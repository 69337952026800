import RouterComponent from '@/components/generic/router/Router'

export default {
  path: '/',
  component: RouterComponent,
  children: [
    {
      path: 'onboarding',
      name: 'Onboarding',
      component: () => import('@/pages/onboarding/Onboarding.vue')
    }
  ]
}
