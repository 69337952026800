import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueScrollTo from 'vue-scrollto'
import ScrollAnimation from './directives/scrollanimation'
import { Plugin } from 'vue-responsive-video-background-player'
import i18n from '@/translate/i18n'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import 'vue-slick-carousel/dist/vue-slick-carousel.css';
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';

//bootstrap
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// import scss
import '@/assets/style/styles.scss'

// import directives
Vue.directive('scrollanimation', ScrollAnimation);

// requires
require('@/plugins/vueMixin')

Vue.use(store);
Vue.use(Plugin);
Vue.use(VueScrollTo);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(require('vue-moment'));

Vue.config.productionTip = false

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
