import RouterComponent from '@/components/generic/router/Router'

export default {
  path: '/',
  component: RouterComponent,
  children: [
    {
      path: 'terms-conditions',
      name: 'TermsAndConditions',
      component: () => import('@/pages/termsAndConditions/TermsAndConditions.vue')
    }
  ]
}
