import RouterComponent from '@/components/generic/router/Router'

export default {
  path: '/',
  component: RouterComponent,
  children: [
    {
      path: 'team',
      name: 'Team',
      component: () => import('@/pages/team/Team.vue')
    }
  ]
}
