import RouterComponent from '@/components/generic/router/Router'

export default {
  path: '/',
  component: RouterComponent,
  children: [
    {
      path: 'privacy-policy',
      name: 'PrivacyPolicy',
      component: () => import('@/pages/privacyPolicy/PrivacyPolicy.vue')
    }
  ]
}
