import RouterComponent from '@/components/generic/router/Router'

export default {
  path: '/',
  component: RouterComponent,
  children: [
    {
      path: '/',
      name: 'Overview',
      component: () => import('@/pages/overview/Overview.vue')
    }
  ]
}
