import RouterComponent from '@/components/generic/router/Router'

export default {
  path: '/',
  component: RouterComponent,
  children: [
    {
      path: 'gordons-corner',
      name: 'GordonsCorner',
      component: () => import('@/pages/gordonsCorner/GordonsCorner.vue')
    }
  ]
}
