import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

//import routes
import Overview from '@/router/routes/overview'
import ExperiencedFinance from "@/router/routes/experienced-finance";
import Guide from "@/router/routes/guide";
import Team from "@/router/routes/team";
import Onboarding from "@/router/routes/onboarding";
import GordonsCorner from "@/router/routes/gordons-corner";
import PrAndMedia from "@/router/routes/pr-and-media";
import PrivacyPolicy from "@/router/routes/privacy-policy";
import TermsAndConditions from "@/router/routes/terms-and-conditions";

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: () => import('@/components/main-container/MainContainer.vue'),
      children: [
        Overview,
        ExperiencedFinance,
        Guide,
        Team,
        Onboarding,
        GordonsCorner,
        PrAndMedia,
        TermsAndConditions,
        PrivacyPolicy,
      ]
    }
  ]
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)

  if (!to.meta.middleware) {
    return next()
  }

  const middleware = to.meta.middleware

  if (middleware.length === 0) {
    return next()
  }
  const context = {
    to,
    from,
    next,
    store
  }
  for (var key in middleware) {
    middleware[key]({
      ...context
    })
  }
})

export default router
