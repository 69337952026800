import Vue from 'vue'
import store from '@/store'

Vue.mixin({
  computed: {
    stateStore() {
      return store.getters
    },
    store() {
      return store
    },
  },
  data() {
    return {
      drawer: false,
      creativeDepartment: [
        {
          id: 0,
          img: 'Jordan_Markov.png',
          title: 'Jordan Markov',
          subtitle: 'position_chief_executive_officer',
        },
        {
          id: 1,
          img: 'kika.png',
          title: 'Kika',
          subtitle: 'position_chief_operations_officer',
        },
        {
          id: 2,
          img: 'Nikoleta_Kovacheva.png',
          title: 'Nikoleta Kovacheva',
          subtitle: 'position_concept_artist',
        },
        {
          id: 3,
          img: 'Daniel_Ivanov.png',
          title: 'Daniel Ivanov',
          subtitle: 'position_editor',
        },
        {
          id: 4,
          img: 'Alexander_Lechev.png',
          title: 'Alexander Lechev',
          subtitle: 'position_three_d_team_lead',
        },
        {
          id: 5,
          img: 'Anton_Raynov.png',
          title: 'Anton Raynov',
          subtitle: 'position_three_d_artist',
        },
        {
          id: 6,
          img: 'Ia_Hristova.png',
          title: 'Ia Hristova',
          subtitle: 'position_three_d_artist',
        },
        {
          id: 7,
          img: 'Lyuboslav_Belovski.png',
          title: 'Lyuboslav Belovski',
          subtitle: 'position_three_d_artist',
        },
        {
          id: 8,
          img: 'Metodi_Velichkov.png',
          title: 'Metodi Velichkov',
          subtitle: 'position_three_d_artist',
        },
        {
          id: 9,
          img: 'Alexande_Razmov.png',
          title: 'Alexander Razmov',
          subtitle: 'position_two_d_compositor',
        },
        {
          id: 10,
          img: 'Atanas_Stankov.png',
          title: 'Atanas Stankov',
          subtitle: 'position_two_d_compositor',
        },
        {
          id: 11,
          img: 'Iliq_Shekerdjiev.png',
          title: 'Iliq Shekerdjiev',
          subtitle: 'position_two_d_compositor',
        },
        {
          id: 12,
          img: 'Yana_Lineva.png',
          title: 'Yana Lineva',
          subtitle: 'position_two_d_compositor',
        },
      ],
      teamMembers: [
        {
          id: 0,
          img: 'frank_roehrig.png',
          title: 'Frank Roehrig',
          subtitle: 'position_chief_executive_officer',
          description: [
            'frank_roehrig_description_first',
            'frank_roehrig_description_second',
            'frank_roehrig_description_third'
          ],
          linkedin: 'https://www.linkedin.com/in/frank-r%C3%B6hrig/'
        },
        {
          id: 2,
          img: 'raphael.png',
          title: 'Rafayel Tobelyan',
          subtitle: 'position_chief_information_officer',
          description: [
            'raphael_tobelyan_description_first',
            'raphael_tobelyan_description_second',
            'raphael_tobelyan_description_third'
          ],
        },
        {
          id: 3,
          img: 'david.png',
          title: 'David Bras',
          subtitle: 'position_chief_marketing_officer',
          description: [
            'david_bras_description_first',
            'david_bras_description_second',
            'david_bras_description_third'
          ],
        },
        {
          id: 5,
          img: 'alberto.png',
          title: 'Alberto Mera',
          subtitle: 'position_lead_designer',
          description: 'alberto_mera_description_first'
        },
        {
          id: 6,
          img: 'Timur_ozturk.png',
          title: 'Timur Ozturk ',
          subtitle: 'timur_ozturk_chief_communication_officer',
          description: 'timur_ozturk_description_first'
        },
        {
          id: 7,
          img: 'Lewis_mhlanga.png',
          title: 'Lewis Mhlanga',
          subtitle: 'position_moderator',
          description: [
            'lewis_mhlanga_description_first',
            'lewis_mhlanga_description_second'
          ]
        },
        {
          id: 8,
          img: 'mariam_sargsyan.png',
          title: 'Mariam Sargsyan',
          subtitle: 'position_senior_marketing_strategist',
          description: [
            'mariam_sargsyan_description_first',
            'mariam_sargsyan_description_second',
            'mariam_sargsyan_description_third',
          ]
        },
        {
          id: 9,
          img: 'anzhelika_volska.png',
          title: 'Anzhelika Volska',
          subtitle: 'position_senior_project_manager',
          description: [
            'anzhelika_volska_description_first',
            'anzhelika_volska_description_second',
          ]
        },
        {
          id: 10,
          img: 'jacob_oblasser.png',
          title: 'Jacob Oblasser',
          subtitle: 'position_senior_marketing_pm',
          description: 'jacob_oblasser_description_first'
        },
        {
          id: 12,
          img: 'diana_kasprovych.png',
          title: 'Diana Kasprovych',
          subtitle: 'position_senior_lead_ux_ui_designer',
          description: [
            'diana_kasprovych_description_first',
            'diana_kasprovych_description_second'
          ]
        },
        {
          id: 13,
          img: 'anna_bodnariuk.png',
          title: 'Anna Bodnariuk',
          subtitle: 'position_senior_ux_ui_designer',
          description: [
            'anna_bodnariuk_description_first',
            'anna_bodnariuk_description_second',
            'anna_bodnariuk_description_third',
          ]
        },
        {
          id: 14,
          img: 'anna_kulyba.png',
          title: 'Anna Kulyba',
          subtitle: 'position_senior_ux_ui_designer',
          description: [
            'anna_kulyba_description_first',
            'anna_kulyba_description_second'
          ]
        },
        {
          id: 16,
          img: 'matilda_demirjian.png',
          title: 'Matilda Demirjian',
          subtitle: 'position_senior_graphic_designer',
          description: [
            'matilda_demirjian_description_first',
            'matilda_demirjian_description_second',
            'matilda_demirjian_description_third',
          ]
        },
      ],
      social: [
        {
          to: 'https://www.linkedin.com/company/meta-networks-fzco/',
          icon: 'linkedin',
        },
        {
          to: 'https://twitter.com/GymNet_Official',
          icon: 'twitter',
        },
        {
          to: 'https://www.youtube.com/channel/UCTKM45Q0twR-tUD7syOpBzQ/about',
          icon: 'youtube',
        },
        {
          to: 'http://t.me/gymnetwork_english',
          icon: 'telegram',
        },
        {
          to: 'https://www.facebook.com/profile.php?id=100086731477013/',
          icon: 'facebook',
        },
        {
          to: 'https://www.instagram.com/gymstreet.metaverse/',
          icon: 'square-instagram',
        }
      ]
    }
  }
})
