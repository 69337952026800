import RouterComponent from '@/components/generic/router/Router'

export default {
  path: '/',
  component: RouterComponent,
  children: [
    {
      path: 'pr-and-media',
      name: 'PrAndMedia',
      component: () => import('@/pages/prAndMedia/PrAndMedia.vue')
    }
  ]
}
