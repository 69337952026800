import RouterComponent from '@/components/generic/router/Router'

export default {
  path: '/',
  component: RouterComponent,
  children: [
    {
      path: 'guide',
      name: 'Guide',
      component: () => import('@/pages/guide/Guide.vue')
    }
  ]
}
