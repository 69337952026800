import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
  // English
  'en': {
    header_button_first: 'Explore the Metaverse',
    header_button_second: 'Login',
    header_button_third: 'Explore',
    header_menu_item_first: 'Experienced finance',
    header_menu_item_second: 'Guide',
    header_menu_item_third: 'Team',
    header_menu_item_fourth: 'Onboarding',
    header_menu_item_fifth: 'Gordon’s Corner',
    header_menu_item_sixth: 'PR and News',

    footer__form__title: 'Subscribe',
    footer__form__subtitle: 'Stay current with our latest insights.',
    footer_address_first: 'META NETWORKS FZCO',
    footer_address_second: 'Dubai Silicon Oasis',
    footer_address_third: 'DDP, Building A4',
    footer_address_fourth: 'Dubai',
    footer_address_fifth: 'United Arab Emirates',
    footer_address_unit_number: 'Unit Number 213',
    footer_link_first: 'Contact us',
    footer_link_second: 'FAQ',
    footer_link_third: 'Privacy policy',
    footer_link_fourth: 'Terms of use',

    overview_intro_title: 'The easiest way to experience finance',
    overview_intro_subtitle: 'What would Wall Street look like today and in 100 years?',
    overview_intro_subtitle_second: 'DeFi, CeFi, exciting opportunities and products.',
    overview_intro_button: 'Explore the Metaverse',
    overview_intro_button_second: 'Login',
    overview_info_title: 'What is "experienced finance" ?',
    overview_info_subtitle: 'There is conventional finance and decentralized finance. To understand the difference, people need to experience it, not just read or study it. Experiences are the key.',
    overview_info_title_second: 'We lead people to these experiences: Controllable growth',
    overview_info_subtitle_second: 'Consultants, advisors and affiliate partners help new users navigate into and within the metaverse. In return, they receive a reward and new users find the experience easier.',
    overview_info_title_third: 'If the product is convincing, the customer is motivated to use it.',
    overview_info_subtitle_third: 'When onboarding new products, we pay a lot of attention to quality and ease of use. If it is easy to use, it is easy to recommend. ',
    overview_news_title_main: 'Gymstreet news',
    overview_news_date_first: 'July 28, 2022',
    overview_news_date_second: 'July 28, 2022',
    overview_news_subtitle_second_first: 'Frank Roehrig,',
    overview_news_subtitle_second_second: 'Chief Executive Officer (CEO)',
    overview_news_date_third: 'July 28, 2022',
    overview_news_date_fourth: 'July 28, 2022',
    overview_news_date_fifth: 'July 28, 2022',
    overview_news_title_fifth: 'We have developed and are developing decentralised and centralised affiliate layer solutions to promote your product to our 25,000+ affiliates.',
    overview_info_second_title: 'How can I experience the Gymstreet in test mode',
    overview_info_second_subtitle: 'We have created a test environment for you so that you can easily get started. You can buy virtual land in the text, run the NFT miners in the test pilot and much more. ',
    overview_info_second_title_second: 'Meet our team',
    overview_partners_title_main: 'Our partners',
    overview_telegram_title_main: 'How can we help you?',
    overview_telegram_link: 'Get in touch!',

    experienced_finance_video_title: 'The story of finance',
    experienced_finance_info_title: 'Did you know that big banks see the Metaverse as an $8 trillion industry? ',
    experienced_finance_review_first_description: '“If we want a creator economy—if we want our kids to have jobs that we could not even dream of ourselves—how do we think about those platforms as citizens, companies, and regulators?”',
    experienced_finance_review_first_position: 'Vice President, Epic Games’ Unreal Engine Ecosystem',
    experienced_finance_review_second_description: '“We’re trying not to define the metaverse so rigidly that it limits the imagination of creators.”',
    experienced_finance_review_second_position: 'CEO, Square Enix',
    experienced_finance_review_third_description: '“I think about the metaverse as a continuation of where tech was headed prior to COVID. We have Instagram. We have an email, we have a messaging, there’re our real-time friends, the real-life activity that we\'re participating in. Sometimes there’s an intersection between those two.”',
    experienced_finance_review_third_position: 'CEO, Niantic',
    experienced_finance_review_fourth_description: '“There are hundreds of millions of people connecting to these environments every day. There are many of the most storied companies on earth building a presence, and we have commerce in the tens and soon to be hundreds of billions of dollars.”',
    experienced_finance_review_fourth_position: 'Managing partner of EpyllionCo and McKinsey knowledge partner',
    experienced_finance_review_fifth_description: '“Consumer behavior has largely shifted toward adopting digital personas, yet many brands have yet to provide a solution.”',
    experienced_finance_review_fifth_position: 'Co-founder and CEO of AnamXR',
    experienced_finance_jump_into_main_title: 'Jump into a new virtual world and experience finance like never before',
    experienced_finance_time_main_title: 'Understand time',
    sandglass_periods_past: 'Past',
    sandglass_periods_now: 'Now',
    sandglass_periods_future: 'Future',
    sandglass_info_description_first: 'Click on a category to reveal additional layers.',
    sandglass_info_title_second: 'Future',
    sandglass_info_description_second: 'Gymstreet with consultants in the Metaverse',
    sandglass_info_title_third: 'Past',
    sandglass_info_description_third: 'Banking 30 years ago - counters and on-site advice',
    sandglass_info_title_fourth: 'Now',
    sandglass_info_first_description_fourth: 'DeFi platforms and Metaverse finance',
    sandglass_info_second_description_fourth: 'Banking as a service - Online and neo banking ',
    sandglass_info_third_description_fourth: 'Banking and insurance via advisor at home',
    experienced_finance_infomasses_main_title: 'We bring DeFi and CeFi to the masses',
    experienced_finance_infomasses_card_first_title: 'Consult and advise',
    experienced_finance_infomasses_card_first_description: 'Our consultant and affiliates will show you the different products and advise you so that you can eventually master it yourself.',
    experienced_finance_infomasses_card_second_title: 'Teach and educate',
    experienced_finance_infomasses_card_second_description: 'In our university we offer courses and live workshops on all topics in our metaverse. Beginners to professionals feel very comfortable with us.',
    experienced_finance_infomasses_card_third_title: 'Affiliates are welcome',
    experienced_finance_infomasses_card_third_description: 'We are the fastest growing and largest metaverse platform for DeFi, CeFi and affiliate partnerships. Would you like to recommend our products?',
    experienced_finance_infomasses_card_fourth_title: 'Move with speed',
    experienced_finance_infomasses_card_fourth_description: 'In such a fast market, speed is one of the key factors. To be fast, you need a strong and large team and a constant drive to innovate.',
    experienced_finance_digits_main_title: 'The Metaverse will change the way people understand and use money',
    experienced_finance_digits_item_first: 'in investment has flowed into the metaverse so far in 2022',
    experienced_finance_digits_item_second: 'of consumers active on the metaverse have made a purchase',
    experienced_finance_digits_item_third: 'of corporate revenue is expected to come from the metaverse in the  next 5 years according to 25% of senior executives',

    guide_video_title: 'Three ways to guide',

    guide_users_main_title: 'We guide our users on their way into and within the metaverse',
    guide_users_main_subtitle: 'Imagine you have to learn to play the piano without a teacher. This is what happens to most people on their way into the metaverse. Courageous people dare to enter. The broad masses will never go the way alone.',
    guide_first_card_title: 'Affiliate',
    guide_first_card_description: 'Our affiliate partnerships serve the first and second contact. The information and the first experience that a new user has.',
    guide_second_card_title: 'Consultant',
    guide_second_card_description: 'Our consultants and moderators show users how to navigate within the metaverse and how to use and experience products.',
    guide_third_card_title: 'Advisor',
    guide_third_card_description: 'External advisors help us integrate their products and, if necessary, make them simple enough to be easily experienced in an integrative way.',
    guide_team_main_title: 'Meet our team',

    team_title_main_first: 'Meet our team',
    team_title_main: 'Development and operation department',
    team_title_main_second: 'Creative department',

    onboarding_video_title: 'Clear advantages,',
    onboarding_video_title_second: 'easy operation & good product',

    onboarding_video_subtitle: 'We onboard global players as well as exciting startups and provide you a first class Metaverse branch building.',
    onboarding_text_info_item_first: 'Imagine Wall Street as it is today and how it may look in the future. DeFi, CeFi, Global Big Players and startups. All in one huge financial center - Gymstreet. We offer our clients familiar but newly designed and redesigned  access and opportunities. In parallel, we also offer highly interesting startups and products with exciting benefits from the financial world.',
    onboarding_text_info_item_second: 'We want to be multifaceted and represent all sub-industries in our metaverse, whether or not they exist at present.',
    onboarding_text_info_item_second_two: 'It is important for us to generate advantages for our customers and affiliates in the same way and to offer them the necessary education at the same time.',
    onboarding_first_advantage_title: 'Gymnetwork Vaults and DeFi',
    onboarding_first_advantage_description: 'Official partner of Alpaca Finance.',
    onboarding_second_advantage_title: 'CashFT',
    onboarding_second_advantage_description: 'NFT Lifestyle debit cards.',
    onboarding_third_advantage_title: 'Trade squares',
    onboarding_third_advantage_description: 'Trading, FX, Commodities.',
    onboarding_fourth_advantage_title: 'University',
    onboarding_fourth_advantage_description: 'Excellent education in the field of finance.',
    onboarding_fifth_advantage_title: 'Affiliate Center',
    onboarding_fifth_advantage_description: 'Quality education in Marketing.',
    onboarding_sixth_advantage_title: 'Zuckerland',
    onboarding_sixth_advantage_description: 'NFT marketplace focused on real estate, land and infrastructure.',
    onboarding_affiliate_title: 'Affiliate layer solutions',
    onboarding_affiliate_subtitle: 'Our affiliate partners will help you to sell your product even more successfully. And we provide you as an initiator with our software solutions and structure, so that you become even more successful with quality and speed.',
    onboarding_first_digits_title: 'investors in more than 50 countries',
    onboarding_second_digits_title: 'affiliates worldwide',
    onboarding_third_digits_title: 'market cap reached by Gymnetwork after 8 weeks',
    onboarding_fourth_digits_title: 'developers working in 3 different regions',
    onboarding_metaverse_title_main: 'Metaverse onboarding',
    onboarding_metaverse_first_card_title: 'A Bank, an exchange, an incurance or a Fintech',
    onboarding_metaverse_first_card_subtitle: 'Feel free to contact us and tell us why you are perfect for our Metaverse.',
    onboarding_metaverse_first_card_subtitle_second: 'In our financial metaverse we want to integrate the best banks, stock exchanges and insurance companies with their products.',
    onboarding_metaverse_first_card_subtitle_third: ' Alpaca.finance ',
    onboarding_metaverse_first_card_subtitle_fourth: 'and Lbank have seized this opportunity directly and decided to cooperate in order to better position their first-class products.',
    onboarding_metaverse_second_card_title: 'New technology',
    onboarding_metaverse_second_card_subtitle: 'Do you have a new, emerging technology from the world of blockchain? We are very excited!',
    onboarding_metaverse_second_card_subtitle_second: 'Every month there are new and emerging startups in the blockchain and fintech world. We want to offer them a platform to be better noticed and at the same time to be able to fall back on our investors and affiliate partners. We support the constant forestry progress and the absolutely necessary innovation in the field of metaverse.',
    onboarding_metaverse_third_card_title: 'Token or coin',
    onboarding_metaverse_third_card_subtitle: 'Do you think your project is sustainable in the digital asset market and your concept is unique? Let\'s talk.',
    onboarding_metaverse_fourth_card_title: 'Finance product and project',
    onboarding_metaverse_fourth_card_subtitle: 'Does your solution offer a clear advantage to our community? Please feel free to apply.',
    onboarding_metaverse_fifth_card_title: 'LBANK exchange',
    onboarding_metaverse_fifth_card_subtitle: 'The popular crypto currency exchange that is #15 on CoinMarketCap’s Top Cryptocurrency Spot Exchanges list has more than 1.8 Million monthly visits and a daily trading volume close to $1.5 Billion.',
    onboarding_metaverse_title_main_second: 'Metaverse onboarding',
    onboarding_metaverse_first_card_description: 'We do a 3D onboarding. In the first step, we name the features of our cooperation and the benefits for our affiliate partners. ',
    onboarding_metaverse_second_card_description: 'In the second step you choose one or more parcels on our virtual land or even a public place. You can either buy or rent them.',
    onboarding_metaverse_third_card_description: 'In the 3rd step you commission our company with the design of your virtual building and receive a first-class representation in our metaverse.',
    onboarding_metaverse_fourth_card_description: 'They celebrate the opening together with us, our partners and potential new customers.',
    onboarding_metaverse_first_info_title: 'Give a presentation - offer added value',
    onboarding_metaverse_first_info_subtitle: 'Regularly we would like to offer our community an added value free of any products.  It is purely about content. ',
    onboarding_metaverse_second_info_title: 'Trend, Fintech, innovation, motivation',
    onboarding_metaverse_second_info_subtitle: 'Do you have a topic that you master and would like to share it with us? Feel free to contact us!',

    gordons_corner_video_title: 'A sneak peak of our Metaverse',
    gordons_corner_first_info_title: 'Do you want to meet Gordon?',
    gordons_corner_first_info_subtitle: 'Of course, it is our claim to create a very realistic and at the same time futuristic Metaverse. But the fun must not be missing...',
    gordons_corner_first_info_subtitle_two: 'The first Metaverse in-game, which will also serve as orientation. "Find Gordon" will simultaneously show you Gymstreet and give rewards when you find him!',
    gordons_corner_digit_title_main: 'Our Roadmap',
    gordons_corner_digit_first_item_title: 'Experience and initial Land Sale',
    gordons_corner_digit_second_item_title: 'Experience and NFT Rental Mode Activation',
    gordons_corner_digit_third_item_title: 'Experience Activation',
    gordons_corner_sneak_peak_main_title: 'Sneak peak of our buildings',
    gordons_corner_sneak_peak_first_card_title: 'Gymnetwork Vault',
    gordons_corner_sneak_peak_first_card_description: 'Our official Partner here is Alpaca Finance and you can use these Vaults as an easy to use product and also experience the products in metaverse style.',
    gordons_corner_sneak_peak_second_card_title: 'CashFT',
    gordons_corner_sneak_peak_second_card_description: 'A NFT Lifestyle Debit Card with lots of cross boarder payment solutions inside and unique rewards, also Limits. Check it out!',
    gordons_corner_sneak_peak_third_card_title: 'Zuckerland',
    gordons_corner_sneak_peak_third_card_description: 'The real estate, land and infrastructure focused NFT Marketplace provides cool data, analytics and much more for you. Take a tour inside.',
    gordons_corner_sneak_peak_fourth_card_title: 'Affiliate center',
    gordons_corner_sneak_peak_fourth_card_description: 'Training and Workshops for Marketing and more. Materials, Promotion stuff, other affiliates and a lot of content.',
    gordons_corner_sneak_peak_fifth_card_title: 'LBANK exchange',
    gordons_corner_sneak_peak_fifth_card_description: 'The popular crypto currency exchange that is #15 on CoinMarketCap’s Top Cryptocurrency Spot Exchanges list has more than 1.8 Million monthly visits and a daily trading volume close to $1.5 Billion.',
    gordons_corner_second_info_title: 'You are a bank or an exchange ',
    gordons_corner_second_info_subtitle: 'What is your opinion on having such a cool designed branch building in gymstreet?',
    gordons_corner_second_info_subtitle_two: 'Really, it can be yours!',
    gordons_corner_first_news_title: 'Factory for NFTs',
    gordons_corner_first_news_description: 'Soon we will open a Creator Club for skilled designers who want to help us make our Metaverse beautiful. At the same time, you may also market your NFTs on Zuckerland.io.',
    gordons_corner_second_news_title: 'Aesthetic NFTs',
    gordons_corner_second_news_description: 'A Metaverse needs Aesthetic. Trees, billboards, signs, parks, hot dog stands and much more. In the streets of Gymstreet you can find many beautiful things.',
    gordons_corner_third_news_title: 'Your avatar',
    gordons_corner_third_news_description: 'Do you want your own avatar? Soon it will come true. After the launch of the 3D opening begins this era!',
    gordons_corner_fourth_news_title: 'Public avatars',
    gordons_corner_fourth_news_description: 'Depending on the service and the area, people live and work in Gymstreet. Want to have a hot dog?',
    gordons_corner_fifth_news_title: 'Repair manager',
    gordons_corner_fifth_news_description: 'Bring their avatars with them. The Repair Manager repairs NFT miners for increased performance.',
    pr_media_title_main: 'PR and Media',
    coming_soon: 'coming soon...',
    see_here: 'See here',
    copyright: 'Copyright',
    button_submit: 'Submit',
    button_more_people: 'More people',
    source: 'Source:',
    email_address: 'Email address',

    position_chief_executive_officer: 'Chief Executive Officer (CEO)',
    position_chief_technology_officer: 'Chief Technology Officer (CTO)',
    position_chief_information_officer: 'Chief Information Officer (CIO)',
    position_chief_marketing_officer: 'Chief Marketing Officer (CMO)',
    position_chief_operations_officer: 'Chief Operations Officer (COO)',
    position_principal_engineer: 'Principal Engineer',
    position_moderator: 'Moderator',
    position_concept_artist: 'Concept Artist',
    position_editor: 'Editor',
    position_lead_designer: 'Lead Designer',
    position_three_d_team_lead: '3D Team Lead',
    position_three_d_artist: '3D Artist',
    position_two_d_compositor: '2D Compositor',

    // New
    position_senior_marketing_strategist: 'Senior Marketing Strategist',
    position_senior_project_manager: 'Project Manager',
    position_senior_marketing_pm: 'Marketing Project Manager',
    position_senior_content_writer: 'Content Writer',
    position_senior_lead_ux_ui_designer: 'Lead UX/UI Designer',
    position_senior_ux_ui_designer: 'UX/UI Designer',
    position_senior_motion_graphic_designer: 'Motion and Graphic Designer',
    position_senior_graphic_designer: 'Graphic Design',

    overview_info_second_subtitle_second: 'Meet our team. You have a chance to join our exciting team of professionals.',

    frank_roehrig_description_first: 'The Managing Director of Meta Networks FZCO our Metaverse Company in Dubai. He has a background in project development in the commercial real estate sector.',
    frank_roehrig_description_second: 'Since 2015, he has been passionately focused on decentralised finance and digital worlds.',
    frank_roehrig_description_third: 'His mission is to make your journey into the world of decentralised finance as easy as possible.',

    raphael_tobelyan_description_first: 'Rafayel Tobelyan boasts over ten years of experience as a Senior Software Architect. He\'s made system architecture for famous US and Australia-based companies.',
    raphael_tobelyan_description_second: 'While working on more than 30 large-scale projects, Rafayel has gained much experience in cryptocurrencies and blockchain. He holds a Master\'s degree in Information Technologies.',
    raphael_tobelyan_description_third: 'Rafayel is 30 years old and is happily married.',

    david_bras_description_first: 'David Bras is responsible for overseeing the planning, development and execution of Gym Network\'s marketing and advertising initiatives.',
    david_bras_description_second: 'With more than 7 years crypto advertising experience he has been one of the top marketers in this industry, able to raise more than $400 Million within the last 5 years.',
    david_bras_description_third: 'With his unique marketing strategies, wide range of crypto knowledge, and collaborations with the world leading crypto marketing agencies, there are no barriers for Gym Network to be one of the biggest players in the DeFi Space.',

    alberto_mera_description_first: 'Alberto Mera has years of experience in traditional finance and investment banking in international banks such as Morgan Stanley and UBS. His interest lies in bringing financial structures to the world of decentralized finance and the metaverse.',

    timur_ozturk_description_first: 'Timur has a natural talent to break down things that seem complicated at first into comprehensive pieces to help those who may be new to DeFi easily understand how things are working here. As a moderator he loves to present Gym Network’s products to our audience and explain them in such a way that it’s entertaining and educative.',
    timur_ozturk_chief_communication_officer: 'Chief Communication Officer',

    mariam_sargsyan_description_first: 'Starting as a digital marketer, Mariam Sargsyan has made her way to the very top cooperating with major advertising and software companies.',
    mariam_sargsyan_description_second: 'In early 2022, Mariam joined Gym Street as a senior marketing strategist. Now she comes up with strategic marketing plans that help us communicate with people worldwide.',
    mariam_sargsyan_description_third: 'Her goal is to contribute to making DeFi a place where dreams become a reality.',

    anzhelika_volska_description_first: 'Anzhelika is a person who knows that project management is not limited to simply planning a project.',
    anzhelika_volska_description_second: 'She is not a manager who watches from the sidelines. But the one who gives everything for success. A compact planner, a raging storm during development, a conflict mediator, a personal support provider, and leader - that’s all who she is.',

    jacob_oblasser_description_first: 'Jacob briefs and monitors the requirements between the gym team. Due to his experience in online marketing and branding, he builds the concept behind the websites.',

    diana_kasprovych_description_first: 'Head UX/UI Designer Gym Network projects. She has a background in project development in the User Experience and User Interface, finances projects, crypto platforms, branding.',
    diana_kasprovych_description_second: 'Her mission is to think about Logic and UX of Project. Lead UX/UI Designer of Gym Network Platform and website, cashFT and Marketplace. Create brand name for CashFT with her team.',

    anna_bodnariuk_description_first: 'Lead UX/UI Designer of Gym Street and UX/UI Designer of Gym Network. She has a background in branding and e-commerce projects.',
    anna_bodnariuk_description_second: 'Lately, she is deeply into designing for Web 3.0.',
    anna_bodnariuk_description_third: 'Her mission is to make your interaction with both platforms native and exciting.',

    anna_kulyba_description_first: 'UX/UI designer at the Gymnet project. She has experience in providing design solutions for various business sectors such as blockchain, finance, e-commerce, branding.',
    anna_kulyba_description_second: 'Her mission is to conduct UX research, think through product logic and create visual design.',

    matilda_demirjian_description_first: 'Matilda has a BA in Graphic Design and over 10 years of experience directing design projects from A to Z in different fields such as design agencies and advertising companies.',
    matilda_demirjian_description_second: 'Currently working with us, she designs our meta and crypto visuals for GYM Network and GYM Street.',
    matilda_demirjian_description_third: 'Matilda recognizes that design is a powerful tool that helps us understand the digital world we live in.',

    lewis_mhlanga_description_first: 'Lewis Mhlanga is a Crypto & Real Estate Investor, trainer, network marketer professional with over 6 years in this industry.',
    lewis_mhlanga_description_second: 'He likes the direct sales business model with integration of life-changing opportunities to those who choose to take advantage of its rewarding program.',

    overview: 'Overview',

    //  03.09.22
    advisors_title_main: 'Advisors',

    advisors_travladd_title: 'Travladd',
    advisors_travladd_description_first: 'Travladd Crypto - one of the biggest Telegram influencers in the crypto space with 70,000+ group members. Travis is CEO and Owner AMA Central.',
    advisors_travladd_description_second: 'AMA Central is built by one of the most competitive marketing teams in DeFi. With more than 400\'000 followers on their socials they have been officially one of the biggest DeFi Telegram and Twitter influencers in the market and will regularly update their community about Gym Network as our official advisor.',

    advisors_claudio_catrini_title: 'Claudio Catrini',
    advisors_claudio_catrini_description_first: 'The consultant and coach Claudio Catrini supports entrepreneurs and international companies in transforming themselves digitally and decentrally.',
    advisors_claudio_catrini_description_second: 'With over 20 years of experience and practice in the areas of sales & marketing, Claudio knows exactly how to celebrate real successes in the digital world and which mistakes to avoid in order not to waste time, money and resources unnecessarily.',

    influencer_title_main: 'Influencers',

    gymstreet_news_title_main: 'Gymstreet news',
    copied: 'Copied'
  },
  // Japanese
  'jap': {
    header_button_first: 'メタバースを探検',
    header_button_second: 'ログイン',
    header_button_third: '探検',
    header_menu_item_first: '経験豊富な金融機関',
    header_menu_item_second: 'ガイド',
    header_menu_item_third: 'チーム',
    header_menu_item_fourth: 'オンボーディング',
    header_menu_item_fifth: 'ゴードンのページ',
    header_menu_item_sixth: '広報とニュース',

    footer__form__title: '購読',
    footer__form__subtitle: '最新のインサイトをお届けします。',
    footer_address_first: 'META NETWORKS FZCO',
    footer_address_second: 'Dubai Silicon Oasis',
    footer_address_third: 'DDP, Building A4',
    footer_address_fourth: 'Dubai',
    footer_address_fifth: 'United Arab Emirates',
    footer_address_unit_number: 'Unit Number 213',
    footer_link_first: 'お問い合わせ',
    footer_link_second: 'よくある質問',
    footer_link_third: '個人情報保護方針',
    footer_link_fourth: '利用規約',

    overview_intro_title: '金融を体験する一番の近道',
    overview_intro_subtitle: '100年後のウォール街はどうなっているでしょうか？',
    overview_intro_subtitle_second: 'DeFi、CeFi、刺激的なチャンスと製品。',
    overview_intro_button: 'メタバースを探検',
    overview_intro_button_second: 'ログイン',
    overview_info_title: '体験型金融とは?',
    overview_info_subtitle: '従来の金融と分散型金融の違いを理解するには、本で勉強するだけでなく実際に体験する必要があります。体験こそが鍵なのです。',
    overview_info_title_second: 'コントロール可能な成長へと皆さんを導きます。.',
    overview_info_subtitle_second: 'コンサルタント、アドバイザー、アフィリエイト・パートナーは、新しいユーザーをメタバースへナビゲートし、メタバース内での活動を助けます。その見返りとして彼らは報酬を受け取り、新しいユーザーはこの過程をより簡単に進めることができます。',
    overview_info_title_third: '製品が納得できるものであれば、顧客自身がそれを使いこなそうと努力します。',
    overview_info_subtitle_third: '新しい製品をオンボーディングする際、私たちはその品質と使いやすさに特別注意を払います。使いやすければ勧めやすいのです。',
    overview_news_title_main: 'ジムストリートニュース',
    overview_info_second_title: 'テストモードでジムストリートを体験するにはどうすればいいですか？',
    overview_info_second_subtitle: '簡単に始められるよう、テスト環境をご用意しました。テスト環境で仮想土地を購入したり、テストパイロットでNFTマイナーを稼働させたりと、様々なことが可能です。',
    overview_info_second_title_second: 'チームの紹介',
    overview_info_second_subtitle_second: 'チームについて知ってください。あなたもチームの一員になりませんか。挑戦してみませんか？',
    overview_partners_title_main: '企業パートナー',
    overview_telegram_title_main: '相談したいことはありますか？',
    overview_telegram_link: 'お問い合わせはこちら',

    experienced_finance_video_title: '金融のお話',
    experienced_finance_info_title: '大手銀行はメタバースを8兆ドル規模の産業と見ていることはご存知ですか？',
    experienced_finance_review_first_description: '「もしクリエイターエコノミーを望むなら、もし私たち自身が夢にも思わなかったような仕事を子供たちにさせてあげたいなら、市民、企業、規制当局としてこれらのプラットフォームについてどう考えるべきでしょうか？」',
    experienced_finance_review_first_position: '（Epic Games社 アンリアル・エンジン・エコシステム担当副社長）',
    experienced_finance_review_second_description: '「メタバースを厳格に定義することでクリエイターの想像力を制限してしまわないように努めています。」',
    experienced_finance_review_second_position: '（スクウェア・エニックス CEO）',
    experienced_finance_review_third_description: 'メタバースについては、テクノロジーが COVID 以前に向かっていた方向の継続として考えています。インスタグラムがあります。私たちにはメールがあり、メッセージがあり、リアルタイムの友達がいて、私たちが参加している実際の活動があります。これら 2 つが交差することもあります。',
    experienced_finance_review_third_position: '（Niantic　CEO）',
    experienced_finance_review_fourth_description: '「これらの環境には、毎日何億人もの人々が接続しています。最も話題の企業の多くがそこで存在感を示しており、現在数百億ドル、もうじき数千億ドル規模の商取引を行っています。」',
    experienced_finance_review_fourth_position: '（EpyllionCoのマネージングパートナー、マッキンゼーのナレッジパートナー）',
    experienced_finance_review_fifth_description: '「消費者の行動は、デジタル・ペルソナを採用する方向に大きくシフトしていますが、多くのブランドはまだ解決策を提供していません。」',
    experienced_finance_review_fifth_position: '（AnamXR共同創業者兼CEO）',
    experienced_finance_jump_into_main_title: '新しい仮想世界に飛び込んで、これまでにないような金融体験をしましょう',
    experienced_finance_time_main_title: '時間を理解する',
    sandglass_periods_past: '過去',
    sandglass_periods_now: '現在',
    sandglass_periods_future: '未来',
    sandglass_info_description_first: 'カテゴリーをクリックして詳細へ。',
    sandglass_info_title_second: '未来',
    sandglass_info_description_second: 'メタバースのコンサルタントがいるジムストリート',
    sandglass_info_title_third: '過去',
    sandglass_info_description_third: '30年前の銀行業務 - 窓口と現場でのアドバイス',
    sandglass_info_title_fourth: '現在',
    sandglass_info_first_description_fourth: 'DeFiプラットフォームとメタバース金融',
    sandglass_info_second_description_fourth: 'バンキング・アズ・ア・サービス - オンライン・バンキング、ネオ・バンキング',
    sandglass_info_third_description_fourth: '自宅でアドバイザーを介してバンキング・保険',
    experienced_finance_infomasses_main_title: '私たちはDeFiとCeFiを一般の皆様に提供します。',
    experienced_finance_infomasses_card_first_title: 'コンサルティングとアドバイス',
    experienced_finance_infomasses_card_first_description: 'コンサルタントとアフィリエイターが、様々な商品を紹介し、最終的には自分で使いこなせるようにアドバイスします。',
    experienced_finance_infomasses_card_second_title: '教える・教育する',
    experienced_finance_infomasses_card_second_description: '私たちの大学では、メタバースに関するあらゆるトピックのコースとライブ・ワークショップを提供しています。初心者の方からプロの方まで、安心してご参加いただけます。',
    experienced_finance_infomasses_card_third_title: 'アフィリエイト大歓迎',
    experienced_finance_infomasses_card_third_description: '私たちは、DeFi、CeFi、アフィリエイトパートナーシップにおいて最も急成長している、そして最大のメタバースプラットフォームです。私たちの製品を推薦してみませんか？',
    experienced_finance_infomasses_card_fourth_title: '迅速な対応',
    experienced_finance_infomasses_card_fourth_description: 'このような速い市場では、スピードが重要です。速くあるためには、強力で大規模なチームと、常にイノベーションを起こそうとする意欲が必要です。',
    experienced_finance_digits_main_title: 'メタバースは人々のお金の理解と使い方を変える',
    experienced_finance_digits_item_first: '2022年のメタバースへの投資額',
    experienced_finance_digits_item_second: 'メタバースで活動する消費者のうち、実際に購入をしたことがある人の割合 79%',
    experienced_finance_digits_item_third: '全体のシニアエグゼクティブの25%によると、企業収益のうち、今後5年間でメタバースが占めることになると予想される割合　',

    guide_video_title: '3つのガイド方法',
    guide_users_main_title: 'ユーザーがメタバースに入る際、またメタバース内で活動する際にガイドします。',
    guide_users_main_subtitle: 'アフィリエイトシステムを統合した世界初のDeFiプラットフォーム「ジムネットワーク」は、始動時に期待以上の反響を呼びました。',
    guide_first_card_title: 'アフィリエイト',
    guide_first_card_description: '私たちのアフィリエイト・パートナーシップは、1番目と2番目のコンタクトの役割を果たします。新しいユーザーが持つ情報と最初の体験です。',
    guide_second_card_title: 'コンサルタント',
    guide_second_card_description: 'コンサルタントとモデレーターが、メタバース内のナビゲーションや製品の使い方、そのエクスペリエンスをユーザーに紹介します。',
    guide_third_card_title: 'アドバイザー',
    guide_third_card_description: '外部のアドバイザーは、社の製品を1箇所にまとめ、必要に応じてシンプルにする事で調和しやくする手助けをしてくれます。',
    guide_team_main_title: 'チーム紹介',

    team_title_main_first: 'チーム紹介',
    team_title_main: '開発・運用部門',
    team_title_main_second: 'クリエイティブ部門',

    onboarding_video_title: '明確なメリット',
    onboarding_video_title_second: '簡単な操作と良い製品',
    onboarding_video_subtitle: '私たちは、世界的企業や情熱的な新興企業を迎え入れ、一流のメタバース支部を提供します。',
    onboarding_text_info_item_first: 'ウォール街の未来を、DeFi、CeFi、グローバルプレーヤー、そしてスタートアップ企業のすべてが1つの巨大な金融センターであるジムストリートに集結するところを、想像してみてください。私たちはお客様に、慣れ親しんだ、しかし新しくデザインされたアクセスや機会を提供します。それと並行して、私たちは金融界から刺激的な利益をもたらす、非常に魅力的なスタートアップ企業や商品も提供します。',
    onboarding_text_info_item_second: '私たちは多面的でありたいと考えており、メタバースに存在する、あるいはまだ存在しないすべての副次産業を代表するものでありたいと思っています。',
    onboarding_text_info_item_second_two: '私たちの顧客とアフィリエイトのため利益を生み出し、同時に彼らに必要な教育を提供することが重要です。',
    onboarding_first_advantage_title: 'ジムネットワークボルトとDeFi',
    onboarding_first_advantage_description: 'アルパカファイナンスのオフィシャルパートナー',
    onboarding_second_advantage_title: 'キャッシュFT',
    onboarding_second_advantage_description: 'NFTライフスタイルのデビットカード',
    onboarding_third_advantage_title: 'トレードスクエア',
    onboarding_third_advantage_description: '取引、FX、コモディティ',
    onboarding_fourth_advantage_title: '大学',
    onboarding_fourth_advantage_description: '金融分野における優れた教育',
    onboarding_fifth_advantage_title: 'アフィリエイトセンター',
    onboarding_fifth_advantage_description: 'マーケティング分野の質の高い教育',
    onboarding_sixth_advantage_title: 'ザッカーランド',
    onboarding_sixth_advantage_description: '不動産、土地、インフラに特化したNFTマーケットプレイス',
    onboarding_affiliate_title: 'アフィリエイト・レイヤー・ソリューション',
    onboarding_affiliate_subtitle: '当社のアフィリエイト・パートナーは、あなたが製品をより効果的に販売できるよう支援します。また、アフィリエイトパートナーの皆様には、当社のソフトウェアソリューションと仕組みをご利用いただくことで、品質とスピードにより満足いただくことができます。',
    onboarding_first_digits_title: '50カ国以上の投資家',
    onboarding_second_digits_title: '世界中のアフィリエイト',
    onboarding_third_digits_title: 'ジムネットワークは8週間でマーケットキャップに到達',
    onboarding_fourth_digits_title: '3つの異なる地域で働く開発者',
    onboarding_metaverse_title_main: 'メタバースオンボーディング',
    onboarding_metaverse_first_card_title: '銀行、取引所、保険会社、Fintech企業など',
    onboarding_metaverse_first_card_subtitle: '私たちのメタバースになぜあなたが最適なのか、お気軽にお問い合わせください。',
    onboarding_metaverse_first_card_subtitle_second: '私たちの金融メタバースでは、最高の銀行、証券取引所、保険会社を、それらの製品と統合したいと考えています。',
    onboarding_metaverse_first_card_subtitle_third: ' Alpaca.finance ',
    onboarding_metaverse_first_card_subtitle_fourth: 'とLbankはこの機会をとらえ、彼らの一流の製品をよりよく位置づけるために協力することにしました。',
    onboarding_metaverse_second_card_title: '新しい技術',
    onboarding_metaverse_second_card_subtitle: 'ブロックチェーンの世界からの、新興技術をお持ちですか？私たちはとても楽しみにしています！',
    onboarding_metaverse_second_card_subtitle_second: '毎月、ブロックチェーンとフィンテックの世界で新しく新興のスタートアップが登場します。 私たちは、彼らにもっと注目されるためのプラットフォームを提供すると同時に、投資家やアフィリエイト パートナーに頼ることができるようにしたいと考えています。 私たちは、林業の絶え間ない進歩と、メタバースの分野で絶対に必要なイノベーションをサポートします。',
    onboarding_metaverse_third_card_title: 'トークンまたはコイン',
    onboarding_metaverse_third_card_subtitle: 'あなたのプロジェクトはデジタルアセットマーケットにおいて支持され、またそのコンセプトはユニークだと思いますか？是非議論しましょう！',
    onboarding_metaverse_fourth_card_title: '金融商品とプロジェクト',
    onboarding_metaverse_fourth_card_subtitle: 'あなたのソリューションは、私たちのコミュニティに明確な利点をもたらしますか?お気軽にご応募ください。',
    onboarding_metaverse_fifth_card_title: 'LBANK取引所',
    onboarding_metaverse_fifth_card_subtitle: 'CoinMarketCapのTop Cryptocurrency Spot Exchangesリストで15位である人気の暗号通貨取引所は、毎月180万以上の訪問者と毎日15億ドル近くの取引量を誇っています。',
    onboarding_metaverse_title_main_second: 'メタバースオンボーディング',
    onboarding_metaverse_first_card_description: '私たちは仮想オンボーディングを行います。まず初めに、私たちの提携の特徴とアフィリエイトパートナーの利益に名前を付けます。',
    onboarding_metaverse_second_card_description: '次のステップでは、仮想の土地や公共の場所にある1つまたは複数の区画を選択します。購入することも、借りることもできます。',
    onboarding_metaverse_third_card_description: 'そうして次に、バーチャル・ビルの設計を弊社に委託していただき、メタバースにおける一流のシンボルを獲得していただきます。',
    onboarding_metaverse_fourth_card_description: 'そして、私たちやパートナー、潜在的な新規顧客と一緒にオープンを祝うことができるのです。',
    onboarding_metaverse_first_info_title: 'プレゼンテーションの実施 - 付加価値の提供',
    onboarding_metaverse_first_info_subtitle: '私たちはコミュニティに、いかなる製品にもない付加価値を、定期的に提供したいと思っています。',
    onboarding_metaverse_second_info_title: 'トレンド、フィンテック、イノベーション、モチベーション',
    onboarding_metaverse_second_info_subtitle: 'あなたがマスターしていて、それを私たちと共有したいトピックがありますか?お気軽にお問い合わせください！',

    gordons_corner_video_title: '私たちのメタバースのプレビュー',
    gordons_corner_first_info_title: 'ゴードンに会いたい？',
    gordons_corner_first_info_subtitle: 'もちろん、非常にリアルで、同時に未来的なMetaverseを作成することが私たちの目指すところです。しかし、その楽しさを失ってはいけません。',
    gordons_corner_first_info_subtitle_two: 'メタバース初のインゲームは、オリエンテーションの役割も果たします。「ゴードンを探せ」では、ジムストリートを同時に表示し、彼を見つけると報酬を受け取ることができます。',
    gordons_corner_digit_title_main: 'ロードマップ',
    gordons_corner_digit_first_item_title: 'エクスペリエンスおよび初期土地販売',
    gordons_corner_digit_second_item_title: 'エクスペリエンスおよびNFTレンタルモードアクティベーション',
    gordons_corner_digit_third_item_title: 'エクスペリエンスアクティベーション',
    gordons_corner_sneak_peak_main_title: '私たちの建物のプレビュー',
    gordons_corner_sneak_peak_first_card_title: 'ジムネットワークVault',
    gordons_corner_sneak_peak_first_card_description: 'オフィシャルパートナーであるAlpaca Financeが提供するVaultsは、手軽に利用できる商品として、またメタバーススタイルで体験できる商品としてご利用いただけます。',
    gordons_corner_sneak_peak_second_card_title: 'キャッシュFT',
    gordons_corner_sneak_peak_second_card_description: 'NFTのLyfestyleデビットカードに、国境を越えた複数の決済方法とユニークな特典、そしてLimitsを搭載。ぜひチェックしてみてください。',
    gordons_corner_sneak_peak_third_card_title: 'ザッカーランド',
    gordons_corner_sneak_peak_third_card_description: '不動産、土地、インフラに特化したNFTマーケットプレイスは、精通したデータ、分析、その他多くの情報を提供します。内部をご覧ください。',
    gordons_corner_sneak_peak_fourth_card_title: 'アフィリエイトセンター',
    gordons_corner_sneak_peak_fourth_card_description: 'アフィリエイトセンターでは、マーケティングなどのトレーニングやワークショップを開催しています。資料、プロモーションスタッフ、他のアフィリエイトと多くのコンテンツがあります。',
    gordons_corner_sneak_peak_fifth_card_title: 'LBANKエクスチェンジ',
    gordons_corner_sneak_peak_fifth_card_description: 'CoinMarketCap の Top Cryptocurrency Spot Exchanges リストで 15 位にランクされている人気の暗号通貨取引所には、毎月 180 万回以上のアクセスがあり、1 日あたりの取引量は 15 億ドルに近くなっています。',
    gordons_corner_second_info_title: 'あなたは銀行または取引所です',
    gordons_corner_second_info_subtitle: 'ジムストリートにこのようなクールなデザインの支店があることについてどう思いますか?',
    gordons_corner_second_info_subtitle_two: '本当に、あなたのものになりますよ！',
    gordons_corner_first_news_title: 'NFTのための工場',
    gordons_corner_first_news_description: '近々、メタバースをよりスタイリッシュにしてくれるプロのデザイナーを募るため、Creator Clubをオープンする予定です。同時に、ZuckerlandにてNFTを販売することも可能になります。',
    gordons_corner_second_news_title: '美しいNFT',
    gordons_corner_second_news_description: 'メタバースには美学が必要です。木、看板、標識、公園、ホットドッグスタンドなど、ジムストリートの街角では、たくさんの美しいものを見つけることができます。',
    gordons_corner_third_news_title: 'あなたのアバター',
    gordons_corner_third_news_description: '自分のアバターが欲しいですか？もうすぐ実現され、3Dオープンの後、その時代が始まります。',
    gordons_corner_fourth_news_title: '公開アバター',
    gordons_corner_fourth_news_description: 'サービスや地域によって、ジムストリートに住んでいる人、働いている人がいます。ホットドッグはいかがですか？',
    gordons_corner_fifth_news_title: 'リペアマネージャー',
    gordons_corner_fifth_news_description: 'NFTがアバターを持ってきます。リペアマネージャーはNFTのマイナーを修理して、性能をアップさせます。',

    pr_media_title_main: 'PRとメディア',

    coming_soon: '近日公開',
    see_here: 'こちらをご覧ください',
    copyright: '著作権について',
    button_submit: '送信する',
    button_more_people: 'その他の人々',
    source: 'ソース',
    email_address: '電子メールアドレス',

    position_chief_executive_officer: '最高経営責任者（CEO）',
    position_chief_technology_officer: '最高技術責任者(CTO)',
    position_chief_information_officer: '最高情報責任者(CIO)',
    position_chief_marketing_officer: '最高マーケティング責任者（CMO）',
    position_chief_operations_officer: '最高業務執行責任者(COO)',
    position_principal_engineer: 'プリンシパルエンジニア',
    position_moderator: 'モデレーター',
    position_concept_artist: 'コンセプトアーティスト',
    position_editor: 'エディター',
    position_lead_designer: 'リードデザイナー',
    position_three_d_team_lead: '3Dチームリーダー',
    position_three_d_artist: '3Dアーティスト',
    position_two_d_compositor: '2Dコンポジター',

    // New
    position_senior_marketing_strategist: 'シニアマーケティング戦略家',
    position_senior_project_manager: 'プロジェクトマネージャー',
    position_senior_marketing_pm: 'マーケティングプロジェクトマネージャー',
    position_senior_content_writer: 'コンテンツライター',
    position_senior_lead_ux_ui_designer: 'リードUX/UIデザイナー',
    position_senior_ux_ui_designer: 'UX/UIデザイナー',
    position_senior_motion_graphic_designer: 'モーション＆グラフィックデザイナー',
    position_senior_graphic_designer: 'グラフィックデザイン',

    frank_roehrig_description_first: 'ドバイのメタバース企業であるMeta Networks FZCOのマネージングディレクター。商業用不動産分野におけるプロジェクト開発の経歴を持っています。',
    frank_roehrig_description_second: '2015年以降、分散型金融とデジタル世界に情熱を注いできました。',
    frank_roehrig_description_third: '彼の使命は、分散型金融の世界への旅をできるだけ簡単にすることです.',

    raphael_tobelyan_description_first: 'ラファイェル　トベルヤンは、シニアソフトウェアアーキテクトとして10年以上の経験を誇っています。米国やオーストラリアの有名企業でシステムアーキテクチャを構築してきました。',
    raphael_tobelyan_description_second: '30以上の大規模プロジェクトに携わる中で、暗号通貨とブロックチェーンについて多くの経験を積んできました。情報技術の修士号も取得しています。',
    raphael_tobelyan_description_third: '彼は３０歳で既婚です。',

    david_bras_description_first: 'デイビット　ブラスは、ジム・ネットワーク社のマーケティングおよび広告イニシアティブの計画、開発、執行を監督する責任者です。',
    david_bras_description_second: '7年以上の暗号広告の経験を持つ彼は、過去5年で4億ドル以上を調達した、この業界でトップクラスのマーケターです。',
    david_bras_description_third: '彼のユニークなマーケティング戦略、暗号の幅広い知識、そして世界をリードする暗号マーケティング機関とのコラボレーションにより、ジムネットワークがDeFiスペースで最大のプレーヤーとなる上でもはや障壁はありません。',

    alberto_mera_description_first: 'アルベルト　メラは、モーガンスタンレーやUBSなどの国際的な銀行で、伝統的な金融と投資銀行業務において長年の経験を持っています。彼の関心は、分散型金融とメタバースの世界に金融構造をもたらすことにあります。',

    timur_ozturk_description_first: 'ティムールには、一見複雑に見える物事を包括的に分解し、DeFi初心者がここでどのように物事が動いているかを容易に理解できるようにする天賦の才能があります。モデレーターとして、ジム・ネットワークの製品を紹介し、楽しく、かつ教育的な方法で説明することが好きです。',
    timur_ozturk_chief_communication_officer: '最高コミュニケーション責任者',

    mariam_sargsyan_description_first: 'デジタルマーケッターとしてスタートしたマリアム・サルキシャンは、大手広告会社、大手ソフトウェア会社と協力し、トップへの道を歩んできました。',
    mariam_sargsyan_description_second: '2022年始めに、マリアムはシニアマーケティング戦略家としてジム・ストリートに入社しました。現在、彼女は私たちが世界中の人々とコミュニケーションを取るための戦略的なマーケティングプランを考えています。',
    mariam_sargsyan_description_third: '彼女の目標は、DeFiを夢が現実になる場所にすることに貢献することです。',

    anzhelika_volska_description_first: 'アンジェリカは、プロジェクトマネジメントが単にプロジェクトの計画を立てるだけではないことを知っています。',
    anzhelika_volska_description_second: '彼女は傍観するマネージャーではありません。しかし、成功のためにすべてを与える人。コンパクトなプランナー、開発中の荒れ狂う嵐、対立の調停者、個人的なサポート提供者、リーダー - それが彼女のすべてです。',

    jacob_oblasser_description_first: 'ジェイコブは、ジムチーム間の要件を説明し、監督しています。オンラインマーケティングとブランディングの経験を生かし、ウェブサイトのコンセプトを構築しています。',

    diana_kasprovych_description_first: 'UX/UIデザイナージム・ネットワーク・プロジェクトの責任者。ユーザーエクスペリエンスとユーザーインターフェイスのプロジェクト開発、財政プロジェクト、暗号プラットフォーム、ブランディングの経歴を持っています。',
    diana_kasprovych_description_second: '彼女のミッションは、Project のロジックと UX について考えることです。 Gym Network Platform と Web サイト、cashFT と Marketplace のリード UX/UI デザイナー。彼女のチームと一緒に CashFT のブランド名を作成します。',

    anna_bodnariuk_description_first: 'ジム・ストリートのリードUX/UIデザイナー、ジム・ネットワークのUX/UIデザイナー。ブランディングとEコマースのプロジェクトに携わっています。',
    anna_bodnariuk_description_second: '最近はWeb3.0のデザインに深く傾倒しています。',
    anna_bodnariuk_description_third: 'ユーザーが両プラットフォームを自然に、楽しく使いこなせることを使命としています。',

    anna_kulyba_description_first: 'ジムネットプロジェクトにおけるUX/UIデザイナー。ブロックチェーン、金融、Eコマース、ブランディングなど、様々なビジネス分野でのデザインソリューションを提供した経験を持っています。',
    anna_kulyba_description_second: 'UXリサーチを行い、製品論理を考え、ビジュアルデザインを作成します。',

    matilda_demirjian_description_first: 'マチルダはグラフィックデザインの学士号を持ち、デザイン会社や広告会社など様々な分野で10年以上のデザインプロジェクトの監督経験があります。',
    matilda_demirjian_description_second: '現在は、ジムネットワークとジムストリートのメタデータと暗号のビジュアルをデザインしています。',
    matilda_demirjian_description_third: 'マチルダは、デザインは私たちが生きるデジタル世界を理解するのに役立つ強力なツールであると理解しています。',

    lewis_mhlanga_description_first: 'ルイス・ムランガは、暗号と不動産の投資家、トレーナー、ネットワークマーケティングの専門家で、この業界で6年以上働いています。',
    lewis_mhlanga_description_second: '彼は、選んだ人には人生を変えるような機会を提供する、そんなダイレクトセールスのビジネスモデルを気に入っています。',

    overview: '概要',


    //  03.09.22
    advisors_title_main: 'アドバイザー',

    advisors_travladd_title: 'Travladd',
    advisors_travladd_description_first: 'Travladd Crypto - 70,000 人以上のグループ メンバーを擁する、仮想通貨業界で最大の Telegram インフルエンサーの 1 つです。 Travis は AMA Central の CEO 兼オーナーです。',
    advisors_travladd_description_second: 'AMA Central は、DeFi で最も競争力のあるマーケティング チームの 1 つによって構築されています。 彼らのソーシャルには 400,000 人以上のフォロワーがおり、市場で最大の DeFi Telegram および Twitter インフルエンサーの 1 つであり、公式アドバイザーとしてジム ネットワークについて定期的にコミュニティを更新しています。',

    advisors_claudio_catrini_title: 'Claudio Catrini',
    advisors_claudio_catrini_description_first: 'コンサルタント兼コーチのクラウディオ・カトリーニは、起業家や国際企業がデジタル化および分散型に変革することをサポートしています。',
    advisors_claudio_catrini_description_second: '販売とマーケティングの分野で 20 年以上の経験と実践を持っている Claudio は、デジタルの世界で真の成功を祝う方法と、時間、お金、リソースを不必要に無駄にしないために避けるべき間違いを正確に知っています。',

    influencer_title_main: 'インフルエンサー',

    gymstreet_news_title_main: 'Gymstreet ニュース',

    copied: 'コピーしました'
  },
  // Korean
  'sk': {
    header_button_first: '메타버스 탐험하기',
    header_button_second: '로그인',
    header_button_third: '탐구',
    header_menu_item_first: '경험이 풍부한 금융',
    header_menu_item_second: '안내',
    header_menu_item_third: '팀',
    header_menu_item_fourth: '온보딩',
    header_menu_item_fifth: '고든스 코너',
    header_menu_item_sixth: '홍보 및 뉴스',

    footer__form__title: '구독',
    footer__form__subtitle: '현재 우리의 최신 인사이트와 함께하세요',
    footer_address_first: 'META NETWORKS FZCO',
    footer_address_second: 'Dubai Silicon Oasis',
    footer_address_third: 'DDP, Building A4',
    footer_address_fourth: 'Dubai',
    footer_address_fifth: 'United Arab Emirates',
    footer_address_unit_number: 'Unit Number 213',
    footer_link_first: '우리에게 문의하세요',
    footer_link_second: 'FAQ',
    footer_link_third: '개인정보정책',
    footer_link_fourth: '이용약관',

    overview_intro_title: '금융시장을 경험하기 위한 가장 쉬운 방법',
    overview_intro_subtitle: '오늘날 월스트리트는 어떠한가요 그리고 향후 100년은 어떤 모습일까요?',
    overview_intro_subtitle_second: '탈중앙금융, 중앙금융, ',
    overview_intro_button: '메타버스 탐험하기',
    overview_intro_button_second: '로그인',
    overview_info_title: '경험이 풍부한 금융은 무엇인가요?',
    overview_info_subtitle: '상업적 금융과 탈중앙화 금융이 있습니다. 그 차이점을 이해하기 위해서는 그저 책을 읽고 공부만 하는 것이 아니라 직접 경험을 할 필요성이 있습니다. 경험이 바로 그 열쇠입니다.',
    overview_info_title_second: '우리는 사람들로 하여금 이것을 경험하도록 이끌어 줍니다. : 통제가능한 성장',
    overview_info_subtitle_second: '컨설턴트, 고문 및 제휴파트너들은 새로운 사용자들이 메타버스를 탐색할 수 있도록 도와줍니다. 그 대가로 그들은 보상을 받고 새로운 사용자는 그 경험을 보다 더 쉽게 할 수 있습니다. ',
    overview_info_title_third: '제품이 설득력이 있으면 고객이 사용하도록 동기를 부여합니다.',
    overview_info_subtitle_third: '신제품을 온보딩할 때 품질과 사용 편의성에 많은 관심을 기울입니다. 사용하기 쉬우면 추천하기 쉽습니다.',
    overview_news_title_main: 'Gymstreet 소식들',
    overview_info_second_title: '테스트 모드에서 짐스트리트를 경험하는 방법',
    overview_info_second_subtitle: '쉽게 시작할 수 있도록 테스트 환경을 만들었습니다. 텍스트에서 가상 토지를 구입하고 테스트 파일럿에서 NFT 마이너를 실행하는 등의 작업을 수행할 수 있습니다.',
    overview_info_second_title_second: '우리의 팀을 만나보세요.',
    overview_info_second_subtitle_second: '우리 팀에 대해 알아보세요. 우리는 당신이 그 일부가 되기를 바랍니다. 도전하시겠습니까?',
    overview_partners_title_main: '우리의 파트너들',
    overview_telegram_title_main: '어떻게 도와드릴까요?',
    overview_telegram_link: '연락하세요!',

    experienced_finance_video_title: '금융이야기',
    experienced_finance_info_title: '대형 은행들이 메타버스를 8조 달러 규모의 산업으로 보고 있다는 사실을 알고 계셨나요?',
    experienced_finance_review_first_description: '“창조 경제를 원한다면, 우리 아이들이 우리 스스로는 꿈도 꾸지 못한 직업을 갖게 된다면, 시민, 기업, 규제 기관으로서 이러한 플랫폼에 대해 어떻게 생각해야 할까요?”',
    experienced_finance_review_first_position: '에픽게임즈 언리얼 엔진 생태계 부사장',
    experienced_finance_review_second_description: '"메타버스를 너무 엄격하게 정의하여 창작자의 상상력을 제한하지 않으려고 합니다."',
    experienced_finance_review_second_position: '스퀘어에닉스 대표이사',
    experienced_finance_review_third_description: '저는 메타버스를 COVID 이전에 기술이 향했던 곳의 연속이라고 생각합니다. 인스타그램이 있습니다. 우리에게는 이메일이 있고, 메시지가 있고, 실시간 친구가 있고, 우리가 참여하고 있는 실제 활동이 있습니다. 때로는 이 둘 사이에 교차점이 있습니다.',
    experienced_finance_review_third_position: '나이언틱 대표이사',
    experienced_finance_review_fourth_description: '"매일 수억 명의 사람들이 이러한 환경에 접속하고 있습니다. 지구상에서 가장 유서 깊은 회사들이 많이 존재하며 우리는 수십억 달러에서 곧 수천억 달러의 상거래를 할 수 있습니다."',
    experienced_finance_review_fourth_position: 'EpyllionCo와 McKinsey 지식 파트너의 매니징 파트너',
    experienced_finance_review_fifth_description: '"소비자 행동은 디지털 페르소나를 채택하는 방향으로 크게 바뀌었지만 많은 브랜드에서 아직 솔루션을 제공하지 못하고 있습니다."',
    experienced_finance_review_fifth_position: 'AnamXR의 공동 설립자이자 CEO',
    experienced_finance_jump_into_main_title: '새로운 가상 세계로 뛰어들어 지금까지 경험하지 못한 금융을 경험하세요',
    experienced_finance_time_main_title: '시간 이해하기',
    sandglass_periods_past: '과거',
    sandglass_periods_now: '현재',
    sandglass_periods_future: '미래',
    sandglass_info_description_first: '카테고리를 클릭하면 추가 레이어가 나타납니다.',
    sandglass_info_title_second: '미래',
    sandglass_info_description_second: '메타버스에 컨설턴트가 있는 체육관 거리',
    sandglass_info_title_third: '과거',
    sandglass_info_description_third: '뱅킹 30년 전-카운터와 현장 상담',
    sandglass_info_title_fourth: '현재',
    sandglass_info_first_description_fourth: '디파이 플랫폼과 메타버스 파이낸스',
    sandglass_info_second_description_fourth: '서비스로서의 뱅킹 - 온라인과 네오뱅킹',
    sandglass_info_third_description_fourth: '집에서 상담원을 통한 은행·보험',
    experienced_finance_infomasses_main_title: '우리는 DeFi와 CeFi를 대중에게 제공합니다',
    experienced_finance_infomasses_card_first_title: '상담 및 조언',
    experienced_finance_infomasses_card_first_description: '저희 컨설턴트와 제휴사에서 다양한 제품을 보여드리고, 결국 스스로 마스터할 수 있도록 조언해 드리겠습니다.',
    experienced_finance_infomasses_card_second_title: '가르치고 가르치다',
    experienced_finance_infomasses_card_second_description: '우리 대학에서는 메타버스의 모든 주제에 대한 과정과 라이브 워크샵을 제공합니다. 초보자부터 전문가까지 우리와 함께 하는 것이 매우 편안합니다.',
    experienced_finance_infomasses_card_third_title: '제휴 환영합니다',
    experienced_finance_infomasses_card_third_description: '우리는 DeFi, CeFi 및 제휴 파트너십을 위한 가장 빠르게 성장하고 가장 큰 메타버스 플랫폼입니다. 우리 제품을 추천하시겠습니까?',
    experienced_finance_infomasses_card_fourth_title: '빠르게 움직이세요',
    experienced_finance_infomasses_card_fourth_description: '이렇게 빠른 시장에서 속도는 핵심 요소 중 하나입니다. 빠르게 하려면 강력하고 큰 팀과 끊임없는 혁신 추진력이 필요합니다. ',
    experienced_finance_digits_main_title: '메타버스는 사람들이 돈을 이해하고 사용하는 방식을 바꿀 것입니다. ',
    experienced_finance_digits_item_first: '2022년 현재까지 메타버스에 투자금이 유입됬습니다. ',
    experienced_finance_digits_item_second: '메타버스에서 활동하는 소비자 중 구매했습니다',
    experienced_finance_digits_item_third: '고위 경영진의 25%에 따르면 향후 5년 동안 기업 수익 중 메타버스에서 나올 것으로 예상',

    guide_video_title: '가이드를 위한 세가지 길',
    guide_users_main_title: '우리는 사용자가 메타버스 안으로 그리고 메타버스 안에서 길을 안내합니다',
    guide_users_main_subtitle: '선생님 없이 피아노를 배워야 한다고 상상해보세요. 이것은 메타버스로 가는 길에 대부분의 사람들에게 일어나는 일입니다. 용감한 사람들은 감히 들어갑니다. 넓은 대중은 결코 혼자 길을 가지 않을 것입니다.',
    guide_first_card_title: '제휴',
    guide_first_card_description: '우리의 제휴 파트너십은 첫 번째 및 두 번째 연락처를 제공합니다. 새로운 사용자가 갖게 되는 정보와 첫 경험',
    guide_second_card_title: '컨설턴트',
    guide_second_card_description: '우리 컨설턴트와 중재자는 사용자에게 메타버스 내에서 탐색하는 방법과 제품을 사용하고 경험하는 방법을 보여줍니다.',
    guide_third_card_title: '조언자',
    guide_third_card_description: '외부 고문은 제품을 통합하고 필요한 경우 통합 방식으로 쉽게 경험할 수 있도록 단순하게 만듭니다.',
    guide_team_main_title: '우리의 팀을 만나보세요',

    team_title_main_first: '우리의 팀을 만나보세요',
    team_title_main: '개발운영부서',
    team_title_main_second: '창의부',

    onboarding_video_title: '명확한 장점,',
    onboarding_video_title_second: '간편한 조작과 좋은 제품',
    onboarding_video_subtitle: '우리는 흥미진진한 Start UP뿐만 아니라 글로벌 플레이어를 온보딩하고 일류 Metaverse 지점 건물을 제공합니다.',
    onboarding_text_info_item_first: '현재와 미래의 월스트리트를 상상해보십시오. DeFi, CeFi, 글로벌 빅 플레이어 및 스타트업. 하나의 거대한 금융 센터 - Gymstreet의 모든 것. 우리는 고객에게 익숙하지만 새롭게 디자인되고 재설계된 접근과 기회를 제공합니다. 이와 동시에 우리는 금융계에서 얻을 수 있는 흥미진진한 혜택과 함께 매우 흥미로운 스타트업 및 제품도 제공합니다.',
    onboarding_text_info_item_second: '우리는 메타버스에서 존재하거나 아직 존재하지 않는 모든 하위 산업을 다각적으로 대표하고 싶습니다.',
    onboarding_text_info_item_second_two: '같은 방식으로 고객과 계열사를 위해 이점을 창출하고 그들에게 필요한 교육을 동시에 제공하는 것이 중요합니다.',
    onboarding_first_advantage_title: 'Gymnetwork Vaults and DeFi',
    onboarding_first_advantage_description: '알파카파이낸스의 공식 파트너',
    onboarding_second_advantage_title: 'CashFT',
    onboarding_second_advantage_description: 'NFT 라이프스타일 체크카드',
    onboarding_third_advantage_title: '무역광장',
    onboarding_third_advantage_description: '무역 FX 상품',
    onboarding_fourth_advantage_title: '보편성',
    onboarding_fourth_advantage_description: '금융분야 교육 우수',
    onboarding_fifth_advantage_title: '제휴센터',
    onboarding_fifth_advantage_description: '마케팅의 질적 교육',
    onboarding_sixth_advantage_title: '저커랜드',
    onboarding_sixth_advantage_description: '부동산, 토지 및 인프라에 중점을 둔 NFT 시장.',
    onboarding_affiliate_title: '가맹층 솔루션',
    onboarding_affiliate_subtitle: '제휴 파트너가 귀사의 제품을 더욱 성공적으로 판매할 수 있도록 도와드립니다. 그리고 우리는 당신이 품질과 속도로 더욱 성공할 수 있도록 우리의 소프트웨어 솔루션과 구조를 개시자로서 제공합니다.',
    onboarding_first_digits_title: '50개국 이상의 투자자',
    onboarding_second_digits_title: '전 세계적으로 계열사',
    onboarding_third_digits_title: '짐네트워크 8주 만에 시가총액 달성',
    onboarding_fourth_digits_title: '3개 지역에서 일하는 개발자',
    onboarding_metaverse_title_main: '메타버스 온보딩',
    onboarding_metaverse_first_card_title: '은행, 거래소, 증권 또는 핀테크',
    onboarding_metaverse_first_card_subtitle: '언제든지 저희에게 연락하여 귀하가 우리 메타버스에 적합한 이유를 알려주십시오.',
    onboarding_metaverse_first_card_subtitle_second: '금융 메타버스에서 우리는 최고의 은행, 증권 거래소 및 보험 회사를 해당 제품과 통합하고자 합니다.',
    onboarding_metaverse_first_card_subtitle_third: '알파카금융',
    onboarding_metaverse_first_card_subtitle_fourth: '그리고 Lbank는 이 기회를 직접 포착하고 일류 제품을 더 잘 포지셔닝하기 위해 협력하기로 결정했습니다.',
    onboarding_metaverse_second_card_title: '새로운 기술',
    onboarding_metaverse_second_card_subtitle: '블록체인 세계에서 새롭게 떠오르는 기술이 있습니까? 우리는 매우 흥분합니다!',
    onboarding_metaverse_second_card_subtitle_second: '매달 블록체인 및 핀테크 세계에서 새롭고 떠오르는 신생 기업이 있습니다. 우리는 그들에게 더 나은 주목을 받을 수 있는 플랫폼을 제공하는 동시에 투자자와 제휴 파트너에게 돌아갈 수 있는 플랫폼을 제공하고자 합니다. 우리는 메타버스 분야의 끊임없는 임업 발전과 절대적으로 필요한 혁신을 지원합니다.',
    onboarding_metaverse_third_card_title: '토큰 또는 코인',
    onboarding_metaverse_third_card_subtitle: '당신의 프로젝트가 디지털 자산 시장에서 지속 가능하고 당신의 개념이 독특하다고 생각합니까? 이야기합시다.',
    onboarding_metaverse_fourth_card_title: '금융상품 및 프로젝트',
    onboarding_metaverse_fourth_card_subtitle: '귀하의 솔루션이 우리 커뮤니티에 분명한 이점을 제공합니까? 부담없이 신청해 주세요.',
    onboarding_metaverse_fifth_card_title: 'LBANK 거래소',
    onboarding_metaverse_fifth_card_subtitle: 'CoinMarketCap의 Top Cryptocurrency Spot Exchanges 목록에서 #15인 인기 있는 암호 화폐 거래소는 180만 명이 넘는 월간 방문과 15억 달러에 가까운 일일 거래량을 자랑합니다.',
    onboarding_metaverse_title_main_second: '메타버스 온보딩 ',
    onboarding_metaverse_first_card_description: '우리는 3D 온보딩을 합니다. 첫 번째 단계에서 우리는 협력의 특징과 제휴 파트너를 위한 이점을 언급합니다.',
    onboarding_metaverse_second_card_description: '두 번째 단계에서는 가상 땅이나 공공 장소에서 하나 이상의 구획을 선택합니다. 구입하거나 대여할 수 있습니다.',
    onboarding_metaverse_third_card_description: '3단계에서는 가상 건물 디자인을 당사에 의뢰하고 메타버스에서 일류 표현을 받습니다.',
    onboarding_metaverse_fourth_card_description: '그들은 우리, 우리의 파트너 및 잠재적인 신규 고객과 함께 오프닝을 축하합니다.',
    onboarding_metaverse_first_info_title: '프레젠테이션 제공 - 부가가치 제공',
    onboarding_metaverse_first_info_subtitle: '정기적으로 우리는 지역 사회에 어떤 제품도 사용하지 않는 부가가치를 제공하고자 합니다. 그것은 순전히 내용에 관한 것입니다.',
    onboarding_metaverse_second_info_title: '트렌드, 핀테크, 혁신, 동기부여',
    onboarding_metaverse_second_info_subtitle: '마스터하고 우리와 공유하고 싶은 주제가 있습니까? 저희에게 연락 주시기 바랍니다!',

    gordons_corner_video_title: '우리 메타버스 살짝 엿보기, 우리 메타버스 엿보기',
    gordons_corner_first_info_title: '고든을 만나고 싶습니까?',
    gordons_corner_first_info_subtitle: '물론, 매우 현실적이면서 동시에 미래 지향적인 Metvaerse를 만드는 것이 우리의 주장입니다. 하지만 재미가 빠질 수 없죠...',
    gordons_corner_first_info_subtitle_two: ' 오리엔테이션 역할도 할 최초의 메타버스 게임. "Find Gordon"은 동시에 Gymstreet를 보여주고 그를 찾으면 보상을줍니다!',
    gordons_corner_digit_title_main: '우리 로드맵',
    gordons_corner_digit_first_item_title: '경험 및 초기 토지 매각',
    gordons_corner_digit_second_item_title: '체험 및 NFT 렌탈 모드 활성화',
    gordons_corner_digit_third_item_title: '체험 활성화',
    gordons_corner_sneak_peak_main_title: '우리 건물의 절경',
    gordons_corner_sneak_peak_first_card_title: '체육관 금고',
    gordons_corner_sneak_peak_first_card_description: '여기 저희 공식 파트너는 알파카 파이낸스로, 이 볼트를 사용하기 쉬운 제품으로 사용하고 메타버스 스타일의 제품을 경험할 수도 있습니다.',
    gordons_corner_sneak_peak_second_card_title: '캐시FT',
    gordons_corner_sneak_peak_second_card_description: 'NFT 라이프스타일 직불카드 내부에 국경을 초월한 다양한 결제 솔루션과 독특한 보상, 리미트도 있습니다. 확인 해봐!',
    gordons_corner_sneak_peak_third_card_title: '저커랜드',
    gordons_corner_sneak_peak_third_card_description: '부동산, 토지 및 인프라에 중점을 둔 NFT Marketplace는 멋진 데이터, 분석 등을 제공합니다. 내부를 둘러보세요.',
    gordons_corner_sneak_peak_fourth_card_title: '가맹센터',
    gordons_corner_sneak_peak_fourth_card_description: '마케팅 등을 위한 교육 및 워크샵. 자료, 판촉물, 기타 계열사 및 많은 콘텐츠.',
    gordons_corner_sneak_peak_fifth_card_title: 'LBANK 거래소',
    gordons_corner_sneak_peak_fifth_card_description: 'CoinMarketCap의 Top Cryptocurrency Spot Exchanges 목록에서 #15인 인기 있는 암호 화폐 거래소는 180만 명 이상의 월간 방문과 15억 달러에 가까운 일일 거래량을 가지고 있습니다.',
    gordons_corner_second_info_title: '당신은 은행 또는 교환',
    gordons_corner_second_info_subtitle: '체육관 거리에 멋진 디자인의 지점 건물을 갖는 것에 대한 귀하의 의견은 무엇입니까?',
    gordons_corner_second_info_subtitle_two: '정말, 당신의 것이 될 수 있습니다!',
    gordons_corner_first_news_title: 'NFT 공장',
    gordons_corner_first_news_description: '곧 Metaverse를 아름답게 만드는 데 지원을 원하는 숙련된 디자이너를 위한 Creator Club을 열 예정입니다. 동시에 Zuckerland에서 NFT를 판매할 수도 있습니다.',
    gordons_corner_second_news_title: '미학적 NFT',
    gordons_corner_second_news_description: '메타버스에는 미학이 필요합니다. 나무, 광고판, 표지판, 공원, 핫도그 가판대 등. 짐스트리트의 거리에는 아름다운 것들이 많이 있습니다.',
    gordons_corner_third_news_title: '당신의 아바타',
    gordons_corner_third_news_description: '나만의 아바타를 원하십니까? 곧 실현될 것입니다. 런칭 후 3D 오프닝이 시작되는 이 시대!',
    gordons_corner_fourth_news_title: '공개 아바타',
    gordons_corner_fourth_news_description: '서비스와 지역에 따라 사람들은 짐스트리트에서 살고 일합니다. 핫도그 먹을래?',
    gordons_corner_fifth_news_title: '수리 관리자',
    gordons_corner_fifth_news_description: 'NFT는 아바타를 가지고 옵니다. 수리 관리자는 성능 향상을 위해 NFT 광부를 수리합니다.',

    pr_media_title_main: '홍보와 미디어',

    coming_soon: '곧 출시',
    see_here: '여기를 보세요',
    copyright: '저작권',
    button_submit: '제출',
    button_more_people: '더 많은 사람들',
    source: '출처',
    email_address: '이메일 주소',

    position_chief_executive_officer: '최고 경영자 CEO',
    position_chief_technology_officer: '최고 기술 책임자',
    position_chief_information_officer: '최고 정보 책임자',
    position_chief_marketing_officer: '최고 마케팅 책임자',
    position_chief_operations_officer: '최고 운영 책임자',
    position_principal_engineer: '수석 엔지니어',
    position_moderator: '중재자',
    position_concept_artist: '컨셉 아티스트',
    position_editor: '편집자',
    position_lead_designer: '리드 디자이너',
    position_three_d_team_lead: '3D 팀 리드',
    position_three_d_artist: '3D 아티스트',
    position_two_d_compositor: '2D 합성기',

    // New
    position_senior_marketing_strategist: '시니어 마케팅 전략가',
    position_senior_project_manager: '프로젝트 매니저',
    position_senior_marketing_pm: '마케팅 프로젝트매니저',
    position_senior_content_writer: '콘텐츠 작가 ',
    position_senior_lead_ux_ui_designer: '수석 UX/UI 디자이너',
    position_senior_ux_ui_designer: 'UX/UI 디자이너',
    position_senior_motion_graphic_designer: '모션 및 그래픽 디자이너',
    position_senior_graphic_designer: '그래픽디자인',

    frank_roehrig_description_first: 'Meta Networks FZCO의 전무이사는 두바이에 있는 Metaverse Vompany입니다. 그는 상업용 부동산 부문에서 프로젝트 개발에 대한 배경 지식을 가지고 있습니다.',
    frank_roehrig_description_second: '2015년부터 그는 분산 금융 및 디지털 세계에 열정적으로 집중해 왔습니다.',
    frank_roehrig_description_third: '그의 임무는 탈중앙화 금융의 세계로의 여행을 가능한 한 쉽게 만드는 것입니다.',

    raphael_tobelyan_description_first: 'Rafayel Tobelyan은 수석 소프트웨어 설계자로서 10년 이상의 경험을 자랑합니다. 그는 미국과 호주에 기반을 둔 유명 회사의 시스템 아키텍처를 만들었습니다.',
    raphael_tobelyan_description_second: 'Rafayel은 30개 이상의 대규모 프로젝트를 수행하면서 암호화폐 및 블록체인에 대한 많은 경험을 얻었습니다. 그는 정보 기술 석사 학위를 보유하고 있습니다.',
    raphael_tobelyan_description_third: 'Rafayel은 30세이며 행복한 결혼 생활을 하고 있습니다.',

    david_bras_description_first: 'David Bras는 Gym Network의 마케팅 및 광고 이니셔티브의 계획, 개발 및 실행을 감독하는 책임을 맡고 있습니다.',
    david_bras_description_second: '7년 이상의 암호화폐 광고 경험을 통해 그는 이 업계 최고의 마케터 중 한 명이었으며 지난 5년 동안 4억 달러 이상을 모금할 수 있었습니다.',
    david_bras_description_third: '그의 독특한 마케팅 전략, 광범위한 암호 지식 및 세계 최고의 암호 마케팅 대행사와의 협력을 통해 Gym Network가 DeFi Space에서 가장 큰 플레이어 중 하나가 되는 데 장벽이 없습니다.',

    alberto_mera_description_first: 'Alberto Mera는 Morgan Stanley 및 UBS와 같은 국제 은행에서 전통적인 금융 및 투자 은행 분야에서 수년간의 경험을 보유하고 있습니다. 그의 관심은 분산 금융과 메타버스의 세계에 금융 구조를 가져오는 데 있습니다.',

    timur_ozturk_description_first: 'Timur는 DeFi를 처음 접하는 사람들이 여기에서 어떻게 작동하는지 쉽게 이해할 수 있도록 처음에는 복잡해 보이는 것들을 포괄적인 조각으로 분해하는 타고난 재능을 가지고 있습니다. 중재자로서 그는 Gym Network의 제품을 청중에게 소개하고 재미있고 교육적인 방식으로 설명하는 것을 좋아합니다.',
    timur_ozturk_chief_communication_officer: '최고 커뮤니케이션 책임자',

    mariam_sargsyan_description_first: '디지털 마케터로 시작한 Mariam Sargsyan은 주요 광고 및 소프트웨어 회사와 협력하여 최고의 자리에 올라섰습니다.',
    mariam_sargsyan_description_second: '2022년 초에 Mariam은 수석 마케팅 전략가로 Gym Street에 합류했습니다. 이제 그녀는 전 세계 사람들과 소통하는 데 도움이 되는 전략적 마케팅 계획을 세웁니다.',
    mariam_sargsyan_description_third: '그녀의 목표는 DeFi를 꿈이 현실이 되는 곳으로 만드는 데 기여하는 것입니다.',

    anzhelika_volska_description_first: 'Anzhelika는 프로젝트 관리가 단순히 프로젝트 계획에만 국한되지 않는다는 것을 알고 있는 사람입니다.',
    anzhelika_volska_description_second: '\n' +
      '옆에서 지켜보는 매니저가 아니다. 그러나 성공을 위해 모든 것을 바치는 사람. 조밀한 계획가, 개발 중 폭풍우, 갈등 중재자, 개인 지원 제공자 및 리더 - 그게 그녀의 전부입니다.',

    jacob_oblasser_description_first: 'Jacob은 체육관 팀 간의 요구 사항을 브리핑하고 모니터링합니다. 그는 온라인 마케팅 및 브랜딩 경험을 바탕으로 웹사이트의 개념을 구축합니다.',

    diana_kasprovych_description_first: 'UX/UI 디자이너 체육관 네트워크 프로젝트를 이끌고 있습니다. 그녀는 사용자 경험 및 사용자 인터페이스, 재정 프로젝트, 암호화 플랫폼, 브랜딩의 프로젝트 개발 배경을 가지고 있습니다.',
    diana_kasprovych_description_second: '그녀의 미션은 Project의 Logic과 UX에 대해 생각하는 것입니다. Gym 네트워크 플랫폼 및 웹사이트, cashFT 및 Marketplace의 수석 UX/UI 디자이너. 그녀의 팀과 함께 CashFT의 브랜드 이름을 만드십시오.',

    anna_bodnariuk_description_first: 'Gym Street의 수석 UX/UI 디자이너이자 Gym Network의 UX/UI 디자이너입니다. 그녀는 브랜딩 및 전자 상거래 프로젝트에 대한 배경 지식을 가지고 있습니다.',
    anna_bodnariuk_description_second: '최근에 그녀는 Web 3.0을 위한 디자인에 깊이 빠져 있습니다.',
    anna_bodnariuk_description_third: '그녀의 임무는 두 플랫폼과의 상호 작용을 기본적이고 흥미롭게 만드는 것입니다.',

    anna_kulyba_description_first: 'Gymnet 프로젝트의 UX/UI 디자이너. 그녀는 블록체인, 금융, 전자 상거래, 브랜딩과 같은 다양한 비즈니스 분야에 디자인 솔루션을 제공한 경험이 있습니다. ',
    anna_kulyba_description_second: '그녀의 임무는 UX 연구를 수행하고 제품 논리를 통해 생각하고 시각적 디자인을 만드는 것입니다.',

    matilda_demirjian_description_first: 'Matilda는 그래픽 디자인 학사 학위를 보유하고 있으며 디자인 대행사 및 광고 회사와 같은 다양한 분야에서 A부터 Z까지 디자인 프로젝트를 지휘한 경험이 10년 이상입니다.',
    matilda_demirjian_description_second: '현재 우리와 함께 일하면서 그녀는 GYM 네트워크 및 GYM 스트리트에 대한 메타 및 암호화 비주얼을 디자인합니다.',
    matilda_demirjian_description_third: 'Matilda는 디자인이 우리가 살고 있는 디지털 세계를 이해하는 데 도움이 되는 강력한 도구라는 것을 알고 있습니다.',

    lewis_mhlanga_description_first: 'Lewis Mhlanga는 이 업계에서 6년 이상의 경력을 가진 암호화 및 부동산 투자자, 트레이너, 네트워크 마케터 전문가입니다.',
    lewis_mhlanga_description_second: '그는 보람 있는 프로그램을 활용하기로 선택한 사람들에게 삶을 변화시키는 기회를 통합한 직접 판매 비즈니스 모델을 좋아합니다.',

    overview: '개요',
    advisors_title_main: '고문',

    advisors_travladd_title: 'Travladd',
    advisors_travladd_description_first: 'Travladd Crypto - 70,000명 이상의 그룹 구성원이 있는 암호화 공간에서 가장 큰 Telegram 영향력 행사자 중 하나입니다. Travis는 AMA Central의 CEO이자 소유자입니다.',
    advisors_travladd_description_second: 'AMA Central은 DeFi에서 가장 경쟁력 있는 마케팅 팀 중 하나에 의해 구축되었습니다. 소셜에서 400,000명 이상의 팔로어와 함께 공식적으로 시장에서 가장 큰 DeFi Telegram 및 Twitter 영향력 있는 사람 중 하나가 되었으며 공식 고문으로서 Gym Network에 대해 커뮤니티에 정기적으로 업데이트할 것입니다.',

    advisors_claudio_catrini_title: 'Claudio Catrini',
    advisors_claudio_catrini_description_first: '컨설턴트이자 코치인 Claudio Catrini는 기업가와 국제 기업이 디지털 및 분산 방식으로 스스로를 혁신하도록 지원합니다.',
    advisors_claudio_catrini_description_second: '영업 및 마케팅 분야에서 20년 이상의 경험과 실습을 통해 Claudio는 디지털 세계에서 진정한 성공을 축하하는 방법과 시간, 돈 및 자원을 불필요하게 낭비하지 않기 위해 피해야 할 실수를 정확히 알고 있습니다.',

    influencer_title_main: '인플루언서',

    gymstreet_news_title_main: 'Gymstreet 소식',

    copied: '복사됨'
  },
  // Chinese
  'ch': {
    header_button_first: '探索元宇宙',
    header_button_second: '登入',
    header_button_third: '探索',
    header_menu_item_first: '经验丰富的金融',
    header_menu_item_second: '指南',
    header_menu_item_third: '团队',
    header_menu_item_fourth: '入职',
    header_menu_item_fifth: 'Gordon 角落',
    header_menu_item_sixth: '公关和新闻',

    footer__form__title: '订阅',
    footer__form__subtitle: '随时了解我们的最新质询',
    footer_address_first: 'META NETWORKS FZCO',
    footer_address_second: 'Dubai Silicon Oasis',
    footer_address_third: 'DDP, Building A4',
    footer_address_fourth: 'Dubai',
    footer_address_fifth: 'United Arab Emirates',
    footer_address_unit_number: 'Unit Number 213',
    footer_link_first: '联系我们',
    footer_link_second: '常问问题',
    footer_link_third: '隐私政策',
    footer_link_fourth: '使用条款',

    overview_intro_title: '最简单的金融体验方式',
    overview_intro_subtitle: '华尔街的今天和100年后会是什么样子？',
    overview_intro_subtitle_second: '去中心化，中心化，令人兴奋的机会和产品。',
    overview_intro_button: '探索元宇宙',
    overview_intro_button_second: '登入',
    overview_info_title: '什么是经验金融',
    overview_info_subtitle: '那里有传统金融和去中心化金融。为了认知其差别，人们需要去体验它，而不是透过阅读或研究。经验是关键。',
    overview_info_title_second: '我们带领人们获得这些体验：可掌控增长',
    overview_info_subtitle_second: '顾问，导师和附属合作伙伴将帮助新用户引导到元宇宙。作为回报，他们将获得奖励以及新用户会更容易去体验。',
    overview_info_title_third: '如果产品令人信服，客户就会激励自己去使用它。',
    overview_info_subtitle_third: '在加入新产品时，我们非常注重质量和易用性。如果容易使用会更加容易推荐。',
    overview_news_title_main: 'Gymstreet 新闻',
    overview_info_second_title: '我可以如何体验 Gymstreet 的测试模式',
    overview_info_second_subtitle: '我们为您创建了一个测试环境，以便能让您更轻松上手。您可以用信息购买虚拟土地，在测试运行中运行NFT矿工等等。',
    overview_info_second_title_second: '认识我们的团队',
    overview_info_second_subtitle_second: '了解我们的团队。我们希望您能参与其内。要来个挑战吗？',
    overview_partners_title_main: '我们的伙伴',
    overview_telegram_title_main: '我们可以如何帮到你？',
    overview_telegram_link: '保持联系',

    experienced_finance_video_title: '金融的故事',
    experienced_finance_info_title: '您知道大银行将元宇宙视为一个价值8万亿美元的产业吗？',
    experienced_finance_review_first_description: '如果我们想成为一个经济创造者 - 如果我们想让我们的小孩拥有我们都预想不到的工作 - 我们如何看待这些平台为公民，公司和监管者？',
    experienced_finance_review_first_position: 'Epic Games 的副总裁，虚拟引擎生态系统。',
    experienced_finance_review_second_description: '我们试图不那么严格的定义元宇宙，以至于它限制了创作者的想象力。',
    experienced_finance_review_second_position: 'Square Enix, 首席执行官',
    experienced_finance_review_third_description: '我认为元宇宙是 COVID 之前科技发展方向的延续。我们有 Instagram。我们有一封电子邮件，我们有一条消息，还有我们的实时朋友，我们正在参与的现实生活中的活动。有时这两者之间存在交集。',
    experienced_finance_review_third_position: 'Niantic, 首席执行官',
    experienced_finance_review_fourth_description: '每天有数亿人链接到这种环境。世界上有许多最具传奇色彩的公司建立业务，我们的商业规模已达到数百万以及很快达到数千亿美金。',
    experienced_finance_review_fourth_position: 'EpillionCo 和 McKinsey 知识伙伴的管理合伙人。',
    experienced_finance_review_fifth_description: '消费者特性已在很大程度上转向数字角色，但许多品牌尚未提供解决方案。',
    experienced_finance_review_fifth_position: 'AnamXR 的创始人兼首席执行官，',
    experienced_finance_jump_into_main_title: '跳入一个新的虚拟世界，体验前所未有的金融”，',
    experienced_finance_time_main_title: '了解时间',
    sandglass_periods_past: '过去',
    sandglass_periods_now: '现在',
    sandglass_periods_future: '未来',
    sandglass_info_description_first: '点击种类以显示其它图层。',
    sandglass_info_title_second: '未来',
    sandglass_info_description_second: 'Gymstreet 与元宇宙的顾问',
    sandglass_info_title_third: '过去',
    sandglass_info_description_third: '30 年前的银行业 - 柜台和现场询问',
    sandglass_info_title_fourth: '现在',
    sandglass_info_first_description_fourth: '去中心化平台和元宇宙金融',
    sandglass_info_second_description_fourth: '银行是一种服务 - 在线和新银行',
    sandglass_info_third_description_fourth: '银行和保险对比家里顾问',
    experienced_finance_infomasses_main_title: '我们将 DeFi 和 CeFi 带给大众，',
    experienced_finance_infomasses_card_first_title: '咨询和建议',
    experienced_finance_infomasses_card_first_description: '我们的顾问及附属会员将向您展示不同的产品并为您提供建议，以便您最终自己掌握它。',
    experienced_finance_infomasses_card_second_title: '教育和栽培',
    experienced_finance_infomasses_card_second_description: '在我们的大学中， 我们提供有关我们元宇宙中所有的主题课程和现场研讨会。初学者至到专业人士都对我们感到很舒服。',
    experienced_finance_infomasses_card_third_title: '欢迎成为我们的附属会员',
    experienced_finance_infomasses_card_third_description: '我们是 DeFi，CeFi和附属合作伙伴发展得最快，规模最大的元宇宙平台。您想推广我们的产品吗？',
    experienced_finance_infomasses_card_fourth_title: '以速度移动',
    experienced_finance_infomasses_card_fourth_description: '在如此快速的市场中，速度是关键因素之一。要快，你需要一个强大而庞大的团队和不断创新的动力。',
    experienced_finance_digits_main_title: '元宇宙将改变人们理解和使用金钱的方式',
    experienced_finance_digits_item_first: '在 2022 年， 投资已流入元宇宙',
    experienced_finance_digits_item_second: '消费者已经活跃的在元宇宙进行购买',
    experienced_finance_digits_item_third: '根据25%的高层显示，预计未来5年公司的收入将来自元宇宙，',

    guide_video_title: '三种方式引导',
    guide_users_main_title: '我们引导我们的用户以他们的方式进入元宇宙，',
    guide_users_main_subtitle: '想象你在没有导师的情况下学习弹钢琴。这是大多数人在进入元宇宙会发生的过程。胆子大的人才敢进入。广大群众永远不会孤身做战。',
    guide_first_card_title: '附属',
    guide_first_card_description: '我们的附属伙伴将服务于第一和第二层联系人。一个新用户的信息和第一次体验。',
    guide_second_card_title: '顾问',
    guide_second_card_description: '我们的顾问和导师向用户展示如何在元宇宙导航以及如何使用和体验产品。',
    guide_third_card_title: '指导师',
    guide_third_card_description: '外部顾问帮助我们整合他们的产品，并在必要时使它们变得足够简单，以便以整合的方式轻松体验。',
    guide_team_main_title: '认识我们的团队',

    team_title_main_first: '认识我们的团队',
    team_title_main: '开发及运营部',
    team_title_main_second: '创意部',

    onboarding_video_title: '明显的优势',
    onboarding_video_title_second: '容易操作以及好产品',
    onboarding_video_subtitle: '我们加入了全球玩家以及令人兴奋的初创公司，并为您提供一流的元宇宙分支建筑。',
    onboarding_text_info_item_first: '想象华尔街的今天和未来的样子。DeFi,CeFi,全球大玩家和初创企业，全集中在一个巨大的金融中心 - Gymstreet。我们为客户提供熟悉但新设计的参与和机会。同时我们还提供非常有趣的初创企业和产品，金融界所带来令人兴奋的利润。',
    onboarding_text_info_item_second: '我们要全方面及代表全行业的元宇宙，这包括已经存在或不存在的。',
    onboarding_text_info_item_second_two: '为客户带来利益和同样给附属提供必要的教育，对我们来说都是同样的重要。',
    onboarding_first_advantage_title: 'Gymnetwork 金库和 DeFi',
    onboarding_first_advantage_description: '官方合作伙伴 Alpaca 金融',
    onboarding_second_advantage_title: '现金FT',
    onboarding_second_advantage_description: 'NFT Lifestyle 借记卡',
    onboarding_third_advantage_title: '贸易广场',
    onboarding_third_advantage_description: '交易，外汇，商品',
    onboarding_fourth_advantage_title: '大学',
    onboarding_fourth_advantage_description: '金融领域的优秀教育',
    onboarding_fifth_advantage_title: '附属中心',
    onboarding_fifth_advantage_description: '营销中的素质教育',
    onboarding_sixth_advantage_title: '糖国',
    onboarding_sixth_advantage_description: 'NFT 市集专注于房地产，土地和基础设施。',
    onboarding_affiliate_title: '附属层解决方案',
    onboarding_affiliate_subtitle: '我们的附属合作伙伴将帮助您更成功地销售您的产品。作为发起人，我们为您提供软件解决方案和结构，让您在质量和速度方面取得更大的成功。',
    onboarding_first_digits_title: '50多个国家的投资者',
    onboarding_second_digits_title: '全球附属公司',
    onboarding_third_digits_title: 'Gymnetwork 在8周达到市值',
    onboarding_fourth_digits_title: '在3个不同地区工作的开发人员',
    onboarding_metaverse_title_main: '元宇宙登场',
    onboarding_metaverse_first_card_title: '一间银行，一间交易所，一间保险公司或一间金融科技公司',
    onboarding_metaverse_first_card_subtitle: '请随时与我们联系，告诉我们为什么您非常适合我们的元宇宙。',
    onboarding_metaverse_first_card_subtitle_second: '在我们的金融元宇宙中，我们要成为最好的银行，证劵交易所和保险公司与其产品。',
    onboarding_metaverse_first_card_subtitle_third: ' Alpaca.finance ',
    onboarding_metaverse_first_card_subtitle_fourth: '和 Lbank 直接抓住了机会，决定合作以更好定位自己的一流产品。',
    onboarding_metaverse_second_card_title: '新技术',
    onboarding_metaverse_second_card_subtitle: '你有来自区块链世界的新兴技术吗？我们非常兴奋！',
    onboarding_metaverse_second_card_subtitle_second: '每个月，区块链和金融科技领域都会出现新的和新兴的初创公司。 我们希望为他们提供一个平台，让他们更好地受到关注，同时能够依靠我们的投资者和附属合作伙伴。 我们支持不断的林业进步和元界领域绝对必要的创新。',
    onboarding_metaverse_third_card_title: '代币或币',
    onboarding_metaverse_third_card_subtitle: '您认为您的项目在数字资产市场是可持续的以及是独一无二的概念吗？让我们谈谈，',
    onboarding_metaverse_fourth_card_title: '金融产品和项目',
    onboarding_metaverse_fourth_card_subtitle: '您的解决方案是否为我们的社区提供了明显的优势？请随时申请。',
    onboarding_metaverse_fifth_card_title: 'LBANK 交易所',
    onboarding_metaverse_fifth_card_subtitle: '在 CoinMarketCap 的顶级加密货币交易所列表中排名第 15 的流行加密货币交易所,每月流量超过 180 万，每日交易量接近 15 亿美元。',
    onboarding_metaverse_title_main_second: '元宇宙登场',
    onboarding_metaverse_first_card_description: '我们推出 3D 登场。 第一步骤，我们列出了我们合作的特点以及给我们的附属合作伙伴带来的好处。',
    onboarding_metaverse_second_card_description: '在第二步骤，您在我们的虚拟土地甚至公共场所选择一个或多个地块。 您可以购买或租用它们。',
    onboarding_metaverse_third_card_description: '在第三步骤，您委托我们公司设计您的虚拟建筑，并在我们的元宇宙中获得一流的表现。',
    onboarding_metaverse_fourth_card_description: '他们与我们、我们的合作伙伴还有潜在的新客户一起庆祝开业。',
    onboarding_metaverse_first_info_title: '进行演示 - 提供增值',
    onboarding_metaverse_first_info_subtitle: '我们一般为为我们的社区定期提供不含任何产品的附加值。 它纯粹是关于内容。',
    onboarding_metaverse_second_info_title: '趋势、金融科技、创新、动力，',
    onboarding_metaverse_second_info_subtitle: '你有一个你掌握的主题并想与我们分享吗？欢迎与我们联系！',

    gordons_corner_video_title: '我们元宇宙的潜在高峰',
    gordons_corner_first_info_title: '你想见戈登吗？',
    gordons_corner_first_info_subtitle: '当然，我们声称要创造一个非常现实且同时具有未来感的元宇宙。 但乐趣不容错过。。。',
    gordons_corner_first_info_subtitle_two: '第一个元宇宙游戏，也将作为定向。 “寻找Gordon” 将同时向您展示 Gymstreet 并在您找到他时给予奖励！',
    gordons_corner_digit_title_main: '我们的路线图',
    gordons_corner_digit_first_item_title: '经验和初始土地出售',
    gordons_corner_digit_second_item_title: '体验与NFT租赁模式激活',
    gordons_corner_digit_third_item_title: '体验激活',
    gordons_corner_sneak_peak_main_title: '潜入我们建筑的高峰',
    gordons_corner_sneak_peak_first_card_title: 'Gymnetwork 金库',
    gordons_corner_sneak_peak_first_card_description: '我们的官方合作伙伴是 Alpaca Finance，您可以将这些金库 用作易于使用的产品，也可以体验元宇宙风格的产品。',
    gordons_corner_sneak_peak_second_card_title: '现金FT',
    gordons_corner_sneak_peak_second_card_description: '一张NFT Lifestyle借记卡，里面有很多跨境支付解决方案和独特的奖励，还有额度。 一探究竟！',
    gordons_corner_sneak_peak_third_card_title: '糖国',
    gordons_corner_sneak_peak_third_card_description: '专注于房地产、土地和基础设施的 NFT 市场为您提供酷数据、分析等。 参观内部。',
    gordons_corner_sneak_peak_fourth_card_title: '附属中心',
    gordons_corner_sneak_peak_fourth_card_description: '营销培训和研讨会等。 材料、促销材料、其它附属公司和大量内容。',
    gordons_corner_sneak_peak_fifth_card_title: 'LBANK 交易所',
    gordons_corner_sneak_peak_fifth_card_description: '在 CoinMarketCap 的顶级加密货币现货交易所列表中排名第 15 的流行加密货币交易所每月访问量超过 180 万，每日交易量接近 15 亿美元。',
    gordons_corner_second_info_title: '你是银行或交易所',
    gordons_corner_second_info_subtitle: '您对在gymstreet设计如此酷炫的分馆有何看法？',
    gordons_corner_second_info_subtitle_two: '真的，它可以是你的！',
    gordons_corner_first_news_title: 'NFT 工厂',
    gordons_corner_first_news_description: '我们很快就会为想要帮助我们让元宇宙变得美丽的经验丰富的设计师开设一个创作者俱乐部。同时，他们也可以在糖国上推销他们的 NFT。',
    gordons_corner_second_news_title: '审美 NFT',
    gordons_corner_second_news_description: '元宇宙需要美学。 树木、广告牌、标志、公园、热狗摊等等。 在 Gymstreet 的街道上，你可以找到许多美丽的东西。',
    gordons_corner_third_news_title: '您的头像',
    gordons_corner_third_news_description: '想要自己的头像？ 很快它就会实现。 推出 3D 后开启这快领域！',
    gordons_corner_fourth_news_title: '大众头像',
    gordons_corner_fourth_news_description: '根据服务和地区，人们在 Gymstreet 生活和工作。 想吃个热狗吗？',
    gordons_corner_fifth_news_title: '修理经理',
    gordons_corner_fifth_news_description: 'NFT 会随身携带他们的头像。 修理经理修复 NFT 矿工以提高性能。',

    pr_media_title_main: '公关和媒体',

    coming_soon: '即将来临。。。',
    see_here: '看这里',
    copyright: '版权',
    button_submit: '提交',
    button_more_people: '更多人',
    source: '资源',
    email_address: '电子邮件地址',

    position_chief_executive_officer: '首席执行官 (CEO)',
    position_chief_technology_officer: '首席技术官 (CTO)',
    position_chief_information_officer: '首席信息官 (CIO)',
    position_chief_marketing_officer: '首席营销官 (CMO)',
    position_chief_operations_officer: '首席运营官 (COO)',
    position_principal_engineer: '首席工程师',
    position_moderator: '导师',
    position_concept_artist: '概念艺术家',
    position_editor: '编辑',
    position_lead_designer: '首席设计师',
    position_three_d_team_lead: '3D 团队负责人',
    position_three_d_artist: '3D 艺术家',
    position_two_d_compositor: '2D 合成器',

    // New
    position_senior_marketing_strategist: '高级营销策略师',
    position_senior_project_manager: '项目经理',
    position_senior_marketing_pm: '项目营销经理',
    position_senior_content_writer: '内容作家',
    position_senior_lead_ux_ui_designer: '首席UX/UI设计师',
    position_senior_ux_ui_designer: 'UX/UI设计师',
    position_senior_motion_graphic_designer: '动感和平面设计师',
    position_senior_graphic_designer: '平面设计',

    frank_roehrig_description_first: 'Meta Networks FZCO 的董事总经理，我们在迪拜的元宇宙公司。 他拥有商业地产领域项目开发的背景。',
    frank_roehrig_description_second: '自 2015 年以来，他一直热情地专注于去中心化金融和数字世界。',
    frank_roehrig_description_third: '他的使命是让您尽可能轻松地进入去中心化金融世界',

    raphael_tobelyan_description_first: 'Rafayel Tobelyan 拥有超过十年的高级软件架构师经验。 他为美国和澳大利亚的著名公司制作了系统架构。',
    raphael_tobelyan_description_second: '在从事 30 多个大型项目的同时，Rafayel 在加密货币和区块链方面积累了丰富的经验。 他拥有信息技术硕士学位。',
    raphael_tobelyan_description_third: 'Rafayel今年 30 岁，婚姻美满。',

    david_bras_description_first: 'David Bras 负责监督 Gym Network 的营销和广告计划的规划、开发和执行。',
    david_bras_description_second: '凭借超过 7 年的加密广告经验，他一直是该行业的顶级营销人员之一，在过去 5 年内筹集了超过 4 亿美元。',
    david_bras_description_third: '凭借他独特的营销策略、广泛的加密知识以及与世界领先的加密营销机构的合作，没有任何障碍的让 Gym Network 成为 DeFi 领域最大的参与者之一。',

    alberto_mera_description_first: 'Alberto Mera 在摩根士丹利和瑞银等国际银行拥有多年的传统金融和投资银行业务经验。 他的兴趣在于将金融结构带入去中心化金融和元宇宙。',

    timur_ozturk_description_first: 'Timur 有一种天生的天赋，可以将初时看起来很复杂的事情分解成全面的部分，以帮助那些可能是 DeFi 新手的人轻松了解这里的事情是如何运作。 作为导师，他喜欢向我们的观众展示 Gym Network 的产品，并以有趣和有教育性的方式对其进行解释。',
    timur_ozturk_chief_communication_officer: '首席傳播官',

    mariam_sargsyan_description_first: '作为一名数字营销人员，Mariam Sargsyan 与主要的广告和软件公司合作，并肩走上巅峰。',
    mariam_sargsyan_description_second: '2022 年初，Mariam 加入 Gym Street，担任高级营销策略师。 现在，她提出了战略营销计划，帮助我们与世界各地的人们交流。',
    mariam_sargsyan_description_third: '她的目标是让 DeFi 世界梦想成真的地方做出贡献。',

    anzhelika_volska_description_first: 'Anzhelika 是一个知深项目管理并不局限于简单地规划项目的人。',
    anzhelika_volska_description_second: '她不是一个旁观的经理。但是一个为成功付出一切的人。紧凑的计划者、开发过程中的狂风暴雨、冲突调解人、个人支持提供者和领导者——这就是她的全部',

    jacob_oblasser_description_first: 'Jacob 简报并监控 Gym 团队之间的要求。 由于他在在线营销和品牌方面的经验，他建立了网站背后的概念。',

    diana_kasprovych_description_first: '首席 UX/UI Gym Network 网络项目。 她拥有用户体验和用户界面项目开发、财务项目、加密平台、品牌方面的背景。',
    diana_kasprovych_description_second: '她的任务是思考 Project 的逻辑和用户体验。 Gym 网络平台和网站、cashFT 和 Marketplace 的首席 UX/UI 设计师。与她的团队一起为 CashFT 创建品牌名称。',

    anna_bodnariuk_description_first: 'Gym Street 的首席 UX/UI 设计师和 Gym Network 的 UX/UI 设计师。 她拥有品牌和电子商务项目的背景。',
    anna_bodnariuk_description_second: '最近，她深入研究 Web 3.0 的设计。',
    anna_bodnariuk_description_third: '她的使命是让您与这两个平台的互动变得熟悉且令人兴奋。',

    anna_kulyba_description_first: 'Gymnet 项目的 UX/UI 设计师。 她在为区块链、金融、电子商务、品牌等各个业务领域提供设计解决方案方面拥有丰富的经验。',
    anna_kulyba_description_second: '她的使命是进行用户体验研究，思考产品逻辑并创建视觉设计。',

    matilda_demirjian_description_first: 'Matilda 拥有平面设计学士学位，并在设计机构和广告公司等不同领域拥有超过 10 年的从A到Z指导设计项目的经验。',
    matilda_demirjian_description_second: '目前与我们合作，她为 GYM Network 和 GYM Street 设计我们的元界和加密视觉效果。',
    matilda_demirjian_description_third: 'Matilda 认识到设计是一种强大的工具，可以帮助我们了解我们生活的数字世界。',

    lewis_mhlanga_description_first: 'Lewis Mhlanga 是一位加密货币和房地产投资者、培训师、网络营销专业人士，在该行业拥有超过 6 年的经验。',
    lewis_mhlanga_description_second: '他喜欢多层次营销业务模式，为那些选择利用其奖励计划的人提供改变生活的机会。',

    overview: '概述',
    advisors_title_main: '顾问',

    advisors_travladd_title: 'Travladd',
    advisors_travladd_description_first: 'Travladd Crypto - 加密领域最大的 Telegram 影响者之一，拥有 70,000 多名群组成员。 Travis 是 AMA Central 的首席执行官兼所有者。',
    advisors_travladd_description_second: 'AMA Central 由 DeFi 中最具竞争力的营销团队之一打造。 他们在社交媒体上拥有超过 400,000 名追随者，他们已正式成为市场上最大的 DeFi Telegram 和 Twitter 影响者之一，并将作为我们的官方顾问定期更新他们的社区关于 Gym Network 的信息。',

    advisors_claudio_catrini_title: 'Claudio Catrini',
    advisors_claudio_catrini_description_first: '顾问兼教练克劳迪奥·卡特里尼 (Claudio Catrini) 支持企业家和国际公司进行数字化和去中心化转型。',
    advisors_claudio_catrini_description_second: 'Claudio 在销售和营销领域拥有超过 20 年的经验和实践经验，他确切地知道如何庆祝数字世界中的真正成功以及避免哪些错误以避免不必要地浪费时间、金钱和资源。',

    influencer_title_main: '影响者',

    gymstreet_news_title_main: 'Gymstreet 消息',

    copied: '已復制'
  },
  // Vietnamese
  'viet': {
    header_button_first: 'Khám phá vũ trụ ảo',
    header_button_second: 'Đăng nhập',
    header_button_third: 'Khám phá',
    header_menu_item_first: 'Tài chính trải nghiệm',
    header_menu_item_second: 'Hướng dẫn',
    header_menu_item_third: 'Đội ngũ',
    header_menu_item_fourth: 'Gia nhập',
    header_menu_item_fifth: 'Góc Gorden',
    header_menu_item_sixth: 'PR và Tin tức',

    footer__form__title: 'Subscribe',
    footer__form__subtitle: 'Cập nhật thông tin chi tiết mới nhất của chúng tôi',
    footer_address_first: 'META NETWORKS FZCO',
    footer_address_second: 'Dubai Silicon Oasis',
    footer_address_third: 'DDP, Building A4',
    footer_address_fourth: 'Dubai',
    footer_address_fifth: 'United Arab Emirates',
    footer_address_unit_number: 'Unit Number 213',
    footer_link_first: 'Liên hệ chúng tôi',
    footer_link_second: 'FAQ',
    footer_link_third: 'Chính sách bảo mật',
    footer_link_fourth: 'Điều khoản sử dụng',

    overview_intro_title: 'Cách dễ dàng nhất để trải nghiệm tài chính',
    overview_intro_subtitle: 'Phố Wall trông như thế nào ngày nay và trong 100 năm nữa?',
    overview_intro_subtitle_second: 'DeFi, CeFi, những cơ hội và sản phẩm hấp dẫn',
    overview_intro_button: 'Khám phá Metaverse',
    overview_intro_button_second: 'Đăng nhập',
    overview_info_title: 'Tài chính trải nghiệm là gì',
    overview_info_subtitle: 'Tài chính thông thường và tài chính phi tập trung. Chúng là gì? Để hiểu được sự khác biệt, mọi người cần trải nghiệm nó chứ không chỉ đọc hay nghiên cứu. Trải nghiệm chính là yếu tố then chốt.',
    overview_info_title_second: 'Chúng tôi sẽ dẫn dắt mọi người đến những trải nghiệm này',
    overview_info_subtitle_second: 'Các nhà tư vấn, cố vấn và các đối tác liên kết sẽ giúp người dùng mới bên trong Metaverse cũng như lúc họ tiến vào Metaverse. Đổi lại, họ sẽ nhận được phần thưởng cho công sức của họ và người dùng mới sẽ có được những trải nghiệm dễ dàng hơn.',
    overview_info_title_third: 'Nếu sản phẩm thuyết phục, khách hàng sẽ tiếp tục sử dụng nó',
    overview_info_subtitle_third: 'Khi giới thiệu sản phẩm mới, chúng tôi rất chú trọng đến chất lượng và tính thân thiện với khách hàng. Nếu sản phẩm này dễ sử dụng, nó sẽ rất dễ dàng đến tay khách hàng tiếp theo',
    overview_news_title_main: 'Tin tức Gymstreet',
    overview_info_second_title: 'Làm cách nào để tôi có thể trải nghiệm Gymstreet ở chế độ thử nghiệm',
    overview_info_second_subtitle: 'Chúng tôi đã tạo một môi trường thử nghiệm cho bạn để bạn có thể dễ dàng bắt đầu. Bạn có thể mua đất ảo trong văn bản, chạy thợ đào NFT thử nghiệm và nhiều hơn thế nữa.',
    overview_info_second_title_second: 'Gặp gỡ đội ngũ của chúng tôi',
    overview_info_second_subtitle_second: 'Hãy làm quen với đội ngũ của chúng tôi. Chúng tôi muốn bạn trở thành một phần của nó. Bạn muốn một thử thách?',
    overview_partners_title_main: 'Đối tác của chúng tôi',
    overview_telegram_title_main: 'Bạn cần giúp đỡ?',
    overview_telegram_link: 'Liên lạc ngay!',

    experienced_finance_video_title: 'Câu chuyện tài chính',
    experienced_finance_info_title: 'Bạn có biết rằng các ngân hàng lớn coi Metaverse là một ngành công nghiệp trị giá 8 nghìn tỷ đô la?',
    experienced_finance_review_first_description: '“Nếu chúng ta muốn một nền kinh tế sáng tạo — nếu chúng ta muốn con cái chúng ta có những công việc mà chính chúng ta cũng không thể mơ tới —Làm cách nào để chúng ta xem những nền tảng đó như công dân, công ty và cơ quan quản lý?”',
    experienced_finance_review_first_position: 'Phó chủ tịch, Epic Games \'Unreal Engine Ecosystem',
    experienced_finance_review_second_description: 'Chúng tôi đang cố xác định metaverse theo cách không quá rập khuôn để khiến cho trí tưởng tượng của người sáng tạo bị hạn chế.',
    experienced_finance_review_second_position: 'CEO, Square Enix',
    experienced_finance_review_third_description: 'Tôi nghĩ về metaverse như một sự tiếp nối của nơi mà công nghệ đã dẫn đầu trước COVID. Chúng tôi có Instagram. Chúng tôi có email, chúng tôi có tin nhắn, có những người bạn trong thời gian thực của chúng tôi, hoạt động thực tế mà chúng tôi đang tham gia. Đôi khi có sự giao thoa giữa hai hoạt động đó.',
    experienced_finance_review_third_position: 'CEO, Niantic',
    experienced_finance_review_fourth_description: 'Có hàng trăm triệu người kết nối những môi trường này mỗi ngày. Có rất nhiều những công ty lâu đời nhất trên thế giới đang xây dựng sự hiện diện của họ và chúng ta có thương mại trị giá hàng chục và sắp tới là hàng trăm tỷ đô la.',
    experienced_finance_review_fourth_position: 'đối tác quản lý của EpyllionCo và đối tác tri thức McKinsey',
    experienced_finance_review_fifth_description: 'Hành vi của người tiêu dùng phần lớn đã chuyển sang áp dụng các nhân vật kỹ thuật số, nhưng nhiều thương hiệu vẫn chưa cung cấp giải pháp cho việc này.',
    experienced_finance_review_fifth_position: 'Đồng sáng lập và CEO của AnamXR',
    experienced_finance_jump_into_main_title: 'Bước vào thế giới ảo mới và trải nghiệm tài chính chưa từng có',
    experienced_finance_time_main_title: 'Hiểu về thời gian',
    sandglass_periods_past: 'Quá khứ',
    sandglass_periods_now: 'Hiện tại',
    sandglass_periods_future: 'Tương lai',
    sandglass_info_description_first: 'Nhấp vào một danh mục để hiển thị các lớp bổ sung.',
    sandglass_info_title_second: 'Tương lai',
    sandglass_info_description_second: 'Gymstreet với các chuyên gia tư vấn trong Metaverse',
    sandglass_info_title_third: 'Quá khứ',
    sandglass_info_description_third: 'Ngân hàng 30 năm trước - quầy và tư vấn tại chỗ',
    sandglass_info_title_fourth: 'Hiện tại',
    sandglass_info_first_description_fourth: 'Nền tảng DeFi và tài chính Metaverse',
    sandglass_info_second_description_fourth: 'Ngân hàng như một dịch vụ - Ngân hàng trực tuyến và ngân hàng mới',
    sandglass_info_third_description_fourth: 'Ngân hàng và bảo hiểm qua nhân viên tư vấn tại nhà',
    experienced_finance_infomasses_main_title: 'Chúng tôi mang DeFi và CeFi đến với số đông',
    experienced_finance_infomasses_card_first_title: 'Tư vấn',
    experienced_finance_infomasses_card_first_description: 'Chuyên gia tư vấn và các bậc liên kết của chúng tôi sẽ hướng dẫn cho bạn về các sản phẩm khác nhau và tư vấn cho bạn để cuối cùng bạn có thể tự mình làm chủ việc này.',
    experienced_finance_infomasses_card_second_title: 'Dạy và hướng dẫn',
    experienced_finance_infomasses_card_second_description: 'Trường đại học của chúng tôi cung cấp các khóa học và hội thảo trực tiếp về tất cả các chủ đề trong metaverse của chúng tôi. Những người tham gia từ mới bắt đầu đến chuyên nghiệp cảm thấy rất thoải mái với chúng tôi.',
    experienced_finance_infomasses_card_third_title: 'Các đối tác liên kết hoàn toàn được hoan nghênh',
    experienced_finance_infomasses_card_third_description: 'Chúng tôi là nền tảng metaverse phát triển nhanh nhất và lớn nhất cho DeFi, CeFi và các đối tác liên kết. Bạn muốn giới thiệu sản phẩm của chúng tôi?',
    experienced_finance_infomasses_card_fourth_title: 'Sải bước tốc độ cao',
    experienced_finance_infomasses_card_fourth_description: 'Trong một thị trường nhanh như vậy, tốc độ là một trong những yếu tố quan trọng. Để trở nên nhanh chóng, bạn cần một đội ngũ lớn mạnh và luôn nỗ lực đổi mới.',
    experienced_finance_digits_main_title: 'Metaverse sẽ thay đổi cách mọi người hiểu và sử dụng tiền',
    experienced_finance_digits_item_first: 'đầu tư đã chảy vào metaverse cho đến nay vào năm 2022',
    experienced_finance_digits_item_second: 'người tiêu dùng tích cực trên metaverse đã mua hàng',
    experienced_finance_digits_item_third: 'doanh thu của công ty dự kiến sẽ đến từ metaverse trong 5 năm tới, theo 25% giám đốc điều hành cấp cao',

    guide_video_title: 'Ba cách để hướng dẫn',
    guide_users_main_title: 'Chúng tôi đồng hành cùng người dùng của mình từ khi họ đặt chân vào metaverse đến khi họ đã ở trong metaverse',
    guide_users_main_subtitle: 'Hãy tưởng tượng bạn phải học chơi piano mà không có giáo viên. Đây là những điều sẽ xảy ra với hầu hết mọi người trên đường vào metaverse. Người can đảm mới dám vào cuộc. Quần chúng rộng rãi sẽ không bao giờ đi con đường này một mình.',
    guide_first_card_title: 'Liên kết',
    guide_first_card_description: 'Các đối tác liên kết của chúng tôi phục vụ cho đầu mối contact thứ nhất và thứ hai. Thông tin và trải nghiệm đầu tiên mà người dùng mới có được.',
    guide_second_card_title: 'Tư vấn',
    guide_second_card_description: 'Các chuyên gia tư vấn và Mod của chúng tôi sẽ chỉ cho người dùng cách điều hướng trong metaverse cũng như cách sử dụng và trải nghiệm các sản phẩm',
    guide_third_card_title: 'Cố vấn',
    guide_third_card_description: 'Các cố vấn bên ngoài giúp chúng tôi tích hợp các sản phẩm của họ và nếu cần, làm cho chúng đủ đơn giản để có thể dễ dàng trải nghiệm theo cách tích hợp.',
    guide_team_main_title: 'Gặp gỡ đội ngũ chúng tôi',

    team_title_main_first: 'Gặp gỡ đội ngũ chúng tôi',
    team_title_main: 'Bộ phận phát triển và vận hành',
    team_title_main_second: 'Bộ phận sáng tạo',

    onboarding_video_title: 'Những lợi thế rõ ràng',
    onboarding_video_title_second: 'phương thức hoạt động dễ dàng và sản phẩm chất lượng',
    onboarding_video_subtitle: 'Những tay chơi toàn cầu cũng như các Start Up thú vị đang gia nhập chúng tôi và cung cấp cho bạn một tòa nhà chi nhánh Metaverse hữu hạng.',
    onboarding_text_info_item_first: 'Hãy nghĩ về Phố Wall hiện nay và tưởng tượng nó trong tương lai. DeFi, CeFi, Những cá mập trên toàn cầu và Start-Up. Tất cả trong một trung tâm tài chính khổng lồ - Gymstreet. Chúng tôi mang đến cho khách hàng cách tiếp cận và những cơ hội thân thuộc nhưng được thiết kế mới và thiết kế lại. Song song đó, chúng tôi cũng cung cấp cho các Sản phẩm và Start-Up thú vị với những lợi ích hấp dẫn từ thế giới tài chính.',
    onboarding_text_info_item_second: 'Chúng tôi muốn trở nên đa diện và đại diện cho tất cả các ngành phụ trong metaverse của chúng tôi, những ngành tồn tại hoặc chưa tồn tại.',
    onboarding_text_info_item_second_two: 'Điều quan trọng là chúng tôi phải tạo ra lợi thế cho khách hàng và các chi nhánh của mình theo cùng 1 cách và đồng thời cung cấp cho họ những hướng dẫn cần thiết.',
    onboarding_first_advantage_title: 'Hầm (Vault) của Gymnetwork và DeFi',
    onboarding_first_advantage_description: 'Đối tác chính thức của Alpaca Finance.',
    onboarding_second_advantage_title: 'CashFT',
    onboarding_second_advantage_description: 'Thẻ Debit NFT Lifestyle',
    onboarding_third_advantage_title: 'Trade squares',
    onboarding_third_advantage_description: 'Giao dịch, FX, Hàng hóa',
    onboarding_fourth_advantage_title: 'Trường đại học',
    onboarding_fourth_advantage_description: 'Một cách giáo dục xuất sắc trong lĩnh vực tài chính.',
    onboarding_fifth_advantage_title: 'Trung tâm liên kết',
    onboarding_fifth_advantage_description: 'Đào tạo chất lượng trong lĩnh vực Marketing',
    onboarding_sixth_advantage_title: 'Zuckerland',
    onboarding_sixth_advantage_description: 'Thị trường NFT tập trung vào bất động sản, đất đai và cơ sở hạ tầng',
    onboarding_affiliate_title: 'Giải pháp tiếp thị liên kết đa lớp',
    onboarding_affiliate_subtitle: 'Các đối tác liên kết của chúng tôi sẽ giúp bạn bán sản phẩm của mình thành công hơn nữa. Và chúng tôi với tư cách là người khởi xướng sẽ cung cấp cho bạn các giải pháp phần mềm và cấu trúc, để bạn trở nên nhanh, chất lượng và cuối cùng là thành công hơn nữa, .',
    onboarding_first_digits_title: 'các nhà đầu tư tại hơn 50 quốc gia',
    onboarding_second_digits_title: 'chi nhánh tiếp thị trên toàn thế giới',
    onboarding_third_digits_title: 'giá trị vốn hóa thị trường do Gymnetwork đạt được sau 8 tuần',
    onboarding_fourth_digits_title: 'các nhà phát triển làm việc ở 3 khu vực khác nhau',
    onboarding_metaverse_title_main: 'Gia nhập Metaverse',
    onboarding_metaverse_first_card_title: 'Ngân hàng, sàn giao dịch, bảo hiểm hoặc Fintech',
    onboarding_metaverse_first_card_subtitle: 'Hãy liên hệ với chúng tôi và cho chúng tôi biết lý do tại sao bạn là người hoàn hảo cho Metaverse của chúng tôi',
    onboarding_metaverse_first_card_subtitle_second: 'Trong metaverse tài chính của mình, chúng tôi muốn tích hợp các ngân hàng, sàn giao dịch chứng khoán và công ty bảo hiểm tốt nhất cùng với các sản phẩm của họ.',
    onboarding_metaverse_first_card_subtitle_third: ' Alpaca.finance ',
    onboarding_metaverse_first_card_subtitle_fourth: 'và Lbank đã trực tiếp nắm bắt cơ hội này và quyết định hợp tác để nâng cao vị thế của các sản phẩm hạng nhất của họ.',
    onboarding_metaverse_second_card_title: 'Công nghệ mới',
    onboarding_metaverse_second_card_subtitle: 'Bạn đang có một công nghệ mới, đang nổi lên ở thế giới blockchain? Chúng tôi sẽ rất háo hức lắng nghe!',
    onboarding_metaverse_second_card_subtitle_second: 'Mỗi tháng đều có các công ty khởi nghiệp mới và đang nổi lên trong thế giới blockchain và fintech. Chúng tôi muốn cung cấp cho họ một nền tảng để được chú ý nhiều hơn và đồng thời có thể thu hút các nhà đầu tư và đối tác liên kết của chúng tôi. Chúng tôi ủng hộ sự tiến bộ liên tục về lâm nghiệp và sự đổi mới hoàn toàn cần thiết trong lĩnh vực metaverse.',
    onboarding_metaverse_third_card_title: 'Token hay Coin',
    onboarding_metaverse_third_card_subtitle: 'Bạn nghĩ rằng dự án của bạn là bền vững trong thị trường tài sản kỹ thuật số và ý tưởng của bạn là duy nhất? Hãy nói chuyện nào!',
    onboarding_metaverse_fourth_card_title: 'Sản phẩm tài chính và dự án tài chính',
    onboarding_metaverse_fourth_card_subtitle: 'Giải pháp của bạn có mang lại lợi thế rõ ràng cho cộng đồng của chúng tôi không? Xin vui lòng áp dụng.',
    onboarding_metaverse_fifth_card_title: 'LBANK exchange',
    onboarding_metaverse_fifth_card_subtitle: 'Sàn giao dịch tiền điện tử phổ biến đứng thứ 15 trong danh sách Sàn giao dịch tiền điện tử hàng đầu của CoinMarketCap có hơn 1,8 triệu lượt truy cập hàng tháng và khối lượng giao dịch hàng ngày gần 1,5 tỷ đô la.',
    onboarding_metaverse_title_main_second: 'Gia nhập Metaverse (Onboarding)',
    onboarding_metaverse_first_card_description: 'Chúng tôi thực hiện việc Gia Nhập (Onboarding) 3D. Trong bước đầu tiên, chúng tôi nêu tên các nét đặc sắc khi hợp tác với chúng tôi cũng như lợi ích cho các đối tác liên kết của chúng tôi.',
    onboarding_metaverse_second_card_description: 'Bước thứ hai, bạn chọn một hoặc nhiều lô đất (Parcel) trên vùng đất ảo (Virtual Land) của chúng tôi hoặc thậm chí là một nơi công cộng (Public place). Bạn có thể mua hoặc thuê.',
    onboarding_metaverse_third_card_description: 'Bước thứ 3, bạn ủy quyền cho công ty chúng tôi thiết kế tòa nhà ảo của bạn và nhận được đại diện hạng nhất của mình trong metaverse của chúng tôi.',
    onboarding_metaverse_fourth_card_description: 'Họ ăn mừng khai trương cùng với chúng tôi, các đối tác của chúng tôi và các khách hàng tiềm năng.',
    onboarding_metaverse_first_info_title: 'Thuyết trình - cung cấp giá trị thêm vào',
    onboarding_metaverse_first_info_subtitle: 'Chúng tôi muốn cung cấp thường xuyên cho cộng đồng của mình các giá trị gia tăng miễn phí cho bất kỳ sản phẩm nào. Nội dung là thứ trọng yếu đối với chúng tôi.',
    onboarding_metaverse_second_info_title: 'Xu hướng, fintech, đổi mới, động lực',
    onboarding_metaverse_second_info_subtitle: 'Bạn có một chủ đề mà bạn nắm vững và muốn chia sẻ nó với chúng tôi? Cứ liên lạc nếu cần!',

    gordons_corner_video_title: 'Một chút bật mí về Metaverse của chúng tôi',
    gordons_corner_first_info_title: 'Bạn có muốn gặp Gordon?',
    gordons_corner_first_info_subtitle: 'Tất nhiên, chúng tôi tuyên bố tạo ra một Metvaerse rất thực tế và đồng thời mang tính tương lai. Nhưng vui chơi cũng không được phép thiếu ...',
    gordons_corner_first_info_subtitle_two: 'Metaverse ingame đầu tiên, cũng sẽ đóng vai trò là như là hướng dẫn. Trò chơi "Tìm Gordon" sẽ hướng dẫn cho bạn về Gymstreet và trao phần thưởng khi bạn tìm thấy anh ta!',
    gordons_corner_digit_title_main: 'Lộ trình của chúng tôi',
    gordons_corner_digit_first_item_title: 'Điểm kinh nghiệm và những khoảng bán đất ban đầu',
    gordons_corner_digit_second_item_title: 'Điểm kinh nghiệm và Kích hoạt Chế độ Cho thuê NFT',
    gordons_corner_digit_third_item_title: 'Kích hoạt điểm kinh nghiệm',
    gordons_corner_sneak_peak_main_title: 'Bật mí về các tòa nhà của chúng tôi',
    gordons_corner_sneak_peak_first_card_title: 'Hầm (Vault) của Gymnet',
    gordons_corner_sneak_peak_first_card_description: 'Đối tác chính thức của chúng tôi ở đây là Alpaca Finance và bạn có thể dễ dàng sử dụng các Vault này và cũng có thể trải nghiệm các sản phẩm theo phong cách metaverse.',
    gordons_corner_sneak_peak_second_card_title: 'CashFT',
    gordons_corner_sneak_peak_second_card_description: 'Thẻ Ghi nợ Phong cách sống NFT với nhiều giải pháp thanh toán xuyên biên giới bên trong và phần thưởng độc đáo, nhưng Giới hạn. Check ngay nào',
    gordons_corner_sneak_peak_third_card_title: 'Zuckerland',
    gordons_corner_sneak_peak_third_card_description: 'Thị trường NFT tập trung vào bất động sản, đất đai và cơ sở hạ tầng sẽ cung cấp dữ liệu, những phân tích thú vị và nhiều hơn thế nữa cho bạn. Hãy tham quan bên trong.',
    gordons_corner_sneak_peak_fourth_card_title: 'Trung tâm liên kết',
    gordons_corner_sneak_peak_fourth_card_description: 'Training, Workshops về Tiếp thị và hơn thế nữa. Tài liệu, Khuyến mãi, các chi nhánh khác và nhiều tài liệu.',
    gordons_corner_sneak_peak_fifth_card_title: 'LBANK exchange',
    gordons_corner_sneak_peak_fifth_card_description: 'Sàn giao dịch tiền điện tử phổ biến đứng thứ 15 trong danh sách Sàn giao dịch tiền điện tử hàng đầu của CoinMarketCap có hơn 1,8 triệu lượt truy cập hàng tháng và khối lượng giao dịch hàng ngày gần 1,5 tỷ đô la.',
    gordons_corner_second_info_title: 'Bạn là một ngân hàng hay một sàn giao dịch',
    gordons_corner_second_info_subtitle: 'Ý kiến ​​của bạn về việc có một tòa nhà chi nhánh được thiết kế mát mẻ như vậy ở gymstreet?',
    gordons_corner_second_info_subtitle_two: 'Đúng vậy, nó có thể là của bạn!',
    gordons_corner_first_news_title: 'Nhà máy cho NFTs',
    gordons_corner_first_news_description: 'Chúng tôi sẽ sớm mở Câu lạc bộ người sáng tạo dành cho các nhà thiết kế có kinh nghiệm muốn giúp chúng tôi làm cho Metaverse trở nên đẹp hơn. Đồng thời, họ cũng có thể tiếp thị NFT của mình trên Zuckerland.',
    gordons_corner_second_news_title: 'Những NFT đầy thẩm mỹ',
    gordons_corner_second_news_description: 'Một Metaverse cần tính thẩm mỹ. Cây xanh, biển quảng cáo, biển hiệu, công viên, quầy bán xúc xích và nhiều hơn thế nữa. Trên các con phố của Gymstreet bạn có thể tìm thấy nhiều thứ đẹp đẽ.',
    gordons_corner_third_news_title: 'Avatar (thế thân) của bạn',
    gordons_corner_third_news_description: 'Muốn thế thân (Avatar) của riêng bạn? Chẳng bao lâu nữa sẽ trở thành sự thật. Kỉ nguyên này sẽ bắt đầu sau màn ra mắt 3D',
    gordons_corner_fourth_news_title: 'Thế thân công cộng',
    gordons_corner_fourth_news_description: 'Tùy thuộc vào dịch vụ và khu vực, người dân sinh sống và làm việc tại Gymstreet. Bạn muốn 1 chiếc xúc xích? \'',
    gordons_corner_fifth_news_title: 'Người quản lý sửa chữa',
    gordons_corner_fifth_news_description: 'NFTs mang theo Avatar. Người quản lý sửa chữa sẽ sửa chữa các thợ đào NFT ảo để tăng hiệu suất.',

    pr_media_title_main: 'PR và Truyền thông',

    coming_soon: 'Sắp có…',
    see_here: 'Xem tại đây',
    copyright: 'Bản quyền',
    button_submit: 'Gửi đi',
    button_more_people: 'Thêm',
    source: 'Nguồn:',
    email_address: 'Địa chỉ email',

    position_chief_executive_officer: 'Giám đốc điều hành (CEO)',
    position_chief_technology_officer: 'Giám đốc Công nghệ (CTO)',
    position_chief_information_officer: 'Giám đốc Thông tin (CIO)',
    position_chief_marketing_officer: 'Giám đốc Tiếp thị (CMO)',
    position_chief_operations_officer: 'Giám đốc điều hành (COO)',
    position_principal_engineer: 'Kỹ sư chính',
    position_moderator: 'MOD',
    position_concept_artist: 'Họa sĩ ý tưởng',
    position_editor: 'Biên tập viên',
    position_lead_designer: 'Thiết kế chính',
    position_three_d_team_lead: 'Trưởng team 3D',
    position_three_d_artist: 'Họa sĩ 3D',
    position_two_d_compositor: 'Người soạn 2D',

    // New
    position_senior_marketing_strategist: 'Nhà chiến lược tiếp thị cấp cao',
    position_senior_project_manager: 'Quản lí dự án',
    position_senior_marketing_pm: 'Giám đốc Marketing dự án',
    position_senior_content_writer: 'Người viết nội dung',
    position_senior_lead_ux_ui_designer: 'Trưởng thiết kế UX/UI',
    position_senior_ux_ui_designer: 'Thiết kế UX/UI',
    position_senior_motion_graphic_designer: 'Thiết kế đồ họa và chuyển động',
    position_senior_graphic_designer: 'Thiết kế đồ họa',

    frank_roehrig_description_first: 'Giám đốc điều hành của Meta Networks FZCO Công ty Metaverse của chúng tôi ở Dubai. Anh có kiến thức chuyên môn về phát triển dự án trong lĩnh vực bất động sản thương mại.',
    frank_roehrig_description_second: 'Kể từ năm 2015, anh đã say mê tập trung vào tài chính phi tập trung và thế giới kỹ thuật số.',
    frank_roehrig_description_third: 'Nhiệm vụ của anh ấy là làm cho hành trình của bạn vào thế giới tài chính phi tập trung dễ dàng nhất có thể.',

    raphael_tobelyan_description_first: 'Rafayel Tobelyan tự hào có hơn mười năm kinh nghiệm với tư cách là Kiến trúc sư phần mềm cao cấp. Anh đã tạo ra kiến trúc hệ thống cho các công ty nổi tiếng của Hoa Kỳ và Úc.',
    raphael_tobelyan_description_second: 'Khi làm việc với hơn 30 dự án quy mô lớn, Rafayel tích lũy nhiều kinh nghiệm trong lĩnh vực tiền điện tử và blockchain. Ông có bằng Thạc sĩ về Công nghệ Thông tin.',
    raphael_tobelyan_description_third: 'Rafayel năm nay 30 tuổi và đang kết hôn hạnh phúc.',

    david_bras_description_first: 'David Bras chịu trách nhiệm giám sát việc lập kế hoạch, phát triển và thực hiện các sáng kiến tiếp thị và quảng cáo của Gym Network.',
    david_bras_description_second: 'Với hơn 7 năm kinh nghiệm quảng cáo tiền điện tử, anh ấy đã là một trong những nhà tiếp thị hàng đầu trong ngành này, có thể huy động được hơn 400 triệu đô la trong vòng 5 năm qua.',
    david_bras_description_third: 'Với các chiến lược tiếp thị độc đáo, nhiều kiến thức về tiền điện tử và sự hợp tác với các đại lý tiếp thị tiền điện tử hàng đầu thế giới, không có rào cản nào có thể ngăn cản Gym Network trở thành một trong những người chơi lớn nhất trong DeFi Space.',

    alberto_mera_description_first: 'Alberto Mera có nhiều năm kinh nghiệm trong lĩnh vực tài chính truyền thống và ngân hàng đầu tư tại các ngân hàng quốc tế như Morgan Stanley và UBS. Mối quan tâm của anh ấy là đưa các cấu trúc tài chính đến với thế giới tài chính phi tập trung và metaverse.',

    timur_ozturk_description_first: 'Timur có tài năng bẩm sinh trong việc chia nhỏ những thứ thoạt nghe có vẻ phức tạp thành những mảng toàn diện để giúp những người sử dụng DeFi mới có thể dễ dàng hiểu được cách thức mọi thứ đang hoạt động ở DeFi. Với tư cách là người kiểm duyệt (MOD), anh ấy thích giới thiệu các sản phẩm của Gym Network cho công chúng của chúng tôi và giải thích chúng theo cách rất giải trí và mang tính giáo dục cao.',
    timur_ozturk_chief_communication_officer: 'Giám đốc truyền thông',

    mariam_sargsyan_description_first: 'Khởi nghiệp với tư cách là một nhà tiếp thị kỹ thuật số, Mariam Sargsyan đã vươn lên dẫn đầu trong việc hợp tác với các công ty phần mềm và quảng cáo lớn.',
    mariam_sargsyan_description_second: 'Đầu năm 2022, Mariam gia nhập Gym Street với tư cách là nhà chiến lược tiếp thị cấp cao. Giờ đây, cô ấy đưa ra các kế hoạch tiếp thị chiến lược giúp chúng tôi giao tiếp với mọi người trên toàn thế giới.',
    mariam_sargsyan_description_third: 'Mục tiêu của cô là góp phần biến DeFi trở thành nơi biến ước mơ trở thành hiện thực.',

    anzhelika_volska_description_first: 'Anzhelika biết rằng quản lý dự án không chỉ đơn giản là lập kế hoạch cho một dự án.',
    anzhelika_volska_description_second: 'Cô ấy không phải là một người quản lý quan sát từ bên lề. Nhưng là người cho đi tất cả mọi thứ để thành công. Một người lập kế hoạch nhỏ gọn, một cơn bão dữ dội trong quá trình phát triển, một người hòa giải xung đột, một nhà cung cấp hỗ trợ cá nhân và một nhà lãnh đạo - đó là tất cả những gì cô ấy là.',

    jacob_oblasser_description_first: 'Jacob tóm tắt, giám sát, điều phối các yêu cầu giữa các đội ngũ Gym Network. Do có kinh nghiệm trong lĩnh vực tiếp thị trực tuyến và xây dựng thương hiệu, Jakob là người xây dựng khái niệm đằng sau các trang web.',

    diana_kasprovych_description_first: 'Trưởng thiết kế UX / UI của các dự án Gym Network. Cô ấy có kiến thức nền tảng về phát triển dự án trong Trải nghiệm người dùng và Giao diện người dùng, các dự án tài chính, nền tảng tiền điện tử, xây dựng thương hiệu.',
    diana_kasprovych_description_second: 'Nhiệm vụ của cô ấy là nghĩ về Logic và UX của Project. Trưởng nhóm thiết kế UX / UI của Nền tảng mạng phòng tập và trang web, cashFT và Marketplace. Tạo thương hiệu cho CashFT với nhóm của cô ấy.',

    anna_bodnariuk_description_first: 'Trưởng nhóm thiết kế UX / UI của Gym Street và UX / UI Designer của Gym Network. Cô có kiến thức nền tảng về các dự án xây dựng thương hiệu và thương mại điện tử.',
    anna_bodnariuk_description_second: 'Gần đây, cô ấy đang tập trung thiết kế cho Web 3.0.',
    anna_bodnariuk_description_third: 'Nhiệm vụ của cô ấy là làm cho tương tác của bạn với cả hai nền tảng trở nên độc đáo và thú vị.',

    anna_kulyba_description_first: 'Nhà thiết kế UX / UI tại dự án Gymnet. Cô có kinh nghiệm trong việc cung cấp các giải pháp thiết kế cho nhiều lĩnh vực kinh doanh khác nhau như blockchain, tài chính, thương mại điện tử, xây dựng thương hiệu.',
    anna_kulyba_description_second: 'Nhiệm vụ của cô là thực hiện nghiên cứu UX, suy nghĩ cho tính logic của sản phẩm và tạo ra thiết kế trực quan.',

    matilda_demirjian_description_first: 'Matilda có bằng Cử nhân Thiết kế đồ họa và hơn 10 năm kinh nghiệm chỉ đạo các dự án thiết kế từ A đến Z trong các lĩnh vực khác nhau như đại lý thiết kế và công ty quảng cáo.',
    matilda_demirjian_description_second: 'Hiện đang làm việc với chúng tôi, cô ấy thiết kế hình ảnh meta và tiền điện tử của chúng tôi cho Mạng lưới GYM và GYM Street.',
    matilda_demirjian_description_third: 'Matilda nhận ra rằng thiết kế là một công cụ mạnh mẽ giúp chúng ta hiểu được thế giới kỹ thuật số mà chúng ta đang sống.',

    lewis_mhlanga_description_first: 'Lewis Mhlanga là Nhà đầu tư tiền điện tử & bất động sản, nhà đào tạo, nhà tiếp thị mạng chuyên nghiệp với hơn 6 năm trong ngành này.',
    lewis_mhlanga_description_second: 'Anh ấy thích mô hình kinh doanh bán hàng trực tiếp được tích hợp những cơ hội có tính thay đổi cuộc đời cho những người sẵn sàng tận dụng những lợi ích mà chương trình tặng thưởng mang lại.',

    overview: 'Tổng quan',
    advisors_title_main: 'Cố vấn',

    advisors_travladd_title: 'Travladd',
    advisors_travladd_description_first: 'Travladd Crypto - một trong những người có ảnh hưởng Telegram lớn nhất trong không gian tiền điện tử với hơn 70.000 thành viên nhóm. Travis là Giám đốc điều hành và Chủ sở hữu AMA Central.',
    advisors_travladd_description_second: 'AMA Central được xây dựng bởi một trong những đội tiếp thị cạnh tranh nhất ở DeFi. Với hơn 400.000 người theo dõi trên mạng xã hội của họ, họ đã chính thức trở thành một trong những người có ảnh hưởng trên DeFi Telegram và Twitter lớn nhất trên thị trường và sẽ thường xuyên cập nhật cộng đồng của họ về Gym Network với tư cách là cố vấn chính thức của chúng tôi.',

    advisors_claudio_catrini_title: 'Claudio Catrini',
    advisors_claudio_catrini_description_first: 'Nhà tư vấn và huấn luyện viên Claudio Catrini hỗ trợ các doanh nhân và các công ty quốc tế trong việc chuyển đổi bản thân về mặt kỹ thuật số và đạo đức.',
    advisors_claudio_catrini_description_second: 'Với hơn 20 năm kinh nghiệm và thực hành trong lĩnh vực bán hàng & tiếp thị, Claudio biết chính xác cách ăn mừng những thành công thực sự trong thế giới kỹ thuật số và những sai lầm nào cần tránh để không lãng phí thời gian, tiền bạc và nguồn lực một cách không cần thiết.',

    influencer_title_main: 'Người có ảnh hưởng',

    gymstreet_news_title_main: 'Gymstreet Tin tức',

    copied: 'Đã sao chép'
  },
  // Thai
  'th': {
    header_button_first: 'สำรวจเมตาเวิร์ส',
    header_button_second: 'เข้าสู่ระบบ',
    header_button_third: 'สำรวจ',
    header_menu_item_first: 'การเงินที่มีประสบการณ์',
    header_menu_item_second: 'แนะนำ',
    header_menu_item_third: 'ทีม',
    header_menu_item_fourth: 'โปรแกรมอบรมและพัฒนา',
    header_menu_item_fifth: 'กอร์ดอน คอร์เนอร์',
    header_menu_item_sixth: 'PR และข่าวสาร',

    footer__form__title: 'ติดตาม',
    footer__form__subtitle: 'ติดตามข้อมูลเชิงลึกล่าสุดของเราอยู่เสมอ',
    footer_address_first: 'META NETWORKS FZCO',
    footer_address_second: 'Dubai Silicon Oasis',
    footer_address_third: 'DDP, Building A4',
    footer_address_fourth: 'Dubai',
    footer_address_fifth: 'United Arab Emirates',
    footer_address_unit_number: 'Unit Number 213',
    footer_link_first: 'ติดต่อเรา',
    footer_link_second: 'คำถามที่พบบ่อย',
    footer_link_third: 'นโยบายความเป็นส่วนตัว',
    footer_link_fourth: 'ข้อกำหนดการใช้งาน',

    overview_intro_title: 'วิธีที่ง่ายที่สุดในการสัมผัสกับการเงิน',
    overview_intro_subtitle: 'วอลล์สตรีทจะหน้าตาเป็นอย่างไรในวันนี้และในอีก 100 ปีข้างหน้า',
    overview_intro_subtitle_second: 'DeFi, CeFi, โอกาสและผลิตภัณฑ์ที่น่าตื่นเต้น',
    overview_intro_button: 'สำรวจเมตาเวิร์ส',
    overview_intro_button_second: 'เข้าสู่ระบบ',
    overview_info_title: 'การเงินที่มีประสบการณ์คืออะไร',
    overview_info_subtitle: 'มีการเงินทั่วไปและการเงินแบบกระจายอำนาจ เพื่อให้เข้าใจถึงความแตกต่าง ผู้คนต้องสัมผัสมัน ไม่ใช่แค่อ่านหรือศึกษามันเท่านั้น ประสบการณ์คือกุญแจสำคัญ',
    overview_info_title_second: 'เรานำพาผู้คนไปสู่ประสบการณ์เหล่านี้: การเติบโตที่ควบคุมได้',
    overview_info_subtitle_second: 'ผู้ให้คำปรึกษา ผู้ให้คำแนะนำ และพันธมิตรในเครือช่วยผู้ใช้ใหม่เข้าสู่และภายในเมตาเวิร์ส ในทางกลับกัน พวกเขาได้รับรางวัลและผู้ใช้ใหม่จะพบประสบการณ์ได้ง่ายขึ้น',
    overview_info_title_third: 'หากผลิตภัณฑ์มีความน่าเชื่อถือ ลูกค้าก็จะจูงใจตัวเองให้ใช้ผลิตภัณฑ์นั้น',
    overview_info_subtitle_third: 'เมื่อเริ่มใช้งานผลิตภัณฑ์ใหม่ เราให้ความสำคัญกับคุณภาพและความสะดวกในการใช้งานเป็นอย่างมาก ถ้ามันใช้ง่าย ก็ง่ายค่อการแนะนำ',
    overview_news_title_main: 'ข่าว Gymstreet',
    overview_info_second_title: 'ฉันจะสัมผัสประสบการณ์ Gymstreet ในโหมดทดสอบได้อย่างไร',
    overview_info_second_subtitle: 'เราได้สร้างสภาพแวดล้อมการทดสอบสำหรับคุณเพื่อให้คุณสามารถเริ่มต้นได้อย่างง่ายดาย คุณสามารถซื้อที่ดินเสมือนจริงในข้อความ เรียกใช้นักขุด NFT ในโหมดทดสอบ และอื่น ๆ อีกมากมาย',
    overview_info_second_title_second: 'พบกับทีมงานของเรา',
    overview_info_second_subtitle_second: 'ทำความรู้จักกับทีมงานของเรา เราอยากให้คุณเป็นส่วนหนึ่งของมัน กล้ารับความท้าทายไหม',
    overview_partners_title_main: 'พันธมิตรของเรา',
    overview_telegram_title_main: 'เราจะช่วยคุณได้อย่างไร',
    overview_telegram_link: 'ได้รับการติดต่อ!',

    experienced_finance_video_title: 'เรื่องราวของการเงิน',
    experienced_finance_info_title: 'คุณรู้หรือไม่ว่าธนาคารขนาดใหญ่มองว่เมตาเวิร์สเป็นอุตสาหกรรมที่มีมูลค่าถึง 8 ล้านล้านดอลลาร์ ',
    experienced_finance_review_first_description: '“ถ้าเราต้องการเศรษฐกิจแบบผู้สร้าง—ถ้าเราต้องการให้ลูกหลานของเรามีงานที่เราไม่สามารถแม้แต่จะฝันถึงได้—เราจะคิดอย่างไรเกี่ยวกับแพลตฟอร์มเหล่านั้นในฐานะพลเมือง บริษัท และหน่วยงานกำกับดูแล”',
    experienced_finance_review_first_position: 'รองประธานระบบนิเวศ Unreal Engine ของ Epic Games',
    experienced_finance_review_second_description: '“เรากำลังพยายามที่จะไม่กำหนดเมตาเวิร์สอย่างเข้มงวดจนจำกัดจินตนาการของผู้สร้าง”',
    experienced_finance_review_second_position: 'CEO ของ Square Enix',
    experienced_finance_review_third_description: 'ฉันคิดว่า metaverse เป็นความต่อเนื่องของเทคโนโลยีที่มุ่งไปข้างหน้าก่อนเกิดโควิด เรามีอินสตาแกรม เรามีอีเมล เรามีข้อความ มีเพื่อนแบบเรียลไทม์ของเรา กิจกรรมในชีวิตจริงที่เราเข้าร่วม บางครั้งอาจมีจุดตัดระหว่างสองคนนี้',
    experienced_finance_review_third_position: 'CEO, Niantic',
    experienced_finance_review_fourth_description: '“มีคนหลายร้อยล้านคนที่เชื่อมต่อกับสภาพแวดล้อมเหล่านี้ทุกวัน มีบริษัทที่มีประวัติมากที่สุดในโลกหลายแห่งที่สร้างการแสดงตน และเรามีการค้าขายในหลักสิบและอีกไม่นานจากนี้จะมีเงินหลายแสนล้านดอลลาร์”',
    experienced_finance_review_fourth_position: 'หุ้นส่วนผู้จัดการของ EpyllionCo และหุ้นส่วนความรู้ McKinsey',
    experienced_finance_review_fifth_description: '“พฤติกรรมของผู้บริโภคได้เปลี่ยนไปใช้บุคลิกดิจิทัลเป็นส่วนใหญ่ แต่แบรนด์จำนวนมากยังไม่ได้เสนอวิธีแก้ปัญหา”',
    experienced_finance_review_fifth_position: 'ผู้ร่วมก่อตั้งและ CEO ของ AnamXR',
    experienced_finance_jump_into_main_title: 'ก้าวกระโดดเข้าสู่โลกเสมือนจริงใหม่และสัมผัสกับการเงินอย่างที่ไม่เคยมีมาก่อน',
    experienced_finance_time_main_title: 'เข้าใจเวลา',
    sandglass_periods_past: 'อดีต',
    sandglass_periods_now: 'ตอนนี้',
    sandglass_periods_future: 'อนาคต',
    sandglass_info_description_first: 'คลิกที่หมวดหมู่เพื่อดูระดับเพิ่มเติม',
    sandglass_info_title_second: 'อนาคต',
    sandglass_info_description_second: 'Gymstreet กับผู้ให้ปรึกษาในเมตาเวิร์ส',
    sandglass_info_title_third: 'อดีต',
    sandglass_info_description_third: 'ธนาคารเมื่อ 30 ปีที่แล้ว - เคาน์เตอร์และคำแนะนำในสถานที่',
    sandglass_info_title_fourth: 'ตอนนี้',
    sandglass_info_first_description_fourth: 'แพลตฟอร์ม DeFi และการเงินเมตาเวิร์ส',
    sandglass_info_second_description_fourth: 'การธนาคารในฐานะบริการ - ออนไลน์และธนาคารใหม่ ',
    sandglass_info_third_description_fourth: 'การธนาคารและการประกันภัยผ่านผู้ให้คำแนะนำที่บ้าน',
    experienced_finance_infomasses_main_title: 'เรานำ DeFi และ CeFi มาสู่มวลชน ',
    experienced_finance_infomasses_card_first_title: 'ปรึกษาและรับคำแนะนำ',
    experienced_finance_infomasses_card_first_description: 'ผู้ให้คำปรึกษาและบริษัทในเครือของเราจะแสดงให้คุณเห็นถึงผลิตภัณฑ์ต่าง ๆ และแนะนำคุณเพื่อที่คุณจะได้เชี่ยวชาญในตัวเองในที่สุด',
    experienced_finance_infomasses_card_second_title: 'สอนและให้ความรู้',
    experienced_finance_infomasses_card_second_description: 'ในมหาวิทยาลัยของเรา เราเสนอหลักสูตรและเวิร์กช็อปสดในทุกหัวข้อในเมตาเวิร์สของเรา ผู้เริ่มต้นจนถึงมืออาชีพรู้สึกสบายใจกับเรามาก',
    experienced_finance_infomasses_card_third_title: 'ยินดีต้อนรับพันธมิตร',
    experienced_finance_infomasses_card_third_description: 'เราเป็นแพลตฟอร์มเมตาเวิร์สที่เติบโตเร็วที่สุดและใหญ่ที่สุดสำหรับ DeFi, CeFi และหุ้นส่วนพันธมิตร คุณต้องการรับคำแนะนำผลิตภัณฑ์ของเราหรือไม่',
    experienced_finance_infomasses_card_fourth_title: 'เคลื่อนที่ด้วยความเร็ว',
    experienced_finance_infomasses_card_fourth_description: 'ในตลาดที่รวดเร็วเช่นนี้ ความเร็วเป็นหนึ่งในปัจจัยสำคัญ เพื่อความรวดเร็ว คุณต้องมีทีมที่แข็งแกร่งและมีขนาดใหญ่ และมีแรงผลักดันอย่างต่อเนื่องเพื่อสร้างสรรค์สิ่งใหม่ ๆ',
    experienced_finance_digits_main_title: 'Metaverse จะเปลี่ยนวิธีที่ผู้คนเข้าใจและใช้เงิน',
    experienced_finance_digits_item_first: 'ในการลงทุนได้ไหลเข้าสู่เมตาเวิร์สจนถึงปี 2565',
    experienced_finance_digits_item_second: 'ของผู้บริโภคที่ใช้งานเมตาเวิร์สได้ทำการซื้อ',
    experienced_finance_digits_item_third: 'ของรายได้องค์กรคาดว่าจะมาจากเมตาเวิร์สในอีก 5 ปีข้างหน้าตาม 25% ของผู้บริหารระดับสูง',

    guide_video_title: 'สามวิธีในการแนะนำ',
    guide_users_main_title: 'เราแนะนำผู้ใช้ของเราในการเข้าสู่และภายในเมตาเวิร์ส',
    guide_users_main_subtitle: 'ลองนึกภาพว่าคุณต้องเรียนเปียโนโดยไม่มีครู นี่คือสิ่งที่เกิดขึ้นกับคนส่วนใหญ่ระหว่างทางเข้าสู่เมตาเวิร์ส คนกล้าหาญกล้าเข้า มวลชนในวงกว้างจะไม่ไปหนทางนี้เพียงลำพัง',
    guide_first_card_title: 'พันธมิตร',
    guide_first_card_description: 'หุ้นส่วนพันธมิตรของเราให้บริการการติดต่อครั้งแรกและครั้งที่สอง ข้อมูลและประสบการณ์ครั้งแรกที่ผู้ใช้ใหม่มี',
    guide_second_card_title: 'ผู้ให้คำปรึกษา',
    guide_second_card_description: 'ที่ปรึกษาและผู้ควบคุมของเราแสดงให้ผู้ใช้เห็นถึงวิธีการนำทางภายในเมตาเวิร์สและวิธีใช้และสัมผัสผลิตภัณฑ์',
    guide_third_card_title: 'ผู้ให้คำแนะนำ',
    guide_third_card_description: 'ผู้ให้คำแนะนำภายนอกช่วยให้เรารวมผลิตภัณฑ์ของพวกเขา และหากจำเป็น ทำให้พวกเขาง่ายพอที่จะมีประสบการณ์ได้อย่างง่ายดายในลักษณะบูรณาการ',
    guide_team_main_title: 'พบกับทีมงานของเรา',

    team_title_main_first: 'พบกับทีมงานของเรา',
    team_title_main: 'ฝ่ายพัฒนาและปฏิบัติการ',
    team_title_main_second: 'ฝ่ายสร้างสรรค์',

    onboarding_video_title: 'ข้อดีที่เห็นได้ชัด',
    onboarding_video_title_second: 'ใช้งานง่ายและผลิตภัณฑ์ดี',
    onboarding_video_subtitle: 'เราเข้าร่วมกับผู้เล่นระดับโลกเช่นเดียวกับการเริ่มต้นธุรกิจที่น่าตื่นเต้นและมอบการสร้างสาขาเมตาเวิร์ส ระดับเฟิร์สคลาสให้กับคุณ',
    onboarding_text_info_item_first: 'ลองนึกภาพวอลล์สตรีทที่มันอาจดูเหมือนวันนี้และอนาคต DeFi, CeFi, ผู้เล่นรายใหญ่ระดับโลกและสตาร์ทอัพ ทั้งหมดในศูนย์การเงินขนาดใหญ่แห่งเดียว - Gymstreet เราเสนอการเข้าถึงและโอกาสที่คุ้นเคย แต่ออกแบบล่าสุดและออกแบบใหม่อีกครั้งให้แก่ลูกค้าของเรา ในขณะเดียวกัน เรายังเสนอสตาร์ทอัพและผลิตภัณฑ์ที่น่าสนใจอย่างยิ่งพร้อมสิทธิประโยชน์ที่น่าตื่นเต้นจากโลกการเงิน',
    onboarding_text_info_item_second: 'เราต้องการที่จะมีหลายแง่มุมและเป็นตัวแทนของอุตสาหกรรมย่อยทั้งหมดในเมตาเวิร์สของเราซึ่งมีอยู่หรือยังไม่มีอยู่',
    onboarding_text_info_item_second_two: 'ถือเป็นสิ่งสำคัญสำหรับเราที่จะสร้างข้อได้เปรียบให้กับลูกค้าและบริษัทในเครือในลักษณะเดียวกัน และเพื่อให้ความรู้ที่จำเป็นแก่พวกเขาในเวลาเดียวกัน',
    onboarding_first_advantage_title: 'ห้องนิรภัย Gymnetwork Vaults และ DeFi',
    onboarding_first_advantage_description: 'พันธมิตรอย่างเป็นทางการของ Alpaca Finance',
    onboarding_second_advantage_title: 'CashFT',
    onboarding_second_advantage_description: 'บัตรเดบิต NFT ไลฟ์สไตล์',
    onboarding_third_advantage_title: 'เทรดสแควร์',
    onboarding_third_advantage_description: 'การซื้อขาย FX สินค้าโภคภัณฑ์',
    onboarding_fourth_advantage_title: 'มหาวิทยาลัย',
    onboarding_fourth_advantage_description: 'การศึกษาที่ยอดเยี่ยมในสาขาการเงิน',
    onboarding_fifth_advantage_title: 'ศูนย์พันธมิตร',
    onboarding_fifth_advantage_description: 'การศึกษาที่มีคุณภาพในด้านการตลาด',
    onboarding_sixth_advantage_title: 'ซัคเกอร์แลนด์',
    onboarding_sixth_advantage_description: 'ตลาด NFT มุ่งเน้นไปที่อสังหาริมทรัพย์ ที่ดิน และโครงสร้างพื้นฐาน',
    onboarding_affiliate_title: 'โซลูชั่นระดับพันธมิตร',
    onboarding_affiliate_subtitle: 'หุ้นส่วนพันธมิตรของเราจะช่วยให้คุณขายผลิตภัณฑ์ของคุณได้สำเร็จมากยิ่งขึ้น และเราให้บริการคุณในฐานะผู้ริเริ่มด้วยโซลูชันและโครงสร้างซอฟต์แวร์ของเราเพื่อให้คุณประสบความสำเร็จมากยิ่งขึ้นด้วยคุณภาพและความเร็ว',
    onboarding_first_digits_title: 'นักลงทุนในกว่า 50 ประเทศ',
    onboarding_second_digits_title: 'บริษัทในเครือทั่วโลก',
    onboarding_third_digits_title: 'Gymnetwork เข้าถึงมูลค่าตลาดสูงสุดหลังจาก 8 สัปดาห์',
    onboarding_fourth_digits_title: 'นักพัฒนาที่ทำงานใน 3 ภูมิภาคที่แตกต่างกัน',
    onboarding_metaverse_title_main: 'การเริ่มต้นเมตาเวิร์ส',
    onboarding_metaverse_first_card_title: 'ธนาคาร การแลกเปลี่ยน การประกัน หรือ เทคโนโลยีทางการเงิน',
    onboarding_metaverse_first_card_subtitle: 'อย่าลังเลที่จะติดต่อเราและบอกเราว่าทำไมคุณถึงเหมาะกับเมตาเวิร์สของเรา',
    onboarding_metaverse_first_card_subtitle_second: 'ในเมตาเวิร์สทางการเงินของเรา เราต้องการรวมธนาคาร ตลาดหลักทรัพย์ และบริษัทประกันภัยที่ดีที่สุดเข้ากับผลิตภัณฑ์ของพวกเขา',
    onboarding_metaverse_first_card_subtitle_third: ' Alpaca.finance ',
    onboarding_metaverse_first_card_subtitle_fourth: 'และ Lbank ได้คว้าโอกาสนี้โดยตรงและตัดสินใจที่จะร่วมมือเพื่อวางตำแหน่งผลิตภัณฑ์ชั้นหนึ่งของพวกเขาให้ดีขึ้น',
    onboarding_metaverse_second_card_title: 'เทคโนโลยีใหม่',
    onboarding_metaverse_second_card_subtitle: 'คุณมีเทคโนโลยีใหม่ที่เกิดขึ้นจากโลกของบล็อกเชนหรือไม่ เราตื่นเต้นมาก!',
    onboarding_metaverse_second_card_subtitle_second: 'ทุกเดือนมีการเริ่มต้นใหม่และเกิดขึ้นใหม่ในโลกบล็อคเชนและฟินเทค เราต้องการเสนอแพลตฟอร์มให้พวกเขาสังเกตเห็นได้ดีขึ้น และในขณะเดียวกันก็สามารถถอยกลับไปหานักลงทุนและพันธมิตรในเครือของเราได้ เราสนับสนุนความก้าวหน้าของการทำป่าไม้อย่างต่อเนื่องและนวัตกรรมที่จำเป็นอย่างยิ่งในด้านเมตาเวิร์ส',
    onboarding_metaverse_third_card_title: 'โทเคนหรือเหรียญ',
    onboarding_metaverse_third_card_subtitle: 'คุณคิดว่าโครงการของคุณมีความยั่งยืนในตลาดสินทรัพย์ดิจิทัลและแนวคิดของคุณไม่เหมือนใครหรือไม่ มาคุยกันเถอะ',
    onboarding_metaverse_fourth_card_title: 'ผลิตภัณฑ์ทางการเงินและโครงการ',
    onboarding_metaverse_fourth_card_subtitle: 'โซลูชันของคุณมีข้อได้เปรียบที่ชัดเจนต่อชุมชนของเราหรือไม่? โปรดอย่าลังเลที่จะสมัคร',
    onboarding_metaverse_fifth_card_title: 'การแลกเปลี่ยน LBANK',
    onboarding_metaverse_fifth_card_subtitle: 'การแลกเปลี่ยนสกุลเงินคริปโตยอดนิยมที่อันดับ 15 ในรายการแลกเปลี่ยนคริปโตเคอเรนซี่ยอดนิยมของ CoinMarketCap มีการเข้าชมมากกว่า 1.8 ล้านครั้งต่อเดือน และปริมาณการซื้อขายรายวันเกือบ 1.5 พันล้านดอลลาร์',
    onboarding_metaverse_title_main_second: 'การเริ่มต้นเมตาเวิร์ส',
    onboarding_metaverse_first_card_description: 'เราทำออนบอร์ด 3 มิติ ในขั้นตอนแรก เราตั้งชื่อคุณลักษณะของความร่วมมือและผลประโยชน์สำหรับพันธมิตรพันธมิตรของเรา ',
    onboarding_metaverse_second_card_description: 'ในขั้นตอนที่สอง คุณต้องเลือกผืนที่ดินอย่างน้อยหนึ่งผืนบนที่ดินเสมือนจริงของเรา หรือแม้แต่สถานที่สาธารณะ คุณสามารถซื้อหรือเช่าได้',
    onboarding_metaverse_third_card_description: 'ในขั้นตอนที่ 3 คุณมอบหมายให้บริษัทของเราออกแบบสิ่งปลูกสร้างเสมือนของคุณและได้รับการเป็นตัวแทนชั้นหนึ่งในเมตาเวิร์สของเรา',
    onboarding_metaverse_fourth_card_description: 'พวกเขาเฉลิมฉลองการเปิดตัวร่วมกับเรา พันธมิตรของเรา และลูกค้าใหม่ที่มีศักยภาพ',
    onboarding_metaverse_first_info_title: 'มอบการนำเสนอ - เสนอมูลค่าเพิ่ม',
    onboarding_metaverse_first_info_subtitle: 'เราต้องการเสนอมูลค่าเพิ่มให้กับชุมชนของเราอย่างสม่ำเสมอโดยปราศจากผลิตภัณฑ์ใด ๆ มันเกี่ยวกับเนื้อหาล้วนๆ ',
    onboarding_metaverse_second_info_title: 'เทรนด์, เทคโนโลยีการเงิน, นวัตกรรม, แรงจูงใจ',
    onboarding_metaverse_second_info_subtitle: 'คุณมีหัวข้อที่คุณเชี่ยวชาญและต้องการแบ่งปันกับเราหรือไม่? อย่าลังเลที่จะติดต่อเรา!',

    gordons_corner_video_title: 'แว็บแรกของเมตาเวิร์สของเรา\'',
    gordons_corner_first_info_title: 'คุณต้องการพบกอร์ดอนหรือไม่?',
    gordons_corner_first_info_subtitle: 'แน่นอนว่าเราอ้างว่าสร้างเมตาเวิร์สที่สมจริงและล้ำสมัยในขณะเดียวกัน แต่ความสนุกต้องไม่พลาด...',
    gordons_corner_first_info_subtitle_two: 'เกมแรกในเมตาเวิร์สซึ่งจะทำหน้าที่เป็นปฐมนิเทศด้วย "Find Gordon" จะแสดงให้คุณเห็น Gymstreet พร้อมกันและให้รางวัลเมื่อคุณพบเขา!',
    gordons_corner_digit_title_main: 'แผนงานของเรา',
    gordons_corner_digit_first_item_title: 'ประสบการณ์และการขายที่ดินเบื้องต้น',
    gordons_corner_digit_second_item_title: 'ประสบการณ์และการเปิดใช้งานโหมดเช่า NFT',
    gordons_corner_digit_third_item_title: 'การเปิดใช้งานประสบการณ์ ',
    gordons_corner_sneak_peak_main_title: 'แว็บแรกของอาคารของเรา',
    gordons_corner_sneak_peak_first_card_title: 'ห้องนิรภัย Gymnetwork',
    gordons_corner_sneak_peak_first_card_description: 'พันธมิตรอย่างเป็นทางการของเราที่นี่คือ Alpaca Finance และคุณสามารถใช้ห้องนิรภัยเหล่านี้เป็นผลิตภัณฑ์ที่ใช้งานง่าย และยังได้สัมผัสกับผลิตภัณฑ์ในรูปแบบเมตาเวิร์ส',
    gordons_corner_sneak_peak_second_card_title: 'CashFT',
    gordons_corner_sneak_peak_second_card_description: 'บัตรเดบิต NFT ไลฟ์สไตลืที่มีโซลูชั่นการชำระเงินข้ามพรมแดนมากมายภายในและรางวัลที่ไม่เหมือนใคร มีวงเงินเช่นกัน เช็คดูเลย!',
    gordons_corner_sneak_peak_third_card_title: 'ซัคเกอร์แลนด์',
    gordons_corner_sneak_peak_third_card_description: 'ตลาด NFT ที่เน้นด้านอสังหาริมทรัพย์ ที่ดิน และโครงสร้างพื้นฐานให้ข้อมูลที่ยอดเยี่ยม การวิเคราะห์ และอื่น ๆ อีกมากมายสำหรับคุณ ไปเที่ยวข้างในกันเถอะ',
    gordons_corner_sneak_peak_fourth_card_title: 'ศูนย์พันธมิตร',
    gordons_corner_sneak_peak_fourth_card_description: 'การฝึกอบรมและการประชุมเชิงปฏิบัติการสำหรับการตลาดและอื่น ๆ วัสดุ โปรโมชั่น พันธมิตรอื่น ๆ และเนื้อหามากมาย',
    gordons_corner_sneak_peak_fifth_card_title: 'การแลกเปลี่ยน LBANK',
    gordons_corner_sneak_peak_fifth_card_description: 'การแลกเปลี่ยนสกุลเงิน crypto ยอดนิยมที่อันดับ 15 ในรายการ Cryptocurrency Spot Exchanges ยอดนิยมของ CoinMarketCap มีการเข้าชมมากกว่า 1.8 ล้านครั้งต่อเดือน และปริมาณการซื้อขายรายวันเกือบ 1.5 พันล้านดอลลาร์',
    gordons_corner_second_info_title: 'คุณเป็นธนาคารหรือการแลกเปลี่ยน',
    gordons_corner_second_info_subtitle: 'คุณมีความคิดเห็นอย่างไรกับการมีอาคารสาขาที่ออกแบบมาอย่างเจ๋งใน Gymstreet?',
    gordons_corner_second_info_subtitle_two: 'แท้จริงมันเป็นของคุณได้!',
    gordons_corner_first_news_title: 'โรงงานสำหรับ NFTs',
    gordons_corner_first_news_description: 'เร็วๆ นี้เราจะเปิด Creator Club สำหรับนักออกแบบที่มีทักษะซึ่งต้องการช่วยเราทำให้ Metaverse ของเราสวยงาม ในเวลาเดียวกัน คุณสามารถทำการตลาด NFT ของคุณบน Zuckerland.io',
    gordons_corner_second_news_title: 'สุนทรียศาสตร์ NFTs',
    gordons_corner_second_news_description: 'เมตาเวิร์สต้องการความสวยงาม ต้นไม้ ป้ายโฆษณา ป้าย สวนสาธารณะ ร้านขายฮอทดอก และอีกมากมาย ในท้องถนนของ Gymstreet คุณจะพบกับสิ่งสวยงามมากมาย',
    gordons_corner_third_news_title: 'อวตารของคุณ',
    gordons_corner_third_news_description: 'ต้องการอวาตาร์ของคุณเองหรือ ในไม่ช้ามันจะเป็นจริง หลังจากการเปิดตัวของการเปิด 3D เริ่มต้นขึ้นในยุคนี้!',
    gordons_corner_fourth_news_title: 'อวตารสาธารณะ',
    gordons_corner_fourth_news_description: 'ผู้คนอาศัยและทำงานใน Gymstreet ขึ้นอยู่กับบริการและพื้นที่ อยากกินฮอทดอกไหม',
    gordons_corner_fifth_news_title: 'ผู้จัดการซ่อมแซม',
    gordons_corner_fifth_news_description: 'NFTs นำอวตารมาด้วย ผู้จัดการซ่อมแซมจะซ่อมแซมเครื่องขุด NFT เพื่อประสิทธิภาพที่เพิ่มขึ้น \'',

    pr_media_title_main: 'ประชาสัมพันธ์และสื่อ',

    coming_soon: 'เร็ว ๆ นี้...',
    see_here: 'ดูที่นี่',
    copyright: 'ลิขสิทธิ์',
    button_submit: 'ส่ง',
    button_more_people: 'คนมากขึ้น',
    source: 'ที่มา:',
    email_address: 'ที่อยู่อีเมล',

    position_chief_executive_officer: 'ประธานเจ้าหน้าที่บริหาร (CEO)',
    position_chief_technology_officer: 'ประธานเจ้าหน้าที่ฝ่ายเทคโนโลยี (CTO)',
    position_chief_information_officer: 'หัวหน้าเจ้าหน้าที่สารสนเทศ (CIO)',
    position_chief_marketing_officer: 'ประธานเจ้าหน้าที่ฝ่ายการตลาด (CMO)\'',
    position_chief_operations_officer: 'หัวหน้าเจ้าหน้าที่ปฏิบัติการ (COO)',
    position_principal_engineer: 'วิศวกรหลัก',
    position_moderator: 'ผู้ดำเนินรายการ',
    position_concept_artist: 'ศิลปินแนวความคิด',
    position_editor: 'บรรณาธิการ',
    position_lead_designer: 'หัวหน้านักออกแบบ',
    position_three_d_team_lead: 'หัวหน้าทีม 3 มิติ',
    position_three_d_artist: 'ศิลปิน 3 มิติ',
    position_two_d_compositor: 'ช่างเรียงภาพ 2 มิติ',

    // New
    position_senior_marketing_strategist: 'นักยุทธศาสตร์การตลาดอาวุโส',
    position_senior_project_manager: 'ผู้จัดการโครงการ',
    position_senior_marketing_pm: 'ผู้จัดการโครงการการตลาด',
    position_senior_content_writer: 'นักเขียนเนื้อหา',
    position_senior_lead_ux_ui_designer: 'หัวหน้า UX/นักออกแบบ UI ',
    position_senior_ux_ui_designer: 'นักออกแบบ UX/UI',
    position_senior_motion_graphic_designer: 'นักออกแบบภาพเคลื่อนไหวและกราฟิก',
    position_senior_graphic_designer: 'ออกแบบกราฟิก',

    frank_roehrig_description_first: 'กรรมการผู้จัดการของ Meta Networks FZCO บริษัทเมตาเวิร์สของเราในดูไบ เขามีพื้นฐานในการพัฒนาโครงการในภาคอสังหาริมทรัพย์เพื่อการพาณิชย์',
    frank_roehrig_description_second: 'ตั้งแต่ปี 2558 เขามุ่งมั่นทุ่มเทให้กับการเงินแบบกระจายอำนาจและโลกดิจิทัล',
    frank_roehrig_description_third: 'ภารกิจของเขาคือการทำให้การเดินทางของคุณเข้าสู่โลกแห่งการเงินแบบกระจายอำนาจให้ง่ายที่สุด',

    raphael_tobelyan_description_first: 'ราฟาเอล โทเบลยัน มีประสบการณ์มากกว่าสิบปีในฐานะสถาปนิกซอฟต์แวร์อาวุโส เขาสร้างสถาปัตยกรรมระบบให้กับบริษัทที่มีชื่อเสียงในสหรัฐอเมริกาและออสเตรเลีย',
    raphael_tobelyan_description_second: 'ในขณะที่ทำงานในโครงการขนาดใหญ่มากกว่า 30 โครงการ ราฟาเอล ได้รับประสบการณ์มากมายในสกุลเงินดิจิทัลและบล็อกเชน เขาสำเร็จการศึกษาระดับปริญญาโทด้านเทคโนโลยีสารสนเทศ',
    raphael_tobelyan_description_third: 'ราฟาเอล อายุ 30 ปีและแต่งงานอย่างมีความสุข',

    david_bras_description_first: 'เดวิด บราส รับผิดชอบดูแลการวางแผน การพัฒนา และการดำเนินการตามแผนการตลาดและการโฆษณาของ Gym Network',
    david_bras_description_second: 'ด้วยประสบการณ์โฆษณาคริปโตมากกว่า 7 ปี เขาจึงเป็นหนึ่งในนักการตลาดชั้นนำในอุตสาหกรรมนี้ โดยสามารถระดมทุนได้มากกว่า 400 ล้านดอลลาร์ภายใน 5 ปีที่ผ่านมา',
    david_bras_description_third: 'ด้วยกลยุทธ์ทางการตลาดที่เป็นเอกลักษณ์ของเขา ความรู้เกี่ยวกับคริปโตที่หลากหลาย และความร่วมมือกับเอเจนซี่การตลาดคริปโตชั้นนำของโลก ไม่มีอุปสรรคสำหรับ Gym Network ในการเป็นหนึ่งในผู้เล่นที่ใหญ่ที่สุดในพื้นที่ DeFi ',

    alberto_mera_description_first: 'อัลแบร์โต เมร่า มีประสบการณ์หลายปีในด้านการเงินแบบดั้งเดิมและวาณิชธนกิจในธนาคารระหว่างประเทศ เช่น Morgan Stanley และ UBS ความสนใจของเขาอยู่ที่การนำโครงสร้างทางการเงินมาสู่โลกแห่งการเงินแบบกระจายอำนาจและเมตาเวิร์ส',

    timur_ozturk_description_first: 'ติมูร์มีความสามารถโดยพรสวรรค์ในการแบ่งย่อยสิ่งที่ดูเหมือนซับซ้อนในตอนแรกออกเป็นชิ้น ๆ ที่ครอบคลุม เพื่อช่วยให้ผู้ที่ยังใหม่กับ DeFi เข้าใจได้ง่ายว่าสิ่งต่าง ๆ ทำงานอย่างไรที่นี่ ในฐานะผู้ดำเนินรายการ เขาชอบที่จะนำเสนอผลิตภัณฑ์ของ Gym Network แก่ผู้ชมของเราและอธิบายผลิตภัณฑ์ดังกล่าวในลักษณะที่ให้ความบันเทิงและให้ความรู้',
    timur_ozturk_chief_communication_officer: 'หัวหน้าเจ้าหน้าที่สื่อสาร',

    mariam_sargsyan_description_first: 'เริ่มต้นจากการเป็นนักการตลาดดิจิทัล มาเรียม ซาร์กเซียน ได้ก้าวไปสู่ความร่วมมือระดับแนวหน้ากับบริษัทโฆษณาและซอฟต์แวร์รายใหญ่',
    mariam_sargsyan_description_second: 'ในช่วงต้นปี 2565 มาเรียมร่วมงานกับ Gym Street ในฐานะนักยุทธศาสตร์การตลาดอาวุโส ตอนนี้เธอคิดแผนการตลาดเชิงกลยุทธ์ที่ช่วยให้เราสื่อสารกับผู้คนทั่วโลก',
    mariam_sargsyan_description_third: 'เป้าหมายของเธอคือการมีส่วนร่วมในการทำให้ DeFi เป็นสถานที่ที่ความฝันกลายเป็นจริง',

    anzhelika_volska_description_first: 'อันเซลิกาเป็นคนที่รู้ว่าการจัดการโครงการไม่ได้จำกัดอยู่เพียงแค่การวางแผนโครงการเท่านั้น',
    anzhelika_volska_description_second: 'เธอไม่ใช่ผู้จัดการที่คอยเฝ้าดูอยู่ข้างสนาม แต่เป็นคนที่ยอมทำทุกอย่างเพื่อความสำเร็จ นักวางแผนที่รัดกุม พายุที่โหมกระหน่ำระหว่างการพัฒนา ผู้ไกล่เกลี่ยความขัดแย้ง ผู้ให้การสนับสนุนส่วนบุคคล และผู้นำ นั่นคือทั้งหมดของเธอ',

    jacob_oblasser_description_first: 'เจคอบสรุปและตรวจสอบข้อกำหนดระหว่างทีมยิม ด้วยประสบการณ์ด้านการตลาดออนไลน์และการสร้างแบรนด์ เขาจึงสร้างแนวคิดที่อยู่เบื้องหลังเว็บไซต์',

    diana_kasprovych_description_first: 'หัวหน้าโครงการนักออกแบบ UX/UI ของ Gym Network เธอมีพื้นฐานในการพัฒนาโครงการในด้านประสบการณ์ผู้ใช้และส่วนต่อประสานกับผู้ใช้ โครงการด้านการเงิน แพลตฟอร์มการเข้ารหัสลับ การสร้างแบรนด์',
    diana_kasprovych_description_second: 'ภารกิจของเธอคือการคิดถึง Logic และ UX ของ Project หัวหน้าผู้ออกแบบ UX/UI ของ Gym Network Platform และเว็บไซต์ cashFT และ Marketplace สร้างชื่อแบรนด์สำหรับ CashFT กับทีมของเธอ',

    anna_bodnariuk_description_first: 'หัวหน้าผู้ออกแบบ UX/UI ของ Gym Street และผู้ออกแบบ UX/UI ของ Gym Network เธอมีพื้นฐานด้านการสร้างแบรนด์และโครงการอีคอมเมิร์ซ',
    anna_bodnariuk_description_second: 'เมื่อไม่นานมานี้ เธอทุ่มเทอย่างมากในการออกแบบสำหรับ Web 3.0',
    anna_bodnariuk_description_third: 'ภารกิจของเธอคือการโต้ตอบกับทั้งแพลตฟอร์มดั้งเดิมและน่าตื่นเต้น',

    anna_kulyba_description_first: 'นักออกแบบ UX/UI โครงการ Gymnet เธอมีประสบการณ์ในการให้บริการโซลูชั่นการออกแบบสำหรับภาคธุรกิจต่าง ๆ เช่น บล็อกเชน การเงิน อีคอมเมิร์ซ การสร้างแบรนด์',
    anna_kulyba_description_second: 'ภารกิจของเธอคือดำเนินการวิจัย UX คิดผ่านตรรกะของผลิตภัณฑ์ และสร้างการออกแบบภาพ',

    matilda_demirjian_description_first: 'มาทิลด้ามีปริญญาตรีด้านการออกแบบกราฟิกและประสบการณ์มากกว่า 10 ปีในการกำกับโครงการออกแบบจาก A ถึง Z ในสาขาต่าง ๆ เช่น เอเจนซี่ออกแบบและบริษัทโฆษณา',
    matilda_demirjian_description_second: 'ปัจจุบันเธอทำงานกับเรา เธอออกแบบเมตาดาต้าและภาพเข้ารหัสของเราสำหรับ GYM Network และ GYM Street',
    matilda_demirjian_description_third: 'มาทิลด้าตระหนักดีว่าการออกแบบเป็นเครื่องมือที่ทรงพลังที่ช่วยให้เราเข้าใจโลกดิจิทัลที่เราอาศัยอยู่',

    lewis_mhlanga_description_first: 'ลูอิส มลังกา เป็นนักลงทุนคริปโตและอสังหาริมทรัพย์ ผู้ฝึกสอน นักการตลาดเครือข่ายมืออาชีพด้วยประสบการณ์มากกว่า 6 ปีในอุตสาหกรรมนี้',
    lewis_mhlanga_description_second: 'เขาชอบรูปแบบธุรกิจขายตรงที่มีการบูรณาการโอกาสที่เปลี่ยนแปลงชีวิตให้กับผู้ที่เลือกใช้ประโยชน์จากโปรแกรมที่คุ้มค่า',

    overview: 'ภาพรวม',
    advisors_title_main: 'ที่ปรึกษา',

    advisors_travladd_title: 'Travladd',
    advisors_travladd_description_first: 'Travladd Crypto - หนึ่งในผู้มีอิทธิพลทางโทรเลขที่ใหญ่ที่สุดในพื้นที่ crypto ที่มีสมาชิกมากกว่า 70,000 คน Travis เป็น CEO และเจ้าของ AMA Central',
    advisors_travladd_description_second: 'AMA Central สร้างขึ้นโดยหนึ่งในทีมการตลาดที่มีการแข่งขันสูงที่สุดใน DeFi ด้วยผู้ติดตามมากกว่า 400,000 คนบนโซเชียล พวกเขาจึงเป็นหนึ่งในผู้มีอิทธิพลของ DeFi Telegram และ Twitter ที่ใหญ่ที่สุดในตลาด และจะอัปเดตชุมชนของพวกเขาอย่างสม่ำเสมอเกี่ยวกับ Gym Network ในฐานะที่ปรึกษาอย่างเป็นทางการของเรา',

    advisors_claudio_catrini_title: 'Claudio Catrini',
    advisors_claudio_catrini_description_first: 'ที่ปรึกษาและโค้ช Claudio Catrini สนับสนุนผู้ประกอบการและบริษัทต่างประเทศในการเปลี่ยนแปลงตนเองทางดิจิทัลและการกระจายอำนาจ',
    advisors_claudio_catrini_description_second: 'ด้วยประสบการณ์กว่า 20 ปีและการฝึกฝนในด้านการขายและการตลาด Claudio รู้ดีว่าควรเฉลิมฉลองความสำเร็จที่แท้จริงในโลกดิจิทัลอย่างไร และควรหลีกเลี่ยงข้อผิดพลาดใดเพื่อไม่ให้เสียเวลา เงิน และทรัพยากรโดยไม่จำเป็น',

    influencer_title_main: 'อินฟลูเอนเซอร์',

    gymstreet_news_title_main: 'Gymstreet ข่าว',

    copied: 'คัดลอก'
  },
  // Russian
  'ru': {
    header_button_first: 'Исследуйте Метавселенную',
    header_button_second: 'Вход',
    header_button_third: 'Исследовать',
    header_menu_item_first: 'Практика финансов',
    header_menu_item_second: 'Путеводитель',
    header_menu_item_third: 'Команда',
    header_menu_item_fourth: 'Внедрение',
    header_menu_item_fifth: 'Уголок Гордона',
    header_menu_item_sixth: 'PR и новости',

    footer__form__title: 'Подписаться',
    footer__form__subtitle: 'Следите за нашими последними новостями.',
    footer_address_first: 'META NETWORKS FZCO',
    footer_address_second: 'Dubai Silicon Oasis',
    footer_address_third: 'DDP, Building A4',
    footer_address_fourth: 'Dubai',
    footer_address_fifth: 'United Arab Emirates',
    footer_address_unit_number: 'Unit Number 213',
    footer_link_first: 'Связаться с нами',
    footer_link_second: 'Часто задаваемые вопросы',
    footer_link_third: 'Политика конфиденциальности',
    footer_link_fourth: 'Правила использования',

    overview_intro_title: 'Самый простой способ получить практический финансовый опыт',
    overview_intro_subtitle: 'Как бы выглядела Уолл-Стрит сегодня и через 100 лет?',
    overview_intro_subtitle_second: 'DeFi, CeFi, захватывающие возможности и продукты.',
    overview_intro_button: 'Исследуйте Метавселенную',
    overview_intro_button_second: 'Вход',
    overview_info_title: 'Что такое практика финансов',
    overview_info_subtitle: 'Существуют обычные финансы и децентрализованные финансы. Чтобы понять разницу, нужно ее испытать, а не просто прочитать или изучить. Практика - это залог успеха.',
    overview_info_title_second: 'Мы направляем людей к этому опыту: Управляемое способствование росту',
    overview_info_subtitle_second: 'Консультанты, советники и аффилированные партнеры помогают новым пользователям ориентироваться в метавселенной. Взамен они получают вознаграждение, а новым пользователям легче ориентироваться.',
    overview_info_title_third: 'Если продукт качественный, клиент мотивирует себя использовать его.',
    overview_info_subtitle_third: 'При внедрении новых продуктов мы уделяем большое внимание их качеству и простоте использования. Если продукт прост в использовании, его порекомендуют. ',
    overview_news_title_main: 'Новости Gymstreet',
    overview_info_second_title: 'Как я могу испытать Gymstreet в тестовом режиме',
    overview_info_second_subtitle: 'Мы создали тестовую среду для вас, чтобы вы могли легко начать. Вы можете купить виртуальную землю в текстовом формате, запустить майнеры NFT в тестовой версии и многое другое. ',
    overview_info_second_title_second: 'Познакомьтесь с нашей командой',
    overview_info_second_subtitle_second: 'Познакомьтесь с нашей командой. Мы хотели бы, чтобы вы стали её частью. Примите вызов?',
    overview_partners_title_main: 'Наши партнеры',
    overview_telegram_title_main: 'Как мы можем вам помочь?',
    overview_telegram_link: 'Свяжитесь с нами!',

    experienced_finance_video_title: 'История финансов',
    experienced_finance_info_title: 'Знали ли вы, что крупные банки рассматривают Метавселенную как индустрию стоимостью 8 триллионов долларов? ',
    experienced_finance_review_first_description: '“Если мы хотим создать экономику созидания — если мы хотим, чтобы у наших детей были рабочие места, о которых мы даже не могли мечтать — что мы думаем об этих платформах как граждане, компании и регулирующие органы?”',
    experienced_finance_review_first_position: 'вице-президент Unreal Engine Ecosystem от Epic Games',
    experienced_finance_review_second_description: '“Мы стараемся не определять метавселенную слишком строго, чтобы не ограничивать воображение создателей.”',
    experienced_finance_review_second_position: 'главный исполнительный директор (CEO) Square Enix',
    experienced_finance_review_third_description: 'Я думаю о метавселенной как о продолжении пути развития технологий до появления COVID. У нас есть Инстаграм. У нас есть электронная почта, у нас есть обмен сообщениями, есть наши друзья в реальном времени, реальная деятельность, в которой мы участвуем. Иногда между ними есть пересечение.',
    experienced_finance_review_third_position: 'главный исполнительный директор (CEO) Niantic',
    experienced_finance_review_fourth_description: '“Каждый день сотни миллионов людей подключаются к этим средам. Есть много самых легендарных компаний в мире, которые создают рынок сбыта, а наша торговля исчисляется десятками, а скоро достигнет сотен миллиардов долларов.”',
    experienced_finance_review_fourth_position: 'управляющий партнер EpillionCo и информационный партнер McKinsey',
    experienced_finance_review_fifth_description: '“Поведение потребителей в значительной степени сместилось в сторону использования цифровых персонажей, но многие бренды еще не предложили решение.”',
    experienced_finance_review_fifth_position: 'соучредитель и главный исполнительный директор (CEO) AnamXR ',
    experienced_finance_jump_into_main_title: 'Погрузитесь в новый виртуальный мир и испытайте финансы, как никогда ранее',
    experienced_finance_time_main_title: 'Поймите время',
    sandglass_periods_past: 'Прошлое',
    sandglass_periods_now: 'Настоящее время',
    sandglass_periods_future: 'Будущее',
    sandglass_info_description_first: 'Нажмите на категорию, чтобы показать дополнительные уровни.',
    sandglass_info_title_second: 'Будущее',
    sandglass_info_description_second: 'Gymstreet с консультантами в Метавселенной',
    sandglass_info_title_third: 'Прошлое',
    sandglass_info_description_third: 'Банковское дело 30 лет назад – счетчики и консультации на местах',
    sandglass_info_title_fourth: 'Настоящее время',
    sandglass_info_first_description_fourth: 'Платформы DeFi и финансы Метавселенной',
    sandglass_info_second_description_fourth: 'Банковское дело как услуга - Онлайн и необанкинг ',
    sandglass_info_third_description_fourth: 'Банковское дело и страхование через консультанта на дому',
    experienced_finance_infomasses_main_title: 'Мы несем DeFi и CeFi в массы',
    experienced_finance_infomasses_card_first_title: 'Консультируем и советуем',
    experienced_finance_infomasses_card_first_description: 'Наш консультант и партнеры покажут вам различные продукты и проконсультируют вас, чтобы вы могли в итоге освоить их самостоятельно.',
    experienced_finance_infomasses_card_second_title: 'Обучение и образование',
    experienced_finance_infomasses_card_second_description: 'В нашем университете мы предлагаем курсы и семинары в прямом эфире по всем темам нашей метавселенной. У нас комфортно себя чувствуют как новички, так и профессионалы.',
    experienced_finance_infomasses_card_third_title: 'Партнеры приветствуются',
    experienced_finance_infomasses_card_third_description: 'Мы самая быстрорастущая и крупнейшая платформа метавселенной для DeFi, CeFi и партнерских отношений. Вы хотели бы порекомендовать наши продукты?',
    experienced_finance_infomasses_card_fourth_title: 'Стремительно двигайтесь',
    experienced_finance_infomasses_card_fourth_description: 'На таком быстрорастущем рынке скорость является одним из ключевых факторов. Чтобы быть быстрым, вам нужна сильная и большая команда и постоянное стремление к инновациям.',
    experienced_finance_digits_main_title: 'Метавселенная изменит то, как люди понимают и используют деньги',
    experienced_finance_digits_item_first: 'было вложено инвестиций в метавселенную в 2022 году',
    experienced_finance_digits_item_second: 'потребителей, активных в метавселенной, совершили покупку',
    experienced_finance_digits_item_third: 'корпоративных доходов, как ожидается, будет поступать из метавселенной в следующие 5 лет, по мнению 25% руководителей высшего звена',

    guide_video_title: 'Три способа направления',
    guide_users_main_title: 'Мы направляем наших пользователей на их пути в метавселенную и внутри нее',
    guide_users_main_subtitle: 'Представьте, что вам нужно научиться играть на пианино без учителя. Это то, что происходит с большинством людей на пути в метавселенную. Смелые люди отважатся войти. Широкие массы никогда не пройдут этот путь в одиночку.',
    guide_first_card_title: 'Партнер',
    guide_first_card_description: 'Наши партнеры обслуживают первый и второй контакт. Информация и первый опыт, которые получает новый пользователь.',
    guide_second_card_title: 'Консультант',
    guide_second_card_description: 'Наши консультанты и модераторы показывают пользователям, как ориентироваться в метавселенной, а также как пользоваться продуктами и работать с ними.',
    guide_third_card_title: 'Советник',
    guide_third_card_description: 'Внешние советники помогают нам интегрировать их продукты и, при необходимости, сделать их достаточно простыми, чтобы их можно было легко интегрировать.',
    guide_team_main_title: 'Познакомьтесь с нашей командой',

    team_title_main_first: 'Познакомьтесь с нашей командой',
    team_title_main: 'Отдел разработки и операционный отдел',
    team_title_main_second: 'Креативный отдел',

    onboarding_video_title: 'Явные преимущества,',
    onboarding_video_title_second: 'простое управление и хороший продукт',
    onboarding_video_subtitle: 'Мы привлекаем глобальных игроков, а также интересные стартапы и предоставляем вам первоклассное здание филиала Метавселенной.',
    onboarding_text_info_item_first: 'Представьте Уолл-Стрит, какой она может быть сегодня и в будущем. DeFi, CeFi, крупные игроки мира и стартапы. Все в одном огромном финансовом центре — Gymstreet. Мы предлагаем нашим клиентам старые знакомые, но недавно разработанные и обновленные доступы и возможности. Параллельно мы также предлагаем очень интересные стартапы и продукты с захватывающими преимуществами из мира финансов.',
    onboarding_text_info_item_second: 'Мы хотим быть многосторонними и представлять в нашей метавселенной все подотрасли, которые существуют или еще не существуют.',
    onboarding_text_info_item_second_two: 'Для нас важно одинаково создавать преимущества для наших клиентов и партнеров и в то же время предлагать им необходимое обучение.',
    onboarding_first_advantage_title: 'Gymnetwork Vaults and DeFi',
    onboarding_first_advantage_description: 'Официальный партнер Alpaca Finance.',
    onboarding_second_advantage_title: 'CashFT',
    onboarding_second_advantage_description: 'Дебетовые карты NFT Lifestyle.',
    onboarding_third_advantage_title: 'Торговые площадки',
    onboarding_third_advantage_description: 'Торговля, FX, Товары.',
    onboarding_fourth_advantage_title: 'Университет',
    onboarding_fourth_advantage_description: 'Отличное финансовое образование.',
    onboarding_fifth_advantage_title: 'Партнерский Центр',
    onboarding_fifth_advantage_description: 'Качественное образование по Маркетингу.',
    onboarding_sixth_advantage_title: 'Zuckerland',
    onboarding_sixth_advantage_description: 'Рынок NFT ориентирован на недвижимость, землю и инфраструктуру.',
    onboarding_affiliate_title: 'Решения партнерского уровня',
    onboarding_affiliate_subtitle: 'Наши аффилированные партнеры помогут вам еще успешнее продавать свой продукт. И мы предоставляем вам в качестве инициатора наши программные решения и структуру, чтобы вы добились еще большего успеха быстро и качественно.',
    onboarding_first_digits_title: 'инвесторы в более чем 50 странах',
    onboarding_second_digits_title: 'партнеров по всему миру',
    onboarding_third_digits_title: 'рыночная капитализация достигнута Gymnetwork через 8 недель',
    onboarding_fourth_digits_title: 'разработчиков, работающих в 3 разных регионах',
    onboarding_metaverse_title_main: 'Внедрение Метавселенной',
    onboarding_metaverse_first_card_title: 'Банк, биржа, страховая или финтех',
    onboarding_metaverse_first_card_subtitle: 'Свяжитесь с нами и расскажите, почему вы идеально подходите для нашей Метавселенной.',
    onboarding_metaverse_first_card_subtitle_second: 'В нашу финансовую метавселенную мы хотим интегрировать лучшие банки, фондовые биржи и страховые компании с их продуктами',
    onboarding_metaverse_first_card_subtitle_third: ' Alpaca.finance ',
    onboarding_metaverse_first_card_subtitle_fourth: 'и Lbank напрямую воспользовались этой возможностью и решили сотрудничать, чтобы лучше позиционировать свои первоклассные продукты.',
    onboarding_metaverse_second_card_title: 'Новая технология',
    onboarding_metaverse_second_card_subtitle: 'У вас есть новая, развивающаяся технология из мира блокчейна? Нам очень интересно!',
    onboarding_metaverse_second_card_subtitle_second: 'Каждый месяц в мире блокчейна и финансовых технологий появляются новые и новые стартапы. Мы хотим предложить им платформу, чтобы их лучше заметили, и в то же время иметь возможность прибегать к услугам наших инвесторов и аффилированных партнеров. Мы поддерживаем постоянный прогресс лесного хозяйства и абсолютно необходимые инновации в области метавселенной.',
    onboarding_metaverse_third_card_title: 'Токен или монета',
    onboarding_metaverse_third_card_subtitle: 'Считаете ли вы, что ваш проект устойчив на рынке цифровых активов, а ваша концепция уникальна? Давайте обсудим.',
    onboarding_metaverse_fourth_card_title: 'Финансовый продукт и проект',
    onboarding_metaverse_fourth_card_subtitle: 'Предлагает ли ваше решение явное преимущество нашему сообществу? Пожалуйста, не стесняйтесь подать заявку.',
    onboarding_metaverse_fifth_card_title: 'LBANK exchange',
    onboarding_metaverse_fifth_card_subtitle: 'Популярная биржа криптовалют, которая занимает 15-е место в списке лучших спотовых бирж криптовалют CoinMarketCap, имеет более 1,8 миллиона посещений в месяц и ежедневный объем торгов, близкий к 1,5 миллиардам долларов.',
    onboarding_metaverse_title_main_second: 'Внедрение Метавселенной',
    onboarding_metaverse_first_card_description: 'Мы проводим 3D внедрение. На первом этапе мы называем особенности нашего сотрудничества и преимущества для наших партнеров. ',
    onboarding_metaverse_second_card_description: 'На втором этапе вы выбираете один или несколько участков на нашей виртуальной земле или даже общественном месте. Их можно купить или арендовать.',
    onboarding_metaverse_third_card_description: 'На третьем этапе вы поручаете нашей компании проектирование вашего виртуального здания и получаете первоклассное представление в нашей метавселенной.',
    onboarding_metaverse_fourth_card_description: 'Они празднуют открытие вместе с нами, нашими партнерами и потенциальными новыми клиентами.',
    onboarding_metaverse_first_info_title: 'Проведите презентацию — предложите дополнительную ценность',
    onboarding_metaverse_first_info_subtitle: 'Регулярно мы хотели бы предложить нашему сообществу доролнительную ценность без каких-либо продуктов. Речь идет исключительно о содержании. ',
    onboarding_metaverse_second_info_title: 'Тренд, финтех, инновации, мотивация',
    onboarding_metaverse_second_info_subtitle: 'У вас есть тема, которой вы владеете, и вы хотели бы поделиться ею с нами? Не стесняйтесь связаться с нами!',

    gordons_corner_video_title: 'Краткий обзор нашей Метавселенной',
    gordons_corner_first_info_title: 'Хочешь познакомиться с Гордоном?',
    gordons_corner_first_info_subtitle: 'Мы планируем создать очень реалистичную, футуристическую и в то же время нескучную Метавселенную.',
    gordons_corner_first_info_subtitle_two: 'Первая игра в Метавселенной, которая также будет служить ориентиром. «Найти Гордона» одновременно покажет вам Gymstreet и наградит вас, когда вы найдете Гордона!',
    gordons_corner_digit_title_main: 'Наш план',
    gordons_corner_digit_first_item_title: 'Испытание и первоначальная продажа земли',
    gordons_corner_digit_second_item_title: 'Испытание и активация режима аренды NFT',
    gordons_corner_digit_third_item_title: 'Испытание активации',
    gordons_corner_sneak_peak_main_title: 'Краткий обзор наших зданий',
    gordons_corner_sneak_peak_first_card_title: 'Gymnetwork Vault',
    gordons_corner_sneak_peak_first_card_description: 'Наш официальный партнер Alpaca Finance, вы можете использовать Vaults как простой в использовании продукт, а также испытать продукты в стиле метавселенной.',
    gordons_corner_sneak_peak_second_card_title: 'CashFT',
    gordons_corner_sneak_peak_second_card_description: 'Дебетовая карта NFT Lyfestyle с множеством международных платежных решений и уникальными вознаграждениями, а также лимитами. Убедитесь в этом!',
    gordons_corner_sneak_peak_third_card_title: 'Zuckerland',
    gordons_corner_sneak_peak_third_card_description: 'Торговая площадка NFT, ориентированная на недвижимость, землю и инфраструктуру, предоставляет вам интересные данные, аналитику и многое другое. Прогуляйтесь внутри.',
    gordons_corner_sneak_peak_fourth_card_title: 'Партнерский Центр',
    gordons_corner_sneak_peak_fourth_card_description: 'Тренинги и семинары по маркетингу и многое другое. Пособия, рекламные материалы, партнеры и много контента.',
    gordons_corner_sneak_peak_fifth_card_title: 'LBANK exchange',
    gordons_corner_sneak_peak_fifth_card_description: 'Популярная биржа криптовалют, которая занимает 15-е место в списке лучших спотовых бирж криптовалют CoinMarketCap, имеет более 1,8 миллиона посещений в месяц и ежедневный объем торгов, близкий к 1,5 миллиардам долларов.',
    gordons_corner_second_info_title: 'Вы банк или биржа ',
    gordons_corner_second_info_subtitle: 'Каково ваше мнение о том, чтобы иметь такое круто спроектированное здание филиала на Gymstreet?',
    gordons_corner_second_info_subtitle_two: 'Оно может быть вашим!',
    gordons_corner_first_news_title: 'Factory for NFTs',
    gordons_corner_first_news_description: 'Скоро мы откроем "Creator Club" для опытных дизайнеров, которые захотят помочь сделать нашу Метавселенную прекрасной. И в то же период, вам станет доступна продажа ваших NFT на Zuckerland.',
    gordons_corner_second_news_title: 'Эстетичные NFT',
    gordons_corner_second_news_description: 'Метавселенной нужна эстетика. Деревья, рекламные щиты, указатели, парки, киоски с хот-догами и многое другое. На улицах Gymstreet можно найти много прекрасных вещей.',
    gordons_corner_third_news_title: 'Ваш аватар',
    gordons_corner_third_news_description: 'Хотите свой собственный аватар? Скоро это сбудется. После запуска 3D открытия начинается настоящая эпоха!',
    gordons_corner_fourth_news_title: 'Публичные аватары',
    gordons_corner_fourth_news_description: 'В зависимости от услуги и района, люди живут и работают на Gymstreet. Хотите хот-дог?',
    gordons_corner_fifth_news_title: 'Мастер по ремонту',
    gordons_corner_fifth_news_description: 'NFT приносят свои аватары с собой. Мастер по ремонту ремонтирует майнеры NFT для повышения производительности. ',

    pr_media_title_main: 'PR и СМИ',

    coming_soon: 'скоро...',
    see_here: 'Смотрите здесь',
    copyright: 'Авторские права',
    button_submit: 'Отправить',
    button_more_people: 'Больше людей',
    source: 'Источник:',
    email_address: 'Email адресс',

    position_chief_executive_officer: 'Главный исполнительный директор (CEO)',
    position_chief_technology_officer: 'Главный технический директор (CTO)',
    position_chief_information_officer: 'Главный информационный директор (CIO)',
    position_chief_marketing_officer: 'Директор по маркетингу (CMO)',
    position_chief_operations_officer: 'Главный операционный директор (COO)',
    position_principal_engineer: 'Главный инженер',
    position_moderator: 'Модератор',
    position_concept_artist: 'Концепт-художник',
    position_editor: 'Редактор',
    position_lead_designer: 'Ведущий дизайнер',
    position_three_d_team_lead: 'Руководитель команды 3D',
    position_three_d_artist: '3D художник',
    position_two_d_compositor: '2D Compositor',

    // New
    position_senior_marketing_strategist: 'Старший стратег по маркетингу',
    position_senior_project_manager: 'Менеджер проекта',
    position_senior_marketing_pm: 'Менеджер маркетинговых проектов',
    position_senior_content_writer: 'Автор контента',
    position_senior_lead_ux_ui_designer: 'Ведущий UX/UI дизайнер',
    position_senior_ux_ui_designer: 'UX/UI дизайнер',
    position_senior_motion_graphic_designer: 'Анимационный и графический дизайнер',
    position_senior_graphic_designer: 'Графический дизайнер',

    frank_roehrig_description_first: 'Управляющий директор Meta Networks FZCO нашей компании Метавселенной в Дубае. Имеет опыт разработки проектов в сфере коммерческой недвижимости',
    frank_roehrig_description_second: 'С 2015 года он увлечен децентрализованными финансами и цифровыми мирами.',
    frank_roehrig_description_third: 'Его миссия — максимально упростить ваше путешествие в мир децентрализованных финансов.',

    raphael_tobelyan_description_first: 'Рафаэль Тобелян имеет более чем десятилетний опыт работы старшим архитектором программного обеспечения. Он разрабатывал системную архитектуру для известных компаний США и Австралии.',
    raphael_tobelyan_description_second: 'Работая над более чем 30 крупномасштабными проектами, Рафаэль приобрел большой опыт работы с криптовалютами и блокчейном. У него степень магистра информационных технологий.',
    raphael_tobelyan_description_third: 'Рафаэлю 30 лет, и он счастлив в браке.',

    david_bras_description_first: 'Дэвид Брас отвечает за планирование, разработку и реализацию маркетинговых и рекламных инициатив Gym Network.',
    david_bras_description_second: 'Обладая более чем 7-летним опытом работы в сфере крипто-рекламы, он был одним из ведущих маркетологов в этой отрасли, сумев привлечь более 400 миллионов долларов за последние 5 лет.',
    david_bras_description_third: 'Благодаря его уникальным маркетинговым стратегиям, обширным знаниям в области крипто и сотрудничеству с ведущими мировыми агентствами крипто-маркетинга нет никаких препятствий для того, чтобы Gym Network стала одним из крупнейших игроков в пространстве DeFi.',

    alberto_mera_description_first: 'Альберто Мера имеет многолетний опыт работы в сфере традиционных финансов и инвестиционно-банковской деятельности в международных банках, таких как Morgan Stanley и UBS. Его интерес заключается в привнесении финансовых структур в мир децентрализованных финансов и метавселенной.',

    timur_ozturk_description_first: 'Тимур обладает природным талантом объяснять вещи, которые на первый взгляд кажутся сложными, простым и понятным языком, чтобы помочь новичкам в DeFi легко понять, как здесь все работает. Как модератор, он любит представлять продукты Gym Network нашей аудитории и объяснять их так, чтобы это было интересно и познавательно.',
    timur_ozturk_chief_communication_officer: 'Директор по связям с общественностью',

    mariam_sargsyan_description_first: 'Начав с цифрового маркетолога, Мариам Саргсян достигла большого успеха, сотрудничая с крупными рекламными и софтверными компаниями.',
    mariam_sargsyan_description_second: 'В начале 2022 года Мариам присоединилась к Gym Street в качестве старшего стратега по маркетингу. Теперь она разрабатывает стратегические маркетинговые планы, которые помогают нам общаться с людьми по всему миру.',
    mariam_sargsyan_description_third: 'Ее цель — внести свой вклад в превращение DeFi в место, где мечты становятся реальностью.',

    anzhelika_volska_description_first: 'Анжелика — человек, знающий, что управление проектами не ограничивается простым планированием проекта.',
    anzhelika_volska_description_second: 'Она не менеджер, который наблюдает со стороны. Но тот, кто отдает все для успеха. Компактный планировщик, бушующая буря во время разработки, посредник в конфликте, личная поддержка и лидер — вот и все, кто она.',

    jacob_oblasser_description_first: 'Джейкоб инструктирует и контролирует требования между командой gym. Благодаря своему опыту в области онлайн-маркетинга и брендинга, он создает концепцию веб-сайтов.',

    diana_kasprovych_description_first: 'Ведущий UX/UI дизайнер Gym Network. У нее есть опыт разработки проектов в области пользовательского опыта и пользовательского интерфейса, финансирования проектов, криптоплатформ, брендинга.',
    diana_kasprovych_description_second: 'Ее миссия — думать о логике и UX проекта. Ведущий UX/UI-дизайнер Gym Network Platform и веб-сайта, cashFT и Marketplace. Создайте бренд для CashFT с ее командой.',

    anna_bodnariuk_description_first: 'Ведущий UX/UI дизайнер Gym Street и UX/UI дизайнер Gym Network. У нее есть опыт работы в области брендинга и электронной коммерции.',
    anna_bodnariuk_description_second: 'В последнее время она серьезно занимается дизайном для Web 3.0.',
    anna_bodnariuk_description_third: 'Ее миссия — сделать ваше взаимодействие с обеими платформами естественным и захватывающим.',

    anna_kulyba_description_first: 'UX/UI дизайнер в проекте Gymnet. У нее есть опыт в предоставлении дизайнерских решений для различных секторов бизнеса, таких как блокчейн, финансы, электронная коммерция, брендинг.',
    anna_kulyba_description_second: 'Ее миссия — проводить UX-исследования, продумывать логику продукта и создавать визуальный дизайн.',

    matilda_demirjian_description_first: 'Матильда получила степень бакалавра в области графического дизайна. Она обладает более 10-летним опытом руководства дизайнерскими проектами от А до Я в различных областях, таких как дизайнерские агентства и рекламные компании.',
    matilda_demirjian_description_second: 'Сейчас она работает с нами, разрабатывает наши мета- и крипто-визуальные эффекты для GYM Network и GYM Street.',
    matilda_demirjian_description_third: 'Матильда думает, что дизайн — это мощный инструмент, который помогает нам понять цифровой мир, в котором мы живем.',

    lewis_mhlanga_description_first: 'Льюис Мхланга — инвестор в криптовалюту и недвижимость, тренер, профессионал сетевого маркетинга с более чем 6-летним опытом работы в этой отрасли.',
    lewis_mhlanga_description_second: 'Ему нравится бизнес-модель прямых продаж с интеграцией возможностей, меняющих жизнь, для тех, кто решит воспользоваться ее программой вознаграждения.',

    overview: 'Обзор',

    advisors_title_main: 'Советники',

    advisors_travladd_title: 'Travladd',
    advisors_travladd_description_first: 'Travladd Crypto — один из крупнейших влиятельных лиц Telegram в криптопространстве с более чем 70 000 участников группы. Трэвис является генеральным директором и владельцем AMA Central.',
    advisors_travladd_description_second: 'AMA Central создана одной из самых конкурентоспособных маркетинговых команд в DeFi. Имея более 400 000 подписчиков в своих социальных сетях, они официально являются одними из крупнейших влиятельных лиц DeFi в Telegram и Twitter на рынке и будут регулярно обновлять свое сообщество о Gym Network в качестве нашего официального консультанта.',

    advisors_claudio_catrini_title: 'Claudio Catrini',
    advisors_claudio_catrini_description_first: 'Консультант и коуч Клаудио Катрини поддерживает предпринимателей и международные компании в их цифровой и децентрализованной трансформации.',
    advisors_claudio_catrini_description_second: 'Обладая более чем 20-летним опытом и практикой в области продаж и маркетинга, Клаудио точно знает, как отпраздновать настоящие успехи в цифровом мире и каких ошибок следует избегать, чтобы не тратить время, деньги и ресурсы напрасно.',

    influencer_title_main: 'Инфлюенсеры',

    gymstreet_news_title_main: 'Новости Gymstreet',
    copied: "Скопировано",
  },
  // Portuguese
  'pt': {
    header_button_first: 'Explore o Metaverso',
    header_button_second: 'Conectar-se',
    header_button_third: 'Explorar',
    header_menu_item_first: 'Finanças experientes',
    header_menu_item_second: 'Guia',
    header_menu_item_third: 'Equipe',
    header_menu_item_fourth: 'Onboarding',
    header_menu_item_fifth: 'Canto do Gordon',
    header_menu_item_sixth: 'Relações Públicas e Mídia',

    footer__form__title: 'Se inscrever',
    footer__form__subtitle: 'Mantenha-se atualizado com nossos insights mais recentes.',
    footer_address_first: 'Meta Networks FZCO',
    footer_address_second: 'Número de licença: 16945',
    footer_address_third: 'IFZA Dubai - Edifício A, Unite Número 001, Dubai, Emirados Árabes Unidos',
    footer_link_first: 'Contate-Nos',
    footer_link_second: 'PERGUNTAS FREQUENTES',
    footer_link_third: 'Política de Privacidade',
    footer_address_fourth: 'Dubai',
    footer_address_fifth: 'United Arab Emirates',
    footer_address_unit_number: 'Unit Number 213',

    overview_intro_title: 'A maneira mais fácil de experimentar as finanças',
    overview_intro_subtitle: 'Como seria Wall Street hoje e daqui a 100 anos?',
    overview_intro_subtitle_second: 'DeFi, CeFi, oportunidades e produtos interessantes.',
    overview_intro_button: 'Explore o Metaverso',
    overview_intro_button_second: 'Conectar-se',
    overview_info_title: 'O que é finança experiente',
    overview_info_subtitle: 'Há finanças convencionais e finanças descentralizadas. Para entender a diferença, as pessoas precisam experimentá-la, não apenas lê-la ou estudá-la. As experiências são a chave.',
    overview_info_title_second: 'Levamos as pessoas a essas experiências: Crescimento controlável',
    overview_info_subtitle_second: 'Consultores, conselheiros e parceiros afiliados ajudam os novos usuários a navegar dentro e pelo metaverso. Em troca, eles recebem uma recompensa e os novos usuários tem uma experiência mais fácil.',
    overview_info_title_third: 'Se o produto for convincente, o cliente se motiva a usá-lo.',
    overview_info_subtitle_third: 'Ao integrar novos produtos, prestamos muita atenção à qualidade e à facilidade de uso. Se é fácil de usar, é fácil de recomendar. ',
    overview_news_title_main: 'Notícias do Gymstreet',
    overview_news_date_first: '28 de julho de 2022',
    overview_news_date_second: '28 de julho de 2022',
    overview_news_subtitle_second_first: 'Fran Roehrig,',
    overview_news_subtitle_second_second: 'Chief Executive Officer (CEO)',
    overview_news_date_third: '28 de julho de 2022',
    overview_news_date_fourth: '28 de julho de 2022',
    overview_news_date_fifth: '28 de julho de 2022',
    overview_news_title_fifth: 'Desenvolvemos e estamos desenvolvendo soluções de camada de afiliados descentralizadas e centralizadas para promover seu produto para nossos mais de 25.000 afiliados.',
    overview_info_second_title: 'Como posso experimentar o Gymstreet em modo de teste',
    overview_info_second_subtitle: 'Criamos um ambiente de teste para você para que possa começar facilmente. Você pode comprar terrenos virtuais no texto, executar as mineradoras NFT no piloto de teste e muito mais. ',
    overview_info_second_title_second: 'Conheça nosso time',
    overview_info_second_subtitle_second: 'Conheça nossa equipe. Gostaríamos que você fizesse parte disso. Quer um desafio?',
    overview_partners_title_main: 'Nossos parceiro',
    overview_telegram_title_main: 'Como podemos te ajudar?',
    overview_telegram_link: 'Entrar em contato!',

    experienced_finance_video_title: 'A história das finanças',
    experienced_finance_info_title: 'Você sabia que os grandes bancos veem o Metaverso como uma indústria de US$ 8 trilhões? ',
    experienced_finance_review_first_description: '“Se queremos uma economia criadora – se queremos que nossos filhos tenham empregos com os quais nem poderíamos sonhar – como pensamos nessas plataformas como cidadãos, empresas e reguladores?”',
    experienced_finance_review_first_position: 'vice-presidente do Ecossistema Unreal Engine da Epic Games',
    experienced_finance_review_second_description: '“Estamos tentando não definir o metaverso de forma tão rígida que limite a imaginação dos criadores.”',
    experienced_finance_review_second_position: 'CEO, Square Enix',
    experienced_finance_review_third_description: '“Penso no metaverso como uma continuação de onde a tecnologia estava indo antes da COVID. Temos Instagram. Temos e-mail. Temos mensagens. E então há nossos amigos da vida real; a atividade da vida real da qual estamos participando. Às vezes há uma interseção entre esses dois.”',
    experienced_finance_review_third_position: 'CEO, Niantic',
    experienced_finance_review_fourth_description: '“Existem centenas de milhões de pessoas se conectando a esses ambientes todos os dias. Existem muitas das empresas mais famosas do mundo construindo uma presença, e temos um comércio de dezenas e em breve centenas de bilhões de dólares.”',
    experienced_finance_review_fourth_position: 'sócio-gerente da EpyllionCo e parceiro de conhecimento da McKinsey',
    experienced_finance_review_fifth_description: '“O comportamento do consumidor mudou amplamente para a adoção de personas digitais, mas muitas marcas ainda precisam fornecer uma solução.”',
    experienced_finance_review_fifth_position: 'cofundador e CEO da AnamXR',
    experienced_finance_jump_into_main_title: 'Entre em um mundo completo e experimente as finanças',
    experienced_finance_time_main_title: 'Entenda o tempo',
    sandglass_periods_past: 'Passado',
    sandglass_periods_now: 'Agora',
    sandglass_periods_future: 'Futuro',
    sandglass_info_description_first: 'Clique em uma categoria para revelar camadas adicionais.',
    sandglass_info_title_second: 'Futuro',
    sandglass_info_description_second: 'Gymstreet com consultores no Metaverso',
    sandglass_info_title_third: 'Passado',
    sandglass_info_description_third: 'Banco há 30 anos - balcões e atendimento no local',
    sandglass_info_title_fourth: 'Agora',
    sandglass_info_first_description_fourth: 'Plataformas DeFi e finanças no Metaverso',
    sandglass_info_second_description_fourth: 'Banco como um serviço - Online e neo banking',
    sandglass_info_third_description_fourth: 'Banca e seguros via consultor em casa',
    experienced_finance_infomasses_main_title: 'Trazemos DeFi e CeFi para as massas',
    experienced_finance_infomasses_card_first_title: 'Consultar e aconselhar',
    experienced_finance_infomasses_card_first_description: 'Nosso consultor e afiliados irão mostrar-lhe os diferentes produtos e aconselhá-lo para que você possa dominá-lo sozinho.',
    experienced_finance_infomasses_card_second_title: 'Ensinar e educar',
    experienced_finance_infomasses_card_second_description: 'Em nossa universidade oferecemos cursos e workshops ao vivo sobre todos os temas do nosso metaverso. Iniciantes a profissionais se sentem muito à vontade conosco.',
    experienced_finance_infomasses_card_third_title: 'Afiliados são bem-vindos',
    experienced_finance_infomasses_card_third_description: 'Somos a plataforma de metaverso de crescimento mais rápido e maior para parcerias DeFi, CeFi e afiliados. Você gostaria de recomendar nossos produtos?',
    experienced_finance_infomasses_card_fourth_title: 'Mova-se com velocidade',
    experienced_finance_infomasses_card_fourth_description: 'Em um mercado tão rápido, a velocidade é um dos fatores-chave. Para ser rápido, você precisa de uma equipe forte e grande e uma vontade constante de inovar.',
    experienced_finance_digits_main_title: 'O Metaverso mudará a maneira como as pessoas entendem e usam o dinheiro',
    experienced_finance_digits_item_first: 'em investimento fluiu para o metaverso até agora em 2022',
    experienced_finance_digits_item_second: 'dos consumidores ativos no metaverso fizeram uma compra',
    experienced_finance_digits_item_third: 'da receita corporativa deve vir do metaverso nos próximos 5 anos, de acordo com 25% dos executivos seniores',

    guide_video_title: 'Três maneiras de orientar',
    guide_users_main_title: 'Orientamos nossos usuários em seu caminho para dentro e no metaverso',
    guide_users_main_subtitle: 'Imagine que você tem que aprender a tocar piano sem um professor. Isso é o que acontece com a maioria das pessoas em seu caminho para o metaverso. Pessoas corajosas se atrevem a entrar. As grandes massas nunca seguirão o caminho sozinhas.',
    guide_first_card_title: 'Afiliado',
    guide_first_card_description: 'Nossas parcerias de afiliados atendem ao primeiro e segundo contato. A informação e a primeira experiência que um novo usuário tem.',
    guide_second_card_title: 'Consultor',
    guide_second_card_description: 'Nossos consultores e moderadores mostram aos usuários como navegar no metaverso e como usar e experimentar os produtos.',
    guide_third_card_title: 'Orientador',
    guide_third_card_description: 'Consultores externos nos ajudam a integrar seus produtos e, se necessário, tornam-os simples o suficiente para serem facilmente experimentados de forma integrativa.',
    guide_team_main_title: 'Conheça nosso time',

    team_title_main_first: 'Conheça nosso time',
    team_title_main: 'Departamento de desenvolvimento e operação',
    team_title_main_second: 'Departamento criativo',

    onboarding_video_title: 'Vantagens claras,',
    onboarding_video_title_second: 'operação fácil e bom produto',
    onboarding_video_subtitle: 'Nós integramos players globais, bem como startups emocionantes e fornecemos a você um edifício no Metaverso de primeira classe.',
    onboarding_text_info_item_first: 'Imagine Wall Street como pode ser hoje e no futuro. DeFi, CeFi, grandes players globais e startups. Tudo em um enorme centro financeiro - Gymstreet. Oferecemos aos nossos clientes acessos e oportunidades já conhecidos, mas recém-projetados e redesenhados. Paralelamente, também oferecemos Startups e Produtos altamente interessantes com benefícios interessantes do mundo financeiro.',
    onboarding_text_info_item_second: 'Queremos ser multifacetados e representar todas as sub-indústrias do nosso metaverso, que existem ou ainda não existem.',
    onboarding_text_info_item_second_two: 'É importante para nós gerar vantagens para nossos clientes e afiliados da mesma forma e oferecer a eles a educação necessária ao mesmo tempo',
    onboarding_first_advantage_title: 'Gymnetwork Vaults e DeFi ',
    onboarding_first_advantage_description: 'Parceiro oficial da Alpaca Finance.',
    onboarding_second_advantage_title: 'CashFT',
    onboarding_second_advantage_description: 'Cartões de débito NFT Lifestyle.',
    onboarding_third_advantage_title: 'Praças de comércio',
    onboarding_third_advantage_description: 'Negociação, FX, Commodities.',
    onboarding_fourth_advantage_title: 'Universidade',
    onboarding_fourth_advantage_description: 'Excelente formação na área de finanças.',
    onboarding_fifth_advantage_title: 'Centro de Afiliados',
    onboarding_fifth_advantage_description: 'Ecuação de qualidade em Marketing.',
    onboarding_sixth_advantage_title: 'Zuckerland',
    onboarding_sixth_advantage_description: 'Mercado NFT focado em imóveis, terrenos e infraestrutura.',
    onboarding_affiliate_title: 'Soluções de camada de afiliados',
    onboarding_affiliate_subtitle: 'Nossos parceiros afiliados ajudarão você a vender seu produto com ainda mais sucesso. E fornecemos a você como iniciador nossas soluções e estrutura de software, para que você tenha ainda mais sucesso com qualidade e rapidez.',
    onboarding_first_digits_title: 'investidores em mais de 50 países",',
    onboarding_second_digits_title: 'afiliados em todo o mundo',
    onboarding_third_digits_title: 'valor de mercado alcançado pela Gymnetwork após 8 semanas',
    onboarding_fourth_digits_title: 'desenvolvedores trabalhando em 3 regiões diferentes',
    onboarding_metaverse_title_main: 'Integração do metaverso',
    onboarding_metaverse_first_card_title: 'Um banco, uma bolsa, um seguro ou uma Fintech',
    onboarding_metaverse_first_card_subtitle: 'Sinta-se à vontade para entrar em contato conosco e nos dizer porque você é perfeito para o nosso Metaverso.',
    onboarding_metaverse_first_card_subtitle_second: 'Em nosso metaverso financeiro queremos integrar os melhores bancos, bolsas de valores e seguradoras com seus produtos.',
    onboarding_metaverse_first_card_subtitle_third: 'Alpaca.finance',
    onboarding_metaverse_first_card_subtitle_fourth: 'e Lbank aproveitaram esta oportunidade diretamente e decidiram cooperar para melhor posicionar seus produtos de primeira classe.',
    onboarding_metaverse_second_card_title: 'Nova tecnologia',
    onboarding_metaverse_second_card_subtitle: 'Você tem uma nova tecnologia emergente do mundo do blockchain? Nós estamos muito animados!',
    onboarding_metaverse_third_card_title: 'Token ou moeda',
    onboarding_metaverse_third_card_subtitle: 'Você acha que seu projeto é sustentável no mercado de ativos digitais e seu conceito é único? Vamos conversar.',
    onboarding_metaverse_fourth_card_title: 'Financiar produto e projeto',
    onboarding_metaverse_fourth_card_subtitle: 'Sua solução oferece uma clara vantagem para nossa comunidade? Sinta-se à vontade para se inscrever.',
    onboarding_metaverse_fifth_card_title: 'Exchange LBANK',
    onboarding_metaverse_fifth_card_subtitle: 'A popular exchange de criptomoedas que está em 15º lugar na lista Top Cryptocurrency Spot Exchanges da CoinMarketCap tem mais de 1,8 milhão de visitas mensais e um volume diário de negociação próximo a US$ 1,5 bilhão.',
    onboarding_metaverse_title_main_second: 'Integração do metaverso',
    onboarding_metaverse_first_card_description: 'Fazemos uma integração 3D. Na primeira etapa, nomeamos as características de nossa cooperação e os benefícios para nossos parceiros afiliados.',
    onboarding_metaverse_second_card_description: 'Na segunda etapa você escolhe um ou mais lotes em nosso terreno virtual ou até mesmo um local público. Você pode comprá-los ou alugá-los.',
    onboarding_metaverse_third_card_description: 'Na 3ª etapa você comissiona nossa empresa com o projeto da sua construção virtual e recebe uma representação de primeira em nosso metaverso.',
    onboarding_metaverse_fourth_card_description: 'Eles celebram a abertura junto conosco, nossos parceiros e novos clientes em potencial.',
    onboarding_metaverse_first_info_title: 'Faça uma apresentação - ofereça valor agregado',
    onboarding_metaverse_first_info_subtitle: 'Gostaríamos de oferecer à nossa comunidade regularmente um valor agregado livre de quaisquer produtos. É puramente sobre o conteúdo.',
    onboarding_metaverse_second_info_title: 'Tendência, fintech, inovação, motivação',
    onboarding_metaverse_second_info_subtitle: 'Você tem um tópico que domina e gostaria de compartilhá-lo conosco? Sinta-se livre para nos contatar!',

    gordons_corner_video_title: 'Uma prévia do nosso Metaverso',
    gordons_corner_first_info_title: 'Quer conhecer Gordon?',
    gordons_corner_first_info_subtitle: 'Claro, é nossa pretensão criar um Metaverso muito realista e ao mesmo tempo futurista. Mas a diversão não pode faltar...',
    gordons_corner_first_info_subtitle_two: 'O primeiro metaverso ingame, que também servirá como orientação. O "Find Gordon" mostrará simultaneamente a Gymstreet e dará recompensas quando você encontrá-lo!',
    gordons_corner_digit_title_main: 'Nosso roteiro',
    gordons_corner_digit_first_item_title: 'Experiência e Venda de Terreno inicial',
    gordons_corner_digit_second_item_title: 'Experiência e ativação do modo de aluguel NFT',
    gordons_corner_digit_third_item_title: 'Ativação de Experiência',
    gordons_corner_sneak_peak_main_title: 'Uma olhada nos nossos edifícios',
    gordons_corner_sneak_peak_first_card_title: 'Vault Gymnetwork',
    gordons_corner_sneak_peak_first_card_description: 'Nosso parceiro oficial aqui é a Alpaca Finance e você pode usar esses Vaults como um produto fácil de usar e também experimentar os produtos no estilo metaverso.',
    gordons_corner_sneak_peak_second_card_title: 'CashFT',
    gordons_corner_sneak_peak_second_card_description: 'Um cartão de débito NFT Lyfestyle com muitas soluções de pagamento transfronteiriço incluso e recompensas exclusivas, também Limites. Confira!',
    gordons_corner_sneak_peak_third_card_title: 'Zuckerland',
    gordons_corner_sneak_peak_third_card_description: 'O mercado NFT focado em imóveis, terrenos e infraestrutura fornece dados interessantes, análises e muito mais para você. Faça um tour por dentro.',
    gordons_corner_sneak_peak_fourth_card_title: 'Centro de afiliados',
    gordons_corner_sneak_peak_fourth_card_description: 'Treinamentos e Workshops para Marketing e muito mais. Materiais, Material promocional, outros afiliados e muito conteúdo.',

    gordons_corner_second_info_title: 'Você é banco ou uma exchange',
    gordons_corner_second_info_subtitle: 'Qual é a sua opinião sobre ter um prédio tão legal no gymstreet',
    gordons_corner_second_info_subtitle_two: 'Realmente, pode ser seu',
    gordons_corner_first_news_title: 'Fábrica para NFT',
    gordons_corner_first_news_description: 'Em breve abriremos um Clube de Criadores para designers habilidosos que queiram nos ajudar a deixar nosso Metaverso bonito. Ao mesmo tempo, você também pode comercializar suas NFTs no Zuckerland.io',
    gordons_corner_second_news_title: 'NFTs estético',
    gordons_corner_second_news_description: 'Um Metaverso precisa de Estética. Árvores, outdoors, placas, parques, barracas de cachorro-quente e muito mais. Nas ruas do Gymstreet você pode encontrar muitas coisas bonitas',
    gordons_corner_third_news_title: 'Seu avata',
    gordons_corner_third_news_description: 'Quer seu próprio avatar? Em breve se tornará realidade. Após o lançamento da abertura 3D começa esta era',
    gordons_corner_fourth_news_title: 'Avatares público',
    gordons_corner_fourth_news_description: 'Dependendo do serviço e da área, as pessoas vivem e trabalham no Gymstreet. Quer comer um cachorro-quente',
    gordons_corner_fifth_news_title: 'Gerente de repar',
    gordons_corner_fifth_news_description: 'A NFTs trazem seus avatares com eles. O Gerente de reparo conserta mineradoras NFT para aumentar o desempenho.',

    pr_media_title_main: 'Relações Públicas e Mídia',

    coming_soon: 'em breve...',
    see_here: 'Veja aqui',
    copyright: 'Direito autoral',
    button_submit: 'Enviar',
    button_more_people: 'Mais pessoas',
    source: 'Fonte:',

    position_chief_executive_officer: 'Chief Executive Officer (CEO)',
    position_chief_technology_officer: 'Diretor de Tecnologia (CTO)',
    position_chief_information_officer: 'Diretor de Informação (CIO)',
    position_chief_marketing_officer: 'Diretor de Marketing (CMO)',
    position_chief_operations_officer: 'Diretor de Operações (COO)',
    position_principal_engineer: 'Engenheiro chefe',
    position_moderator: 'Moderador',
    position_concept_artist: 'Artista Conceitual',
    position_editor: 'Editor',
    position_lead_designer: 'Designer-chefe',
    position_three_d_team_lead: 'Líder de equipe 3D',
    position_three_d_artist: 'Artista 3D',
    position_two_d_compositor: 'Compositor 2D',
    position_senior_marketing_strategist: 'Estrategista de Marketing Sênior',
    position_senior_project_manager: 'Gestor de projeto',
    position_senior_marketing_pm: 'Gerente de Projetos de Marketing',
    position_senior_content_writer: 'Escritor de conteúdo',
    position_senior_lead_ux_ui_designer: 'Designer líder de UX/UI',
    position_senior_ux_ui_designer: 'Designer de UX/UI',
    position_senior_motion_graphic_designer: 'Motion e designer gráfico',
    position_senior_graphic_designer: 'Design gráfico',

    frank_roehrig_description_first: 'O diretor administrativo da Meta Networks FZCO, nossa empresa no Metaverso em Dubai. Tem experiência em desenvolvimento de projetos no setor imobiliário comercial.',
    frank_roehrig_description_second: 'Desde 2015, ele se concentra apaixonadamente em finanças descentralizadas e mundos digitais.',
    frank_roehrig_description_third: 'Sua missão é tornar sua jornada no mundo das finanças descentralizadas o mais fácil possível.',

    raphael_tobelyan_description_first: 'Rafayel Tobelyan possui mais de dez anos de experiência como arquiteto de software sênior. Ele fez arquitetura de sistema para empresas famosas sediadas nos EUA e na Austrália.',
    raphael_tobelyan_description_second: 'Enquanto trabalhava em mais de 30 projetos de grande escala, Rafayel ganhou muita experiência em criptomoedas e blockchain. Possui mestrado em Tecnologias da Informação.',
    raphael_tobelyan_description_third: 'Rafayel tem 30 anos e está em um casamento feliz.',

    david_bras_description_first: 'David Bras é responsável por supervisionar o planejamento, desenvolvimento e execução das iniciativas de marketing e publicidade do Gym Network.',
    david_bras_description_second: 'Com mais de 7 anos de experiência em publicidade cripto, ele tem sido um dos principais profissionais de marketing do setor, capaz de arrecadar mais de US$ 400 milhões nos últimos 5 anos.',
    david_bras_description_third: 'Com suas estratégias de marketing exclusivas, ampla gama de conhecimento de criptomoedas e colaborações com as principais agências de marketing de criptomoedas do mundo, não há barreiras para que o Gym Network seja um dos maiores players do Espaço DeFi.',

    alberto_mera_description_first: 'Alberto Mera tem anos de experiência em finanças tradicionais e banco de investimento em bancos internacionais como Morgan Stanley e UBS. Seu interesse está em trazer estruturas financeiras para o mundo das finanças descentralizadas e do metaverso.',
    timur_ozturk_description_first: 'Timur tem um talento natural para dividir coisas que parecem complicadas no início em partes simplificadas para ajudar aqueles que podem ser novos no DeFi a entender facilmente como as coisas estão funcionando aqui. Como moderador, ele adora apresentar os produtos do Gym Network ao nosso público e explicá-los de forma divertida e educativa.',
    timur_ozturk_chief_communication_officer: 'Diretor de Comunicação',

    mariam_sargsyan_description_first: 'Começando como profissional de marketing digital, Mariam Sargsyan chegou ao topo cooperando com grandes empresas de publicidade e software.',
    mariam_sargsyan_description_second: 'No início de 2022, Mariam entrou no Gym Street como estrategista sênior de marketing. Agora ela apresenta planos estratégicos de marketing que nos ajudam a nos comunicar com pessoas em todo o mundo.',
    mariam_sargsyan_description_third: 'Seu objetivo é contribuir para que a DeFi seja um lugar onde os sonhos se tornem realidade.',

    anzhelika_volska_description_first: 'A Anzhelika é uma pessoa que sabe que o gerenciamento de projetos não se limita a simplesmente planejar um projeto.',
    anzhelika_volska_description_second: 'Ela não é a gerente que assiste do lado de fora. Mas aquele que dá tudo pelo sucesso. Uma planejadora compacta, uma tempestade furiosa durante o desenvolvimento, uma mediadora de conflitos, uma provedora de suporte pessoal e líder - isso é tudo que ela é.',

    jacob_oblasser_description_first: 'Jacob informa e monitora os requisitos entre a equipe do ginásio. Devido à sua experiência em marketing online e branding, ele constrói o conceito por trás dos sites.',

    diana_kasprovych_description_first: 'Chefe de projetos de rede de academias de UX/UI Designer. Tem experiência em desenvolvimento de projetos na Experiência de Usuário e Interface de Usuário, projetos de finanças, plataformas cripto, branding.',
    diana_kasprovych_description_second: 'Sua missão é pensar em Lógica e no UX do Projeto. Designer líder de UX/UI da plataforma Gym Network e site, cashFT e Marketplace. Crie um nome de marca para CashFT com sua equipe.',

    anna_bodnariuk_description_first: 'Designer líder de UX/UI da Gym Street e UX/UI Designer da Gym Network. Tem experiência em projetos de branding e e-commerce.',
    anna_bodnariuk_description_second: 'Ultimamente, ela está profundamente interessada em projetar para Web 3.0.',
    anna_bodnariuk_description_third: 'Sua missão é tornar sua interação com ambas as plataformas nativa e emocionante.',
    anna_kulyba_description_first: 'Designer de UX/UI no projeto Gymnet. Ela tem experiência no fornecimento de soluções de design para diversos setores de negócios como blockchain, finanças, e-commerce, branding.',
    anna_kulyba_description_second: 'Sua missão é realizar pesquisas de UX, pensar na lógica do produto e criar design visual.',

    matilda_demirjian_description_first: 'Matilda é bacharel em Design Gráfico e tem mais de 10 anos de experiência dirigindo projetos de design de A a Z em diferentes áreas, como agências de design e empresas de publicidade.',
    matilda_demirjian_description_second: 'Atualmente trabalhando conosco, ela projeta nossos visuais meta e cripto da GYM Network e GYM Street.',
    matilda_demirjian_description_third: 'Matilda reconhece que o design é uma ferramenta poderosa que nos ajuda a entender o mundo digital em que vivemos.',

    overview: 'Visão Geral',

    lewis_mhlanga_description_first: 'Lewis Mhlanga é um investidor de criptomoedas e imóveis, instrutor e profissional de marketing de rede com mais de 6 anos neste setor.',
    lewis_mhlanga_description_second: 'Ele gosta do modelo de negócios de vendas diretas com integração de oportunidades de mudança de vida para aqueles que optam por aproveitar seu programa de recompensas.',

    footer_link_fourth: 'Termos de uso',
    gordons_corner_sneak_peak_fifth_card_title: 'Câmbio LBANK',
    gordons_corner_sneak_peak_fifth_card_description: 'A popular exchange de criptomoedas que está em 15º lugar na lista Top Cryptocurrency Spot Exchanges da CoinMarketCap tem mais de 1,8 milhão de visitas mensais e um volume diário de negociação próximo a US$ 1,5 bilhão.',
    email_address: 'Endereço de e-mail',
    advisors_title_main: 'Conselheiros',
    advisors_claudio_catrini_description_first: 'O consultor e coach Claudio Catrini apoia empreendedores e empresas internacionais na transformação digital e descentralizada.',
    advisors_claudio_catrini_description_second: 'Com mais de 20 anos de experiência e atuação nas áreas de vendas & marketing, Claudio sabe exatamente como comemorar verdadeiros sucessos no mundo digital e quais erros evitar para não perder tempo, dinheiro e recursos desnecessariamente.',
    copied: 'copiado',
  }

}

const i18n = new VueI18n({
  locale: 'en', // set locale
  messages, // set locale messages
});

export default i18n;
