export default {
  state: {
    newsData: [
      {
        "name": "GYMSTREET The Most Innovative DEFI Project of 2022",
        "shortDescription": "Gym Street Metaverse Awarded as the Most Innovative...",
        "description": "<p>Gym Street Metaverse Awarded as the Most Innovative DeFi Project of 2022 at Crypto Expo Dubai 2022 <br/> 🏆Launched 2 Months and Already Awarded</p><br/><br/><p>Gym Street was honored as the Most Innovative DeFi Project of 2022 by the blockchain community at Crypto Expo Dubai 2022, one of the world’s largest cryptocurrency expos💙</p><br/><br/><p>Given that Gym Street was launched in August 2022, just two months ahead of the award, this achievement is a doubly binding and motivating statement for the Gym Street team🌟</p><br/><br/><p>In 2022, the Crypto Expo Dubai hosted over 130 crypto businesses from 30 countries⛓️ Meanwhile, more than 10k investors and traders visited the event from different parts of the world.</p><br/><br/><p>For 2 days, people could not ignore the Gym Street booth's striking design, which was to mirror the atmosphere that people are to experience in Metaverse🔥</p><br/><br/>",
        "image": "photo_2022-12-01_00-00-00.png",
        "createdDate": "2022-12-01T00:00:00.735Z",
        "id": "631659db78ad860d0b66b612"
      },
      {
        "name": "Official partnership with Travladd Crypto ",
        "shortDescription": "Gym Network has officially partnered...",
        "description": "<p>📣 OFFICIAL PARTNERSHIP WITH TRAVLADD CRYPTO 📣</p><p>🤩 We have got exciting news to share!</p><p>🤝 Gym Network has officially partnered with Travladd Crypto ~ Serious Investor Group (aka AMA Central), one of the biggest Telegram influencers in the crypto space with 70,000+ group members.&nbsp;</p><p>AMA Central is built by one of the most competitive marketing teams in DeFi. With more than 400'000 followers on their socials they have been officially one of the biggest DeFi Telegram and Twitter influencers in the market and will regularly update their community about Gym Network as our official advisor.</p><p>👉 Follow AMA Central on Telegram:</p><p><a href=\"https://t.me/AMA_Central\" rel=\"noopener noreferrer\" target=\"_blank\">@AMA_Central</a></p><p>💸 Join Friends of Travladd - $10,000 Community Giveaway:</p><p><a href=\"https://gleam.io/nCigg/friends-of-travladd-crypto-10000-giveaway\" rel=\"noopener noreferrer\" target=\"_blank\">https://gleam.io/nCigg/friends-of-travladd-crypto-10000-giveaway</a></p>",
        "image": "photo_2022-08-26_08-55-51.png",
        "createdDate": "2022-09-05T20:19:39.735Z",
        "id": "631659db78cd800d0a66b602"
      },
      {
        "name": "The famous comedian Carlos Davis is promoting GYM NETWORK in his Instagram Story.",
        "shortDescription": "We've started a new marketing campaign...",
        "description": "<p>🔥 🔥 🔥 HOT NEWS 🔥 🔥 🔥</p><p>We've started a new marketing campaign to promote the launch of GYM NETWORK V2.0 and raise awareness. One part of it is to work with famous celebrities!</p><p>Currently the famous comedian Carlos Davis is promoting GYM NETWORK in his Instagram Story. 🤩</p><p><a href=\"https://instagram.com/stories/hahadavis/2898500924912670696?utm_source=ig_story_item_share&amp;igshid=YmMyMTA2M2Y=\" rel=\"noopener noreferrer\" target=\"_blank\">https://instagram.com/stories/hahadavis/2898500924912670696?utm_source=ig_story_item_share&amp;igshid=YmMyMTA2M2Y=</a></p><p>👀 He has 7.7 Million followers, so a lot of potential prospects for GYM NETWORK!</p><p>🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀</p>",
        "image": "photo_2022-08-06_13-39-33.png",
        "createdDate": "2022-09-05T20:17:45.346Z",
        "id": "6316596978cd800d0a66b5fc"
      },
      {
        "name": "Jaden Newman, an upcoming basket ball talent and influencer, is promoting GYM NETWORK in her Instagram Story. ",
        "shortDescription": "We've started a new marketing...",
        "description": "<p>🔥 🔥 🔥 HOT NEWS 🔥 🔥 🔥</p><p>We've started a new marketing campaign to promote the launch of GYM NETWORK V2.0 and raise awareness. One part of it is to work with famous celebrities!</p><p>Currently Jaden Newman, an upcoming basket ball talent and influencer, is promoting GYM NETWORK in her Instagram Story. 🤩</p><p>👀 She has 1 Million followers, so a lot of potential prospects for GYM NETWORK!</p><p>🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀</p>",
        "image": "photo_2022-08-07_13-47-47.png",
        "createdDate": "2022-09-05T20:16:27.052Z",
        "id": "6316591b78cd800d0a66b5f6"
      },
      {
        "name": "The next influencer to promote GYM NETWORK V2 in her Instagram Story is Daniela Bessia.",
        "shortDescription": "Our Influencer Campaign...",
        "description": "<p>Our Influencer Campaign keeps running hot! 🔥</p><p>The next influencer to promote GYM NETWORK V2 in her Instagram Story is Daniela Bessia: Actress 🎬 TV Personality 🎤 Singer Songwriter 👉 Award Winning 🏆 Grammy Member BMI, and Top 🔟 Expat Influencer of Shanghai China. 🤩</p><p>Daniela recently published her first own NFT a few days ago! 👀</p><p>She may have \"only\" 393k followers, but her audience is crazy. 😜</p><p><a href=\"https://instagram.com/stories/danielabessia/2899490533352586565?utm_source=ig_story_item_share&amp;igshid=YmMyMTA2M2Y=\" rel=\"noopener noreferrer\" target=\"_blank\">https://instagram.com/stories/danielabessia/2899490533352586565?utm_source=ig_story_item_share&amp;igshid=YmMyMTA2M2Y=</a></p><p>🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀</p>",
        "image": "photo_2022-08-07_17-59-02.png",
        "createdDate": "2022-09-05T20:15:19.102Z",
        "id": "631658d778cd800d0a66b5f0"
      },
      {
        "name": "The next influencer to promote GYM NETWORK V2 in his Instagram Story is Trillionaire E Riche Rich.",
        "shortDescription": "The next influencer to promote...",
        "description": "<p>💥💥💥 BOOM! 💥💥💥</p><p>The next influencer to promote GYM NETWORK V2 in his Instagram Story is Trillionaire E Riche Rich:&nbsp;</p><p>Artist 🎤 Musician, CEO of both @the_network1 and @mprmg1 on Instagram. 🤩</p><p>He has 4.8 Million followers, so there will be many potential prospects to get interested in GYM NETWORK 🚀🚀🚀</p>",
        "image": "photo_2022-08-08_22-44-43.png",
        "createdDate": "2022-09-05T20:14:22.938Z",
        "id": "6316589e78cd800d0a66b5ea"
      },
      {
        "name": "Our Influencer Campaign keeps running hot! The next influencer to promote GYM NETWORK V2 in his Instagram Story is Aykut Guzel.",
        "shortDescription": "Our Influencer Campaign...",
        "description": "<p>Our Influencer Campaign keeps running hot! 🔥</p><p>The next influencer to promote GYM NETWORK V2 in his Instagram Story is Aykut Guzel:</p><p>@djaykutguzel</p><p><a href=\"https://instagram.com/stories/djaykutguzel/2900891322894887851?utm_source=ig_story_item_share&amp;igshid=YmMyMTA2M2Y=\" rel=\"noopener noreferrer\" target=\"_blank\">https://instagram.com/stories/djaykutguzel/2900891322894887851?utm_source=ig_story_item_share&amp;igshid=YmMyMTA2M2Y=</a></p><p>NFT Artist 🎧 Disc Jockey, 🔊 Music Director. 🤩</p><p>He has 204k loyal followers, so there will be many potential prospects to get interested in GYM NETWORK 🚀🚀🚀</p>",
        "image": "photo_2022-08-09_14-38-54.png",
        "createdDate": "2022-09-05T20:13:09.827Z",
        "id": "6316585578cd800d0a66b5e4"
      },
      {
        "name": "Benny Camaro, a popular artist, musician, songwriter from Italy, who recently promoted GYM NETWORK in his Instagram Story.",
        "shortDescription": "Yet another influencer...",
        "description": "<p>Yet another influencer to promote GYM NETWORK 🤩</p><p>This time it's Benny Camaro, a popular Artist 🎤 Musician 🔊 Songwriter from Italy🇮🇹 who recently promoted GYM NETWORK in his Instagram Story:</p><p><a href=\"https://instagram.com/stories/bennycamaro/2901591881276240270?utm_source=ig_story_item_share&amp;igshid=YmMyMTA2M2Y=\" rel=\"noopener noreferrer\" target=\"_blank\">https://instagram.com/stories/bennycamaro/2901591881276240270?utm_source=ig_story_item_share&amp;igshid=YmMyMTA2M2Y=</a></p><p>Benny has 742k loyal followers, so there will be many potential prospects to get interested in GYM NETWORK 🚀🚀🚀</p>",
        "image": "photo_2022-08-10_18-51-10.png",
        "createdDate": "2022-09-05T20:11:43.827Z",
        "id": "631657ff78cd800d0a66b5de"
      },
      {
        "name": "The next influencer to promote GYM NETWORK V2 in her Instagram Story is Diana Moric of @diannafitness 🤩",
        "shortDescription": "The next influencer to promote...",
        "description": "<p>Our Influencer Campaign Keeps Running Hot! 🔥</p><p>The next influencer to promote GYM NETWORK V2 in her Instagram Story is Diana Moric of @diannafitness 🤩</p><p>World Champion in Sambo &amp; Judo 🥇 🥉 🏆</p><p><a href=\"https://instagram.com/stories/diannafitness/2903102181434316931?utm_source=ig_story_item_share&amp;igshid=YmMyMTA2M2Y=\" rel=\"noopener noreferrer\" target=\"_blank\">https://instagram.com/stories/diannafitness/2903102181434316931?utm_source=ig_story_item_share&amp;igshid=YmMyMTA2M2Y=</a></p><p>She has 1 Million loyal followers, so there will be again many potential prospects to get interested in GYM NETWORK 🚀🚀🚀</p>",
        "image": "photo_2022-08-12_18-51-46.png",
        "createdDate": "2022-09-05T20:06:03.052Z",
        "id": "631656ab78cd800d0a66b5ca"
      },
      // {
      //   "name": "📘 OUR NEW GITBOOK IS HERE!",
      //   "shortDescription": "With the launch of our new platform...",
      //   "description": "<p>📘 OUR NEW GITBOOK IS HERE!</p><p>With the launch of our new platform, the documentation was also completely revised. There you can get comprehensive information about GYM NETWORK and our products - in 12 languages!</p><p>🇬🇧🇰🇷🇯🇵🇻🇳🇨🇳🇷🇺🇪🇸🇵🇹🇹🇷🇫🇷🇹🇭🇲🇾</p><p>👉 You can access the new Gitbook via this link:</p><p><a href=\"https://docs.gymnetwork.io/\" rel=\"noopener noreferrer\" target=\"_blank\">https://docs.gymnetwork.io/</a></p>",
      //   "image": "photo_2022-08-13_15-07-00.png",
      //   "createdDate": "2022-09-05T20:04:42.051Z",
      //   "id": "6316565a78cd800d0a66b5c4"
      // },
      {
        "name": "🏋️‍ Exklusive Gym Network V2.0 Launch Event and Promotion 🏅",
        "shortDescription": "Excited about meeting ...",
        "description": "<p>Excited about meeting the entire Gym Network team in person?</p><p>We sure are excited to get to know you!</p><p>If you’ve been wondering about when and where exactly, we are now ready to share that information with you.</p><p>VENUE &amp; TIME</p><p>📍 Hotel Atlantis The Palm</p><p>🗓 18 August 2022</p><p>🕚 11:00 GST (Dubai local time)</p><p>We’ve also updated our registration form:</p><p><a href=\"https://www.eventbrite.com/e/leader-launch-event-tickets-380254039067\" rel=\"noopener noreferrer\" target=\"_blank\">https://www.eventbrite.com/e/leader-launch-event-tickets-380254039067</a></p><p>The Hotel 🏨 and Flight ✈️ Promotion🏅 is still active as well:</p><p>⚠️ ATTENTION: NEW PROMOTION PERIOD!</p><p>The promo will start with the launch of the NFT sales (will be announced soon) and last 18 days. Those who meet the criteria during that period AND attend the Dubai Event will receive the compensation afterwards.</p><p>We will cover hotel and airfare costs if the following criteria are met:&nbsp;</p><p>NFT Turnover:</p><p>1. 50.000$ with team (50% rule)&nbsp;</p><p>= 🏨 hotel costs 600$</p><p>2. 100.000$ with team (50% rule)&nbsp;</p><p>= ✈️ airfare 1.500$</p><p>The amount can be claimed after the event via the dashboard.</p>",
        "image": "photo_2022-08-14_16-00-39.png",
        "createdDate": "2022-09-05T20:00:19.697Z",
        "id": "6316555378cd800d0a66b5a6"
      },
      {
        "name": "The next influencer to promote GYM NETWORK V2 in his Instagram Story is the famous MMA Pro Erko Jun.",
        "shortDescription": "The next influencer ...",
        "description": "<p>The next influencer to promote GYM NETWORK V2 in his Instagram Story is the famous MMA Pro Erko Jun:</p><p><a href=\"https://instagram.com/stories/erkojun/2904517568338580373?utm_source=ig_story_item_share&amp;igshid=YmMyMTA2M2Y=\" rel=\"noopener noreferrer\" target=\"_blank\">https://instagram.com/stories/erkojun/2904517568338580373?utm_source=ig_story_item_share&amp;igshid=YmMyMTA2M2Y=</a></p><p>He has 1.1 Million loyal followers, so there will be again many potential prospects to get interested in GYM NETWORK.</p>",
        "image": "photo_2022-08-14_21-26-31.png",
        "createdDate": "2022-09-05T19:57:11.099Z",
        "id": "6316549778cd800d0a66b5a0"
      },
      {
        "name": "Lady Victoria Hervey, the former \"It girl\" and model, is the latest celebrity to promote Gym Network on social media! 😍",
        "shortDescription": "Lady Victoria Hervey, the former \"It girl\"...",
        "description": "<p>Lady Victoria Hervey, the former \"It girl\" and model, is the latest celebrity to promote Gym Network on social media!</p><p>@ladyvictoriahervey</p><p>She's shared a nice poster introducing her followers to Gym Network V2.0, from NFTs to our unique metaverse!</p><p>Which celebrity do you think fits the bill to partner with us next? 🚀</p><p><a href=\"https://instagram.com/stories/ladyvictoriahervey/2905619687353441747?utm_source=ig_story_item_share&amp;igshid=YmMyMTA2M2Y=\" rel=\"noopener noreferrer\" target=\"_blank\">https://instagram.com/stories/ladyvictoriahervey/2905619687353441747?utm_source=ig_story_item_share&amp;igshid=YmMyMTA2M2Y=</a></p>",
        "image": "photo_2022-08-16_15-11-04.png",
        "createdDate": "2022-09-05T19:47:52.489Z",
        "id": "6316526878cd800d0a66b585"
      },
      {
        "name": "Virtual land and virtual NFT miner. Convince yourself of our inititial products.",
        "shortDescription": null,
        "description": null,
        "image": "earth.png",
        "createdDate": "2022-09-03T13:16:44.170Z",
        "id": "631353bc2efaf2a038f04449"
      },
      {
        "name": "“Gymstreet is a unique opportunity for sophisticated customers.” ",
        "shortDescription": "<p> Fran Roehrig,</p><p>Chief Executive Officer (CEO)</p>",
        "description": "<p> Fran Roehrig,</p><p>Chief Executive Officer (CEO)</p>",
        "image": "IMG_2467.png",
        "createdDate": "2022-09-03T13:16:00.971Z",
        "id": "631353902efaf2a038f04441"
      },
      {
        "name": "Visit our office in Dubai, in the Heart of Silicon Oasis.",
        "shortDescription": null,
        "description": null,
        "image": "0f9dabe275673b055664e6a8dc017ecb.png",
        "createdDate": "2022-09-03T13:15:46.072Z",
        "id": "631353822efaf2a038f04439"
      },
      {
        "name": "Affiliate layer solutions for onboarding your product.",
        "shortDescription": null,
        "description": null,
        "image": "crop-people-shaking-hands-conference.png",
        "createdDate": "2022-09-03T13:15:24.670Z",
        "id": "6313536c2efaf2a038f04433"
      },
      {
        "name": "We have developed and are developing decentralised and centralised affiliate layer solutions to promote your product to our 25,000+ affiliates.",
        "shortDescription": null,
        "description": null,
        "image": "group-young-business-people-working-office.png",
        "createdDate": "2022-09-03T13:15:05.664Z",
        "id": "631353592efaf2a038f0442b"
      }
    ]
  },
  getters: {
    newsData: state => state.newsData
  },
  mutations: {
    SET_NEWS_LIST (state, news) {
      state.newsData = news
    }
  },
  actions: {
    setNewsList (context, news) {
      context.commit('SET_NEWS_LIST', news)
    }
  }
}
